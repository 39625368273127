.ar-checklist-input .plus-sign-svg {
  color: #7c98b6;
}

.ar-checklist-input:focus-within .plus-sign-svg {
  color: #1e73f0;
}

.ar-checklist .node-wrapper {
  padding: 5px 10px 10px 0;
}

.ar-checklist .node-component {
  width: 420px;
  z-index: 10;
  border: 2px solid transparent;
  /* padding: 10px 0px 10px 0; */
  transition-duration: 0s;
}

/* .rst__lineHalfHorizontalRight::before {
  width: 30%;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  left: 70%;
}

.ar-linechart-alternate.rst__lineChildren::after {
  left: 70%;
} */

.ar-checklist-actionPopover {
  width: 140px;
}

.ar-checklist .node-component:focus-within {
  transition-duration: 0.3s;
  border-color: #1e73f0 !important;
}

.ar-linechart-alternate.rst__lineChildren::after {
  content: "";
  position: absolute;
  background-color: #7c98b6;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 6px;
}

.rst__lineHalfHorizontalRight::before {
  background-color: #7c98b6;
}

.ar-checklist .node-move-handle {
  cursor: move;
  color: #d5dee8 !important;
  font-size: 18px;
}

.ar-checklist-expandableButtons {
  min-width: 20px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0;
}

.checklist-expand-rotate svg {
  transform: rotate(135deg) !important;
}

.rst__rowCancelPad::before {
  border: 1px dashed #ff5962 !important;
  background-color: #ffeeef !important;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  border: 1px dashed #1e73f0;
  background-color: #f2fbfd;
  border-radius: 5px;
  top: 5px;
  right: 0;
  height: 45px;
}

.bg-transparent {
  background: transparent;
  transition-duration: 0s;
}

.rst__rowSearchMatch {
  border: solid 2px #0080ff !important;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: #7c98b6 !important;
}

.rst__highlightBottomLeftCorner::after {
  content: "";
  position: absolute;
  height: 0;
  right: 0;
  top: 100%;
  margin-top: -15px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 8px solid #36c2f6;
}

.rst__highlightBottomLeftCorner::before {
  content: "";
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(100% + 1px);
  top: 0;
  right: 3px;
  width: calc(50% - -1px);
}

.ar-checklist .ExpanderButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  font-size: 18px;
  background-color: white;
  appearance: none;
  color: #7c98b6;
  border: none;
  position: absolute;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
