.ar-rounded-badge {
  border-radius: 5px;
  color: #002b63;
  background: #e7f1fd;
}
.ar-rounded-badge.purple {
  color: #5757D9;
  background: #DDDDF7;
  font-weight: 500;
}
.ar-rounded-badge.success {
  color: #0e6400;
  background: #e5faf4;
  font-weight: 500;
}
.ar-rounded-badge.black {
  color:#002b63;
  background: #CCD4E0;
  font-weight: 500;
}

.ar-rounded-badge.danger {
  color: #831f12;
  background: #ffeeef;
  font-weight: 500;
}

.ar-rounded-badge.white {
  color: #002b63;
  background: white;
	font-weight: 500;
}

.ar-rounded-badge.orange {
  color: #FF8066;
	font-weight: 500;
  background: #FFE6E0;
}

.ar-rounded-badge.yellow {
  color: #C69914;
	font-weight: 500;
  background: #FDF2D1;
}

.ar-rounded-badge.blue {
  color: #1e73f0;
	font-weight: 500;
  background: #1e72f026;
}

.ar-rounded-badge.grey {
  color: #7c98b6;
	font-weight: 500;
  background: #7c98b624;
}
