/* Basic editor styles */
.ProseMirror {
  // height > * + * {
  //   margin-top: 0.75em;
  // }
  a {
    color: #1e73f0 !important;
    cursor: pointer;
  }
  min-height: 215px;
  font-size: 14px;
  outline: none;
  height: 100%;
  ul,
  ol {
    padding: 0 1rem;
  }
  p {
    margin-top: 0 !important;
    margin-bottom: 0.5em !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    border-left: 2px solid rgba(#0d0d0d, 0.1);
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      align-items: center;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      cursor: col-resize !important;
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      // pointer-events: none;
      top: 0;
      width: 4px;
      z-index: 20;
    }

    p {
      margin: 0;
    }
  }
}

.email-tag {
  border: 1px solid #000;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
  background: white;
  user-select: auto;
}

.menu-item {
  outline: none;
}

.editor {
  background: #f5f8fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #33475b;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;

  &__header {
    align-items: center;
    border-bottom: 1px solid #ced4da;
    display: flex;
    flex: 0 0 auto;
    // flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.375rem 0.75rem;
    height: 250px;
    // padding-top: 20px;
    min-height: 230px;
    // padding: 1.25rem 1rem;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    align-items: center;
    border-top: 3px solid #0d0d0d;
    color: #0d0d0d;
    display: flex;
    flex: 0 0 auto;
    font-size: 12px;
    flex-wrap: wrap;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    white-space: nowrap;
  }

  /* Some information about the status */
  &__status {
    align-items: center;
    border-radius: 5px;
    display: flex;

    &::before {
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      content: " ";
      display: inline-block;
      flex: 0 0 auto;
      height: 0.5rem;
      margin-right: 0.5rem;
      width: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #b9f18d;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      border-radius: 0.4rem;
      color: #0d0d0d;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: #0d0d0d;
        color: #fff;
      }
    }
  }
}

.editor__content ul {
  list-style-type: disc !important;
}

.editor.remove-overflow-from-editor .editor__content {
  height: fit-content !important;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -0.7em;
  user-select: none;
  white-space: nowrap;
}

.has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2px #1e73f0;
}
.cont {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: hidden;
}

button.menu-item:disabled {
  cursor: default !important;
  color: #7c98b6 !important;
}

.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #002b63;
  height: 1.55rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  flex: 0 0 auto;
  width: fit-content;
  outline: none;
  svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
  }

  &.menu-item-speech.is-active {
    background: #be0d0d !important;
    color: white !important;
  }

  &:hover,
  &.is-active {
    background: #e6edf4 !important;
  }
}

.docOverflowedItemsBar {
  background: white;
  width: fit-content;
  border: none;
  border-radius: 3px;
  margin: 0;
}

.docTypeColorInput {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.editor__header .sketch-picker div {
  user-select: none;
}

.divider {
  height: 1.25rem;
  margin-left: 8.5px;
  border-left: 1px solid #ced4da;
  margin-right: 11.5px;
}

.docTyperMaximizedContainer {
  z-index: 10000001;
  width: 100vw;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  display: flex;
}

.DocMoreMenuBar {
  z-index: 9999990000000 !important;
}

.editor-menu-font-family-dropdown.ar-dropdown-container {
  width: fit-content !important;
  background: transparent !important;
  border: none !important;
}

.editor-menu-font-family-dropdown.ar-dropdown-container svg {
  margin-left: 0.5rem;
}

.editor-dropdown-placeholder-replacer > label > div > span {
  color: #002b63 !important;
}

// .CodeMirror {
//   height: auto !important;
// }

@media screen and (max-height: 750px) {
  .editor__content {
    height: 180px !important;
    min-height: 120px !important;
  }
}
