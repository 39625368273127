.ar-searchPreviewSquare {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  font-size: 14px;
  color: #002b63;
  font-family: Roboto;
  position: relative;
}

.blueStatus > .status-label {
  color: #002b63 !important;
  font-weight: bold !important;
}

.ar-global-search-modal {
  align-items: baseline;
}
.ar-global-search-result-items {
  padding: 8px;
}
.ar-global-search-modal .ar-modal-content {
  margin-top: 5%;
  display: flex;
	overflow: visible;
  max-height: 80%;
}

.ar-global-search-container {
  max-width: 900px;
  width: 100vw;
}

.ar-global-search-body {
  /* max-height: 350px;
  height: 350px; */
}

.ar-global-search-input {
  border: none;
  outline: none;
  font-size: 20px;
  color: #002b63;
}

.ar-global-search-buttons {
  margin: -0.25rem;
}
.ar-global-search-buttons button {
  margin: 0.25rem;
}
.ar-global-search-results {
  overflow: auto;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.ar-global-search-result-item {
  display: block;
  border-radius: 5px;
  text-decoration: none !important;
}

.ar-global-search-result-item.hint {
  background: rgba(20, 115, 230, 0.1);
}

.ar-global-search-result-title {
  background: #f8f9fb;
}

.ar-global-search-no-results-icon {
  background: url("../../Assets/Svg/global_search_no_results.svg");
  width: 70px;
  height: 70px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ar-global-search-preview-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: white;
}

.ar-global-search-footer {
  height: 60px;
  border-top: solid 1px #d5dee8;
}

.ar-global-search-footer-air-icon {
  height: 16px;
}

.ar-global-search-key {
  height: 24px;
  width: 24px;
  box-shadow: 0 3px 10px #0000001a;
  border: solid 1px #d5dee8;
}

/* .ar-globalSearch-orin-item-sidebar-link {
  padding: 0px !important;
  height: 100% !important;
  background: transparent !important;
  color: #002b63 !important;
}

.ar-globalSearch-orin-item-sidebar-link:hover {

} */
