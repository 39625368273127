.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  overflow: hidden;
  padding: 5px 0;
  /* background: red; */
}

.innerContainer {
  border-radius: 30px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.kanban {
  left: 0;
  top: 0;
  height: calc(100% + 0.5rem);
  padding: 0;
}

.timeline {
  position: sticky;

  left: calc(230px + 0.25rem);
  top: none;
  margin-right: 0.5rem;
  padding: 0;
}
