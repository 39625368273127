.ar-entity-board-refresh-container {
  position: absolute;
  z-index: 9999999;
  left: 50%;
  bottom: 20px;
  pointer-events: none;
}

.ar-entity-board-refresh-container > div {
  pointer-events: all;
  position: relative;
  background: #e7f1fd;
  left: -50%;
}

.ar-board-container.dragging .ar-board-item {
  opacity: 0.3;
}

.ar-board-item.selected {
  opacity: 1 !important;
}

.ar-board-add-new-item:hover {
	border-radius: 3px;
  background: #f9fbff;
}
