.ar-overview-item {
  /* display: flex; */
	padding: 0.4rem 0;
	font-size: 12px;
}

.ar-overview-item-title {
  flex: 0 0 auto;
  width: 190px;
  margin-right: 0.75rem;
  font-size: 12px;
  color: #7c98b6;
}
