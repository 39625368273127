.ar-actions-button{
    width: 130px;
}

.ar-actions-popup{
	min-width: 150px;
	border-radius: 3px;
}

.ar-actions-popup .ssi-button {
	border-radius: 0px !important; 
}

