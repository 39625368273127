/* TO FIX HEADER AND CONTENT 100%*/
/* @import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"); */

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #c1c9d2;
  }
}

:root {
  --purple50: #5757d9;
}

body,
html,
.App,
#root {
  background: #f9fbff;
  /* background: #000; */
  color: #7c98b6;
  height: 100%;
}

ul[data-type="taskList"] {
  list-style-type: none;
}

ul[data-type="taskList"] > li {
  display: flex;
}
ul[data-type="taskList"] > li > * {
  margin-right: 5px;
}

body {
  /* font-family: "Fira Sans", sans-serif !important; */
  /* font-family: -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Ubuntu",
  sans-serif; */
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.mw-40 {
  max-width: 40%;
}
.mw-20 {
  max-width: 20%;
}

.item-was-deleted {
  opacity: 0.4 !important;
  pointer-events: none !important;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -ms-user-select: none; /*Internet Explorer */
  user-select: none;
}

.view-button-container {
  width: 246px;
  height: 40px;
  color: #002b63;
  border-radius: 3px;
  border: 1px solid #d5dee8;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.view-button-popup {
  width: 246px;
  color: #002b63;
  border-radius: 3px;
  border: 1px solid #d5dee8;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.cke_autocomplete_panel {
  z-index: 9999999999 !important;
  color: black;
}

.cke_emoji-panel {
  z-index: 9999999999 !important;
}

.cke_dialog_container {
  z-index: 9999999999 !important;
}
.cke_combopanel {
  z-index: 9999999999 !important;
}
.cke_panel {
  z-index: 9999999999 !important;
}

.w-fit {
  width: fit-content !important;
}

.cke_panel {
  box-shadow: 0 3px 10px rgba(1, 28, 61, 0.15) !important;
  color: #002b63 !important;
}

.cke_panel_grouptitle {
  color: #002b63 !important;
}

.cke_dialog_title {
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
  color: #002b63 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
  padding: 1rem !important;
}

::-webkit-scrollbar {
  padding: 4px 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.text-align-center {
  text-align: center;
}

.origin_header_div {
  background: #293866;
  width: 555px;
  height: 65px;
  display: table;
}
.origin_header_dif_div {
  background: white;
  box-shadow: 0px 3px 10px #0000001a;
  width: 555px;
  height: 65px;
  display: table;
}

.origin_header {
  display: table-cell;
  vertical-align: middle;
}

.c-isOdd {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  background: #c1c9d2;
  border-radius: 12px;
  border: 2px solid transparent;
  background-clip: content-box;
}

a {
  color: #002b63;
}

a:hover {
  color: #002b63;
}

.fw-light {
  font-weight: 300;
}

.fw-regular {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.flex-1 {
  flex: 1;
}

.flex-0-0-auto {
  flex: 0 0 auto;
}

.shadow {
  box-shadow: 0 3px 10px rgba(227, 231, 241, 0.62) !important;
}

.text-orange {
  color: #ff8066 !important;
}

.text-maintence-color {
  color: #f7bf19;
}

.text-decoration-underline-with-hover:hover {
  text-decoration: underline;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-danger {
  color: rgba(159, 58, 56) !important;
}

.text-danger-alt {
  color: #ff5962 !important;
}

.custom-dropdown-input {
  display: flex;
  /* padding: 3px 0; */
}

/* .custom-dropdown-input > .img.sm {
  flex: 0 0 32px;
} */
/* 
.custom-dropdown-input > div {
  flex: 0 0 24px;
} */

.outline-0 {
  outline: none;
}

.custom-dropdown-input input {
  border: 0;
  outline: 0;
  background: transparent;
  flex: 1 1 auto;
}

.title-id {
  color: #1e73f0;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
}

.position-absolute {
  position: absolute;
}

.airdesk-logo {
  color: #5c5ce6;
}
.airdesk-logo.md {
  width: 290px;
}

.position-relative {
  position: relative;
}

.ssi-link {
  color: #002b63 !important;
  transition: 0.1s;
  display: block;
  font-size: 14px;
  width: 100%;
  text-decoration: none !important;
  cursor: pointer;
}

button.ssi-link {
  border: none;
  background: inherit;
  text-align: left;
  outline: none;
}

.ssi-link.selected,
.ssi-link:hover,
.ssi-link:active {
  border-radius: 5px;
  background: rgba(20, 115, 230, 0.1);
  color: #007bff !important;
}

.ssi-control {
  /* background: #f5f8fa; */
  background: #fff;
  border: 1px solid #cbd6e2;
  line-height: 1.5;
  color: #33475b;
  height: 40px;
  outline: none !important;
  font-size: 14px;
  transition: 0.3s;
}

.form-Anexos-floater .fw-medium:first-child {
  clear: left;
  float: left;
}

.ssi-control.lg {
  height: 50px;
}

.ssi-control.sm {
  height: 30px;
}

.ssi-control.hidden {
  background: inherit;
  border: none;
}

.ssi-control:focus,
.ssi-control:focus-within,
.ssi-control.selected {
  border-color: #007bff;
}

.ssi-control.error {
  border-color: rgba(159, 58, 56, 0.7);
}

.of-y-auto {
  overflow-y: auto;
}

.of-x-hidden {
  overflow-x: hidden;
}

.of-hidden {
  overflow: hidden;
}

.shadow-top {
  box-shadow: 0 -3px 10px rgba(227, 231, 241, 0.62);
}

.border {
  border: 1px solid #d5dee8;
}

.border-bottom {
  border-bottom: 1px solid #d5dee8;
}

.rounded {
  border-radius: 0.25rem;
}

.onboarding-progress-container {
  width: 100%;
  height: 8px;
  position: fixed;
  top: 0;
}
.onboarding-progress-bar {
  height: 100%;
  background: transparent
    linear-gradient(90deg, #7c7ceb 0%, #4949b6 99%, #3d3d99 100%) 0% 0%
    no-repeat padding-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  opacity: 1;
}

.onboarding-airdesk-icon-header {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  width: 520px;
}

.header {
  position: fixed;
  width: 100%;
  height: 65px;
  padding: 10px 30px;
  z-index: 999999;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: #1e73f0;
  border-top-width: 5px;
  border-top-style: solid;
  background-color: #061d4b;
}

.center {
  text-align: center;
}

.app-header.vertical-header {
  height: 100%;
  width: 130px;
  flex-direction: column;
  padding: 20px 0;
}

.app-header.vertical-header a {
  margin: 0 !important;
}

.app-header.vertical-header .header-icon {
  margin-bottom: 10px;
}

.app-header.vertical-header .header-link {
  margin: 2px 0 !important;
}

.app-header .app-header-link-container {
  position: relative;
}

.app-header.vertical-header .app-header-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-header.vertical-header .header-settings-area {
  display: flex;
  flex-direction: column;
}

.app-header.vertical-header .AccountPopup-Button {
  display: flex;
  flex-direction: column;
  padding: 0;
}

/* .AccountPopup-FullContainer:hover {
  background: #232357;
} */

.app-header.vertical-header .AccountPopup-Button .img {
  margin: 0 !important;
}

/* .app-header.vertical-header .AccountPopup-Button .account-button-container {
} */

.ar-header.ar-vertical-header + .page {
  /* padding-top: 65px; */
  padding-top: 0px !important;
  padding-left: 100px;
  height: 100%;
  box-sizing: border-box;
  /* background-color: #fff; */
}

.ar-header.ar-vertical-header.ar-vertical-header--right + .page {
  /* padding-top: 65px; */
  padding-left: 0px;
  padding-top: 0px !important;
  padding-right: 100px;
  height: 100%;
  box-sizing: border-box;
  /* background-color: #fff; */
}

.ar-header.ar-header--bottom + .page {
  /* padding-top: 65px; */
  padding-top: 0px !important;
  padding-bottom: 65px;
  height: 100%;
  box-sizing: border-box;
  /* background-color: #fff; */
}

.page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.page {
  padding-top: 64px;
  /* padding-left: 130px; */
  height: 100%;
  box-sizing: border-box;
  /* background-color: #fff; */
  position: absolute;
  overflow: hidden;
}

.MultiSelectInput-selected-item > span > div > div {
  max-width: 164px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ODataFilterList-right-content {
  width: 100%;
  overflow-x: auto;
}

/* .innerContent > div {
  /* overflow: hidden; 
} */

.c-grid-body button {
  padding: 10px;
}

.innerContent > div > .FilterList {
  flex: 0 0 auto;
}

.page.vertical-header {
  padding-top: 0;
  padding-left: 72px;
}

.header-icon {
  width: 20px;
}

.header-link {
  color: #fff;
  text-decoration: none;
  /* margin-right: 6px; */
  padding: 6px 12px;
  font-size: 15px;
  border-radius: 50px;
  display: inline-block;
  transition: 0.3s;
}

.text-check-green {
  color: #36ab80;
}

.header-link:hover {
  color: white;
  background-color: rgba(106, 106, 153, 0.35);
  text-decoration: none;
}

.header-link.selected {
  background-color: rgba(106, 106, 153, 0.35);
}

.splitContainer {
  margin-bottom: 20px;
}

.splitContainer .label,
.fc .label {
  margin-bottom: 7px;
}

.Account_BasicInformation {
  padding-left: 50px;
}

.fc .Ssi-input,
.fc .DropdownContainer {
  width: 100%;
}

.Ssi-input {
  outline: 0;
  width: 200px;
  font-size: 13px;
  padding: 3px 9px 3px 9px;
  background-color: #f5f8fa;
  border-radius: 3px;
  border: 1px solid #dee6ed;
  color: #506273;
  position: relative;
  height: 39px;
  box-sizing: border-box;
}

.Account_BasicInformation .title {
  margin-top: 15px;
  margin-bottom: 25px;
}

.left-content {
  width: 200px;
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 15px;
  box-sizing: border-box;
}

.right-content {
  width: calc(100% - 200px);
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 15px;
  box-sizing: border-box;
}

.Account_PersonalData_accdetails {
  margin-bottom: 20px;
}

.Account_PersonalData_left,
.Account_PersonalData_right {
  float: left;
  display: block;
  position: relative;
}

.Account_PersonalData_right {
  margin-left: 5px;
}

.Account_PersonalData_accdetails img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #f4f4f4;
}

.accountName {
  color: #244979;
  font-size: 21px;
  margin-bottom: 5px;
}

.badge-lightblue {
  display: inline-block;
  padding: 4px 10px;
  color: #244979;
  font-size: 14px;
  background-color: #f0fafc;
  border-radius: 100px;
}

.label {
  color: #2b4f7d;
  display: block;
  font-size: 15px;
}

.title {
  color: #2b4f7d;
  display: block;
  font-size: 21px;
}

.innerHeader {
  /* background-color: #fff; */
  min-height: 74px;
  flex: 0 0 74px;
  padding: 0 20px;
  /* box-shadow: 0px 3px 10px rgba(227, 231, 241, 0.62); */
  /* border-bottom: 1px solid #ebeff3; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerHeader > div:first-child {
  min-width: 190px;
  max-width: 190px;
  overflow: hidden;
  margin-right: 20px;
}

.innerHeaderSettings {
  /* background-color: #fff; */
  min-height: 74px;
  flex: 0 0 74px;
  /* box-shadow: 0px 3px 10px rgba(227, 231, 241, 0.62); */
  /* border-bottom: 1px solid #ebeff3; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.innerHeaderSettings > span {
  width: 255px;
}

.no-p-lr {
  padding-left: 0;
  padding-right: 0;
}

.no-p {
  padding: 0 !important;
}

.no-m {
  margin: 0 !important;
}

.innerHeader .title {
  font-size: 26px;
  position: relative;
  display: inline-block;
  margin: 3px 0;
}

.Ssi-dropdown.DropdownContainer {
  background: transparent;
  color: rgb(13, 180, 207);
  border: 1px solid rgb(13, 180, 207);
  border-radius: 5px;
  box-sizing: border-box;
}

.Ssi-dropdown.DropdownContainer .Dropdown-button {
  color: rgb(13, 180, 207);
  font-size: 14px;
  background-color: transparent;
}

tr.cancelled-intervention > td {
  background-color: #fce4ec66;
}
/* 
.innerHeader .ssi-button,
.innerHeader .DropdownContainer,
.innerHeader .Search {
   height: 100%;
  vertical-align: top; 
} */

.innerHeader .Search {
  margin-left: 20px;
}

.innerHeader .btn-header {
  margin-left: 20px;
}

.height-1 {
  height: 1rem;
}
.account-profiles {
  /* width: 100%; */
  height: 100%;
}

.account-profiles > div {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-left: -7px;
}

.account-profiles > div:first-child {
  margin: 0;
}

.account-profiles > div > div {
  margin: 0 !important;
}

.account-profiles > div > div > div {
  margin: 0 !important;
}

.dropdown-item-maintext {
  font-size: 14px;
  color: #002b63;
  width: 100%;
  text-align: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.flat-input {
  font-size: 14px !important;
  border: 0;
  background: transparent;
  outline: 0;
  width: 100%;
}

.dropdown-item-subtext {
  /* font-size: 10px;
  line-height: 10px;
  color: #7c98b6; */
  font-size: 12px;
  line-height: 1;
  color: #1e73f0;
}

.dropdown-item-subtext-dif {
  font-size: 12px;
  line-height: 1;
  align-items: justify;
  color: #7c98b6;
}

.dropdown-item-subtext svg {
  vertical-align: middle;
  margin-right: 3px;
}

.dropdown-item-subtext svg.fa-phone {
  transform: rotate(90deg);
}

.dropdown-item-subtext span {
  vertical-align: middle;
  margin-right: 10px;
}

.dropdown-item-time {
  font-size: 10px;
  color: #7c98b6;
}

/* .innerHeader .Dropdown-button { */
/* height: 100%;
  background-color: transparent;
  border-radius: 5px; */
/* } */

.listview_title {
  color: #32b9d7;
  margin-bottom: 8px;
}

.AccountPopup-FullContainer {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  transition-duration: 0.3s;
}

.ActionButtonNewColorDefault {
  color: #9a9ae8;
}

.ActionButtonNewColorDefault:hover {
  color: #ffffff;
}

.AccountPopup-FullContainer .AccountPopup-Image {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: #769ca2;
  object-fit: cover;
  vertical-align: middle;
}

.AccountPopup-FullContainer .AccountPopup-Button i {
  vertical-align: middle;
  color: #769ca2;
  margin-left: 10px;
}

.AccountPopup-FullContainer .AccountPopup-Button {
  padding: 0 !important;
  background-color: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
}

.account-popup {
  background-color: #fff;
  border-radius: 3px;
  min-width: 248px;
}

.account-popup .sub-popup {
  max-height: 400px;
  overflow-y: auto;
}

.AccountPopup.active {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.AccountPopup-Link {
  color: #a8b0b9;
  font-size: 15px;
  display: block;
  padding: 6px 0;
  margin: 5px 0;
  text-decoration: none;
}

.account-popup-Bottom .AccountPopup-Link {
  color: #0db2cf;
  margin: 0;
}

.account-popup-Name,
.account-popup-Text {
  color: #677785;
  font-size: 14px;
}

.account-popup-Text {
  margin-top: 5px;
  text-decoration: none;
  display: inline-block;
  color: #0db2cf;
}

.account-popup-Email {
  color: #9cb1c8;
  font-size: 12px;
}

.text-success-green {
  color: #0e6400 !important;
}

.right-container {
  display: block;
  float: right;
  height: 100%;
  padding-right: 50px;
  position: relative;
}

.notifications .Sidebar-Content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
}

.notifications .SimpleListView-Container {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.border-radius-0 {
  border-radius: 0;
}

.notTitle-area {
  color: #afc0d2;
  font-size: 16px;
}

.notDesc-area {
  margin-top: 10px;
}

.notDesc-area img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
}

.notDesc-area span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 32px);
  padding-left: 10px;
  box-sizing: border-box;
  line-height: 21px;
}

.header-splitter {
  display: inline-block;
  height: 22px;
  margin-right: 10px;
  margin-left: 2px;
  vertical-align: middle;
  width: 1px;
  /* height: 100%; */
  position: relative;
  background-color: #ffffff2e;
  /* margin: 0 13px 0 19px; */
}

.SettingsButton {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 21px;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: 0;
}

.ar-vertical-header .BellButton,
.ar-vertical-header .InboxButton {
  position: relative;
  width: 32px !important;
  height: 32px;
}

.ar-vertical-header .SettingsButton,
/* .ar-vertical-header .BellButton, */
.ar-vertical-header .header-splitter {
  margin: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
}

/* .ar-vertical-header .BellButton {
  margin-bottom: 0;
  margin-top: 2px;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  font-size: 23px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  outline: 0;
} */
.ar-vertical-header .BellButton:hover,
.ar-vertical-header .InboxButton:hover {
  background-color: #6a6a9959;
}

/* Chrome, Safari, Edge, Opera */
.disable-input-default-arrows input::-webkit-outer-spin-button,
.disable-input-default-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.disable-input-default-arrows input[type="number"] {
  -moz-appearance: textfield;
}

.ar-vertical-header .header-splitter {
  width: 32px;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
}

.account-button-space {
  font-size: 12px;
}

.ar-vertical-header .account-button-space {
  font-size: 10px;
  word-break: normal;
  padding: 0 2px;
  /* max-width: 100%; */
}

.ar-vertical-header .AccountPopup-FullContainer .AccountPopup-Button {
  flex-direction: column;
}

.cancelamento_btn {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  margin-right: 10px;
}

.clear {
  clear: both;
  position: relative;
  margin: 0;
  padding: 0;
}

.w70-content,
.w90-content {
  width: 70%;
  margin: 0 auto;
  max-width: 950px;
  min-width: 600px;
}

.w70-header,
.w90-header {
  width: 70%;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  max-width: 950px;
  min-width: 600px;
  height: 100%;
}

.w90-content,
.w90-header {
  width: 90%;
  max-width: 1500px;
}

.innerFullContainer {
  height: 100%;
}

.innerContent {
  overflow: auto;
  display: flex;
  padding: 0px 20px;
  max-height: 100%;
  flex: 1 1 auto;
  height: 100%;
}

.of-v {
  overflow: visible !important;
}

.FilterArea {
  margin-bottom: 20px;
  height: 40px;
}

.FilterArea .ssi-button,
.FilterArea .DropdownContainer {
  height: 100%;
  vertical-align: top;
}

.FilterArea .Search {
  margin-left: 20px;
  width: 250px;
}

.f-right {
  height: 100%;
  display: block;
  float: right;
  position: relative;
}

.text_only_dropdown_label {
  display: inline-block;
  position: relative;
  line-height: 41px;
  color: #7c98b6;
  font-size: 14px;
}

/* .ar-groupbutton-container {
  margin-right: 20px;
} */
.text_only_dropdown {
  width: auto;
}

.ml-50 {
  margin-left: 50px;
}

.text_only_dropdown .Dropdown-button {
  background-color: transparent;
  border: none;
  text-align: right;
  padding-right: 22px;
  color: #1e73f0;
}

.FilterArea .text_only_dropdown {
  margin-left: 10px;
}

.text_only_dropdown .Dropdown-button .Dropdown-value {
  font-weight: 600;
}

.text_only_dropdown .Dropdown-button span {
  vertical-align: bottom;
}

.menu-content {
  padding: 0 25px;
}

.menu-content .Ssi-input {
  width: 100%;
}

.menu-content .label {
  margin-bottom: 5px;
}

.menu-content .DropdownContainer,
.menu-content .MultiSelectInputContainer {
  width: 100%;
}

.menu-content .split {
  margin-top: 15px;
}

.menu-content .splitContainer {
  margin-bottom: 0;
}

.button-area-right {
  float: right;
  display: block;
  position: relative;
  height: 100%;
}

.button-area-right .DatePicker-FullContainer.text_only_datepicker {
  height: 100%;
}

.DatePicker-FullContainer.text_only_datepicker {
  width: auto;
  background: transparent;
  border: 0;
}

.DatePicker-FullContainer.text_only_datepicker button i,
.DatePicker-FullContainer.text_only_datepicker button span {
  vertical-align: middle;
}

.DatePicker-FullContainer.text_only_datepicker button {
  position: relative;
  display: inline-block;
  height: 100%;
  font-size: 15px;
  border: 0;
  background-color: transparent;
  color: #1e73f0;
  cursor: pointer;
  outline: 0;
}

.DatePicker-FullContainer.text_only_datepicker .fa-calendar-alt {
  margin-right: 5px;
}

.DatePicker-FullContainer.text_only_datepicker .fa-caret-down {
  margin-left: 5px;
}

.button-area-right .DropdownContainer,
.button-area-right .DatePicker-FullContainer {
  margin-right: 15px;
}

.button-area {
  display: inline-block;
  position: relative;
  height: 100%;
  vertical-align: top;
  padding-left: 30px;
}

.button-area .ssi-button,
.button-area .DropdownContainer {
  margin-left: 10px;
}

.w150 {
  width: 150px;
}

.text-color-link {
  color: #1e73f0 !important;
}

/* .splitContainer .FileUploader-Container, .split .FileUploader-Container{
  margin-left:13px;
} */

.link-color {
  color: #002b63 !important;
}

.link-alt {
  color: #002b63 !important;
}

.link-alt:hover,
.link-alt:active {
  color: #0056b3 !important;
  text-decoration: underline;
}

.link-primary {
  color: #1e73f0 !important;
}
.link-primary:hover,
.link-primary:active {
  color: #0056b3 !important;
  text-decoration: underline;
}

.link-secondary {
  color: var(--purple50) !important;
}
.link-secondary:hover,
.link-secondary:active {
  color: var(--purple50) !important;
  text-decoration: underline;
}

.Smaller-Scroll-Bar::-webkit-scrollbar {
  width: 6px !important;
}

.ml-12 {
  margin-left: 12px;
}

.Smaller-Scroll-Bar::-webkit-scrollbar-thumb {
  background: #5757d9 !important;
}

/* REACT TABLE */
.Grid-Container {
  padding: 0;
  margin: 0;
  border: 0;
  height: 100%;
}

.ReactTable .rt-thead .rt-th {
  outline: 0;
  color: #506e94;
  font-size: 16px;
  padding: 15px 7px;
  font-weight: 600;
  text-align: left;
  border-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65px;
}

.ReactTable .rt-tbody .rt-td {
  padding: 15px 10px;
  border-width: 0;
  color: #4d5f71;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 2px solid #e8f0f5;
}

.ReactTable .rt-tbody {
  overflow-y: scroll;
}

.ReactTable .rt-tbody .rt-tr {
  background-color: #fff;
}

.ReactTable.-striped .rt-tr.-odd {
  background-color: #fff;
}

.ReactTable .rt-tbody .rt-tr.selected {
  background-color: #f3fbfd;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-width: 2px;
  border-color: #e8f0f5;
}

.ReactTable.-highlight .rt-tbody .rt-tr.selected:not(.-padRow):hover {
  background-color: #f3fbfd;
}

.ReactTable .-pagination {
  padding: 15px 3px;
  background-color: #f5f8fa;
  box-shadow: none;
  border-color: #e8f0f5;
}

.rt-thead,
.rt-tfoot,
.pagination-bottom {
  width: calc(100% - 1.05em);
}

.ReactTable .checkbox-cell {
  flex: 100px 0 !important;
}

.ReactTable .checkbox-cell .rt-resizable-header-content {
  height: 26px;
}

.ReactTable .rt-thead .rt-th.checkbox-cell {
  padding-left: 35px;
}

.ReactTable .rt-tbody .rt-td.checkbox-cell {
  padding-left: 35px;
}

/* REACT TABLE */

.react-grid-item > .react-resizable-handle:after {
  content: "" !important;
  position: absolute !important;
  right: 8 !important;
  bottom: 8 !important;
  width: 11px !important;
  z-index: 999999999999;
  height: 11px !important;
  border-right: 3px solid rgba(6, 29, 75, 0.27) !important;
  border-bottom: 3px solid rgba(6, 29, 75, 0.27) !important;
  stroke-linecap: round !important;
  /* padding: 5px; */
  transform: translate(-3px, -3px) !important;
}

.ssi-onboarding-page {
  display: flex;
  height: 100%;
}

.ssi-onboarding-page .content {
  /* flex: 0 0 33.333333%; */
  /* max-width: 440px; */
  flex: 0 0 600px;
  /* max-width: 544px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
}

.ssi-unread-count-pill {
  background: #ff8066;
  border-radius: 49px;
  display: flex;
  color: #ffffff;
  min-width: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
}
.ssi-ammount-count-pill {
  background: #a5c7f9;
  border-radius: 49px;
  display: flex;
  color: #232357;
  min-width: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
}

.ssi-onboarding-page .image {
  flex: 1;
  background: #f9fbff;
  display: flex;
}

.ssi-onboarding-page .image svg {
  max-width: 710px;
  max-height: 650px;
  width: 80%;
  height: 80%;
  margin: auto;
}

.ar-onboarding-basic-form {
  max-width: 520px;
  width: 100%;
}
.Logout-button {
  border-radius: 5px;
}
.Logout-button:hover {
  color: red !important;
  background-color: rgba(20, 115, 230, 0.1);
}
.Logout-button:hover > .ar-logout-button {
  color: red !important;
  background-color: #fff6f7 !important;
}

.ar-dropdown-padding {
  padding: 0 8px;
}

.ar-logout-button:hover {
  color: red !important;
  background-color: #fff6f7 !important;
}

.ar-logout-button-checklist:hover > span,
.ar-logout-button-checklist:hover > div {
  color: red !important;
  transition: 0.3s;
}

/* .ar-logout-button:hover > span {
  color: red !important;
} */

[placeholder] {
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-overflow: ellipsis;
}

.ar-logout-button {
  text-decoration: none !important;
  color: #002b63;
  font-size: 14px;
  padding: 10px 28px 10px;
}

.ar-logout-button > svg {
  width: 20px;
}

.of-visible {
  overflow: visible !important;
}
.text-black {
  color: #232357;
}

.text-gray-darker {
  color: #637a92 !important;
}

.text-gray-lighter {
  color: #cbd6e2;
}

.text-purple {
  color: var(--purple50);
}
.text-green {
  color: #36ab80;
}
.text-grey-light {
  color: #7c98b6;
}

.text-primary {
  color: #1e73f0;
}

.text-secondary {
  color: #5c5ce6 !important;
}

.text-grey {
  color: #657b94 !important;
}

.text-white {
  color: white !important;
}

.text-white .status-label {
  color: white !important;
}

.text-warning {
  color: #ff8066 !important;
}

.text-warning-alt {
  color: #f7bf19 !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-accept-alt {
  color: #008000;
}

.bg-black {
  background: #002b63;
}

.bg-lightred {
  background: #ffeeef;
}

.bg-white {
  background: white;
}

.bg-purple {
  background: var(--purple50);
}

.bg-airdesk {
  background: #f9fbff;
}

.bg-primary-light {
  background: #f6faff;
}
.bg-blue {
  background: #1e73f0;
}

.bg-grey {
  background: #e6edf4;
}

.bg-yellow {
  background: lightgoldenrodyellow;
}

.rounded-50 {
  border-radius: 50% !important;
}

.img-sm {
  width: 26px;
  height: 26px;
}

.popper {
  position: absolute;
  top: 0;
  z-index: 10000002;
}

.min-h-100 {
  min-height: 100%;
}

.max-w-540px {
  max-width: 540px;
}

.max-w-1200 {
  max-width: 1200px;
}

/* .header-fa-icon {
  font-size: 17px;
  vertical-align: middle;
} */

.header-fa-icon-button {
  width: auto !important;
  padding: 8px !important;
  border-radius: 50% !important;
}

.skeleton {
  animation: skeletonPulse ease-in-out 2s 0.2s infinite;
}

@keyframes skeletonPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.airdesk-icon {
  background: #5c5ce6;
  height: 150px;
  width: 150px;
  padding: 40px;
  border-radius: 28px;
}

.horizontal-splitter {
  border: solid 1px #d5dee8;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-auto {
  cursor: auto !important;
}

.w-450px {
  width: 450px;
}

.status-dot-background-template {
  text-align: center;
  line-height: 24px;
}
.status-dot-svg {
  display: flex;
  margin-right: 5px;
  position: relative;
}
.status-dot-svg > svg {
  width: 12px;
  height: 12px;
}

.status-dot {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

/* .status-label {
  font-weight: bold;
} */

.status-color-danger > .status-dot {
  background-color: #ffbdc0;
  border-color: #cc474e;
}

.status-color-danger > .status-label {
  color: #cc474e;
}

.status-color-orange > .status-dot {
  background-color: #ffa166;
  border-color: #ffa166;
}

.status-color-orange > .status-label {
  color: #cc6652;
}

.status-color-yellow > .status-dot {
  background-color: #f7bf19;
  border-color: #f7bf19;
}

.status-color-yellow > .status-label {
  color: #c69914;
}

.status-color-black > .status-dot {
  background-color: #cbd6e2;
  border-color: #4a5b6d;
}

.status-color-black > .status-label {
  color: #4a5b6d;
}
.status-color-inactive > .status-label {
  color: #4a5b6d;
}

.status-color-purple > .status-dot {
  background-color: #908cff;
  border-color: #908cff;
}

.status-color-purple > .status-label {
  color: #5757d9;
}

.status-color-grey > .status-dot {
  background-color: #7c98b6;
  border-color: #7c98b6;
}

.status-color-grey > .status-label {
  color: #7c98b6;
}

.status-color-outline-grey > .status-dot {
  border-color: #d5dee8;
}

.status-color-outline-grey > .status-label {
  color: #d5dee8;
}

.status-color-success > .status-dot {
  background-color: #afddcc;
  border-color: #2b8966;
}
.status-color-pipeline > .status-dot {
  background-color: #00236c;
  border-color: #00236c;
}
.status-color-danger > .status-dot.status-dot-background-template {
  background-color: #ffbdc0;
  border-color: #ffbdc0;
  color: #cc474e;
}
.status-color-success > .status-dot.status-dot-background-template {
  background-color: #afddcc;
  border-color: #afddcc;
  color: #2b8966;
}

.status-color-orange > .status-dot.status-dot-background-template {
  background-color: #ffe6e0;
  border-color: #ffe6e0;
  color: #ff8066;
}

.status-color-yellow > .status-dot.status-dot-background-template {
  background-color: #fdf2d1;
  border-color: #fdf2d1;
  color: #f7bf19;
}
.status-color-black > .status-dot.status-dot-background-template {
  background-color: #cbd6e2;
  border-color: #cbd6e2;
  color: #4a5b6d;
}

.status-color-grey > .status-dot.status-dot-background-template {
  background-color: #e5eaf0;
  border-color: #e5eaf0;
  color: #96adc5;
}

.status-color-purple > .status-dot.status-dot-background-template {
  background-color: #ddddf7;
  border-color: #ddddf7;
  color: #7979e1;
}
.status-color-purple > .status-list-template {
  background-color: #ddddf7;
  border-color: #ddddf7;
  border-radius: 5px;
  color: #7979e1;
}

.status-color-orange > .status-list-template {
  background-color: #ffe6e0;
  border-color: #ffe6e0;
  color: #ff8066;
  border-radius: 5px;
}

.status-color-black > .status-list-template {
  background-color: #b8c7d9;
  border-color: #b8c7d9;
  color: #002b63;
  border-radius: 5px;
}

.status-color-danger > .status-list-template {
  background-color: #ffbdc0;
  border-color: #ffbdc0;
  border-radius: 5px;
  color: #cc474e;
}
.status-color-inactive > .status-list-template {
  background-color: #e5eaf0;
  border-color: #e5eaf0;
  color: #96adc5;
  border-radius: 5px;
}

.status-color-success > .status-list-template {
  background: #afddcc;
  border-color: #afddcc;
  border-radius: 5px;
  color: #2b8966;
}

.status-color-blue > .status-list-template {
  background-color: #afddcc;
  border-color: #afddcc;
  color: #2b8966;
  border-radius: 5px;
}

.status-color-blue > .status-dot.status-dot-background-template {
  background-color: #afddcc;
  border-color: #afddcc;
  color: #2b8966;
}
.status-color-inactive > .status-dot.status-dot-background-template {
  background-color: #cbd6e2;
  border-color: #cbd6e2;
  color: #4a5b6d;
}

.status-color-success > .status-label {
  color: #2b8966;
}

.status-color-blue > .status-dot {
  background-color: #2b8966;
  border-color: #2b8966;
}
.status-color-inactive > .status-dot {
  background-color: #4a5b6d;
  border-color: #4a5b6d;
}

.status-color-blue > .status-label {
  color: #2b8966;
}
.status-color-inactive > .status-label {
  color: #4a5b6d;
}

.ssi-button-grid {
  width: 40px;
  min-width: 40px !important;
  height: 40px;
  display: inline-flex;
  padding: 0px !important;
  align-items: center;
  justify-content: center;
}

.ssi-button-grid.sm {
  width: 30px;
  min-width: 30px !important;
  height: 30px;
  padding: 0;
}

.lh-normal {
  line-height: normal;
}

.w-150px {
  width: 150px;
}

.w-175px {
  width: 175px;
}
.w-170px {
  width: 170px !important;
}

.mw-0 {
  min-width: 0px !important;
}

.mw-120px {
  max-width: 120px;
}

.mw-290px {
  max-width: 290px;
}

.ar-origin-popup {
  border-radius: 5px;
  width: 555px;
  max-height: 90vh;
  overflow: auto;
}

.skeleton {
  background: rgb(243, 243, 243) !important;
  animation: pulse 1.5s infinite;
}

.skeleton-inverted-pulse {
  animation: invertedPulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes invertedPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-line {
  white-space: pre-line;
}

.ar-grid-buttons-popup {
  width: 275px;
  padding: 8px;
  max-height: 95vh;
  overflow-y: auto;
}
.ar-grid-buttons-popup .ssi-button {
  border-radius: 5px !important;
  text-transform: capitalize;
}

.air-rounded-selection {
  border-radius: 5px !important;
}

.ar-exclamation-warning-svg {
  width: 50px;
  height: 43px;
  background: url("./Assets/Svg/details_history_warning.svg");
}
/* 
.page {
  transition: 150ms ease-out;
} */

.fade-enter {
  opacity: 0;
  z-index: 1;
  /* transform: scale(0.95) */
  transform: scale3d(0.98, 1.01, 1);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  /* transform: scale(1) */
  transform: scale3d(1, 1, 1);
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  /* transform: scale(1.05) */
  transform: scale3d(1.02, 0.99, 1);
}

.removeNumberButtonsFromInput input::-webkit-outer-spin-button,
.removeNumberButtonsFromInput input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.removeNumberButtonsFromInput input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.vertical-align-middle {
  vertical-align: middle;
}

.details-form-footer {
  z-index: 999999;
  position: sticky;
  background-color: white;
  bottom: 0;
}

.ar-sidebar-sticky-title {
  background: #f6faff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.vertical-splitter {
  border-right: solid 1px #dee2e6;
  height: 32px;
}

.vertical-splitter.sm {
  height: 24px;
}

.EntityLinkClassID {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dragging {
  border-color: #1e73f0 !important;
}

.dragging .GripVerticalIcon {
  color: #1e73f0 !important;
}

.ar-deals-pipeline-item-drag > svg {
  pointer-events: none;
}

.air-new-profiles-circle {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.air-new-profiles-circle.lg {
  width: 34px !important;
  height: 34px !important;
}
.air-new-profiles-circle.sm {
  width: 24px !important;
  height: 24px !important;
}

.status-open-colors {
  background: #afddcc;
  color: #2b8966;
}
.status-lost-colors {
  background: #ffbdc0;
  color: #cc474e;
}
.status-ended-colors {
  background: #4a5b6d;
  color: #cbd6e2;
}
.status-staging-colors,
.status-yellow-colors {
  background: #f7bf19;
  color: #fdf2d1;
}

.status-closed-colors {
  background: #afddcc;
  color: #2b8966;
}
.status-canceled-colors {
  background: #cbd6e2;
  color: #4a5b6d;
}
.status-inactive-colors {
  background: #e5eaf0;
  color: #96adc5;
}
.status-in-progress-colors {
  background: #ddddf7;
  color: #7979e1;
}
.status-draft-colors,
.status-orange-colors {
  background: #ffe6e0;
  color: #ff8066;
}
