.SketchPickerContainer .sketch-picker {
  padding: 0 !important;
  width: 100% !important;
  background: rgb(255, 255, 255) !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 100% !important;
}

.SketchPickerContainer > .sketch-picker > div:first-child{
	padding-bottom: 31% !important;
}

.SketchPickerContainer > .sketch-picker > div:last-child > div{
	width:  30px !important;
	height:  30px !important;
}


.SketchPickerContainer input{
	border: 1px solid #ced4da !important;
	background: #f5f8fa;
	outline: none !important;
	color: #33475b;
	box-shadow: none !important;
}
.SketchPickerContainer input:focus{
	border-color: #007bff !important;

}