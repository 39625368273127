.ar-classification-integrity-list-item {
  padding: 1rem 0;
  border-bottom: solid 1px #d5dee8;
  line-height: 1.7;
  width: 100%;
}
.ar-classification-integrity-list-item:last-child {
  border-bottom: none;
}

.ar-classification-breadcrumbs {
  max-width: 80vw;
  overflow: auto;
}

.ar-classification-breadcrumbs .ar-breadcrumbs {
  border: none !important;
}

.ar-classification-origin-warning {
  box-shadow: none !important;
}
