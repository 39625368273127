.HoursOutside-Container {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.HoursOutside-Component {
  background-color: #ffeeef;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 34px;
  color: #ff5962;
  font-size: 14px;
}

.HoursControll-Container .HoursControll-controller {
  width: 12px;
  height: 21px;
}

.HoursControll-Hours {
  height: 100%;
  width: max-content;
  overflow-y: hidden;
}

.HoursControll-Container .HoursControll-HourComponent-basic {
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  width: 54px;
  height: 40px;
  color: #002b63;
  border: 2px solid #ebf1f7;
  background-color: #ebf1f7;
}

.HoursControll-Container
  .HoursControll-HourComponent-basic.HoursControll-Current {
  border: 2px solid #1e73f0;
}

.HoursControll-Container
  .HoursControll-HourComponent-OverDone.HoursControll-Current {
  border: 2px solid #ff5962;
}

.visibility-none {
  visibility: hidden;
}

.HoursControll-Container .HoursControll-HourComponent-OverDone {
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  width: 54px;
  height: 40px;
  color: #ff5962 !important;
  border: 2px solid #fff5f3;
  background-color: #fff5f3 !important;
}
