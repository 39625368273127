.ar-alert {
  border: solid 1px black;
  border-radius: 6px;
}

.ar-alert.outline {
  box-shadow: 0 3px 6px #00000029;
}

.ar-alert-error {
  color: #ff5962;
  background: #fff6f7;
  border-color: rgba(255, 89, 97, 0.5);
}
.ar-alert-error.outline {
  color: #831f12;
  background: white;
  border-color: #ff596280;
}

.ar-alert-info {
  color: #002b63;
  background: #f6faff;
  border-color: #d5dee8;
}

.ar-alert-warning {
  color: #7e6514;
  background: #fef8e7;
  border-color: #fef8e7;
}
