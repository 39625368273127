.ar-details-view {
  height: 100%;
}

.ar-details-view-overview,
.ar-details-view-sidebar {
  flex-basis: 436px;
  background: white;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 10px rgba(227, 231, 241);
}

@media screen and (max-width: 1750px) {
  .ar-details-view-overview,
  .ar-details-view-sidebar {
    flex-basis: 336px;
  }
}
.keepWidth {
  min-width: 214px;
  word-break: keep-all;
  word-wrap: normal;
  white-space: nowrap;
}
.ar-details-view-overview {
  transition: 0.3s;
  border-right: 1px solid #cdd5ea;
}
.ar-details-view-sidebar {
  transition: 0.3s;
  border-left: 1px solid #cdd5ea;
}
.ar-details-view-sidebar.closed,
.ar-details-view-overview.closed {
  flex-basis: 41px;
}

.ar-details-id {
  width: auto;
}
.ar-details-id.skeleton {
  width: 150px;
  height: 14px;
}

.ar-details-name {
  width: auto;
}

.ar-details-id.skeleton,
.ar-details-name.skeleton {
  border-radius: 30px;
}

.ar-details-name.skeleton {
  width: 250px;
  height: 22px;
}

.ar-details-view-form-buttons .ssi-button-grid {
  margin: 0 0.5rem;
}

.ar-details-view-form-buttons .ssi-button-grid:first-child {
  margin-left: 0;
}
.ar-details-view-form-buttons .ssi-button-grid:last-child {
  margin-right: 0;
}

.ar-details-view-status-button {
  width: 140px;
}

.ar-details-form-submmiter {
  width: 100%;
  z-index: 0;
}

.ar-details-id,
.ar-details-name {
  line-height: 1;
}

.ar-details-view-overview-toggler {
  position: sticky;
  background: white;
  top: 0;
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align: -o-right;
  text-align: -ms-right;
  z-index: 1;
}

.ar-details-view-sidebar-toggler {
  position: sticky;
  background: white;
  top: 0;
  z-index: 1;
}

.ar-details-view-overview-toggler div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform: rotate(0deg);
}

.ar-details-view-overview-toggler div.closed {
  transform: rotate(180deg);
}

.ar-details-view-sidebar-toggler div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform: rotate(180deg);
}

.ar-details-view-sidebar-toggler div.closed {
  transform: rotate(0deg);
}

.ar-details-entity {
  min-width: 300px;
  border-bottom: solid 1px #d5dee8;
}
.ar-details-entity .ar-history-accordion {
  margin-top: 0 !important;
  line-height: 0;
}

.ar-details-entity .ar-history-accordion.active > .title {
  margin-bottom: 1rem;
}

.ar-details-entity:last-child {
  border-bottom: none;
}
.ar-details-entity-children .ar-details-entity-child {
  font-size: 12px;
  margin-bottom: 0.25rem;
  min-height: 30px;
}
.ar-details-entity-children .ar-details-entity-child:last-child {
  margin-bottom: 0;
}
.ar-details-child-skeleton {
  height: 60px;
  width: 100%;
}

.ar-details-tabstrip{
	user-select: none;
}

.ar-details-tabstrip .Tabstrip-top-menu {
  padding: 0;
  text-align: left;
}

.ar-details-tabstrip .Tabstrip-content {
  padding: 1.5rem 0;
}

.ar-details-tabstrip .Tabstrip-link {
  color: #002b63;
  text-decoration: none;
  transition: 0.3s;
  border-radius: 5px;
  font-weight: 400;
}

.ar-details-tabstrip .Tabstrip-link:hover {
  background-color: #1473e61a;
}
.ar-details-tabstrip .Tabstrip-link.selected:hover {
  background-color: inherit;
}
.ar-details-tabstrip .Tabstrip-link.selected::after {
  background-color: #002b63;
  height: 3px;
  bottom: -2px;
}

.ar-details-form-title-skeleton {
  height: 15px;
  margin-bottom: 6px;
  width: 160px;
}

.ar-details-form-input-skeleton {
  height: 18px;
  margin-top: 4px;
  margin-bottom: 18px;
  width: 280px;
}

.ar-details-form .ar-history-accordion.active > .title {
  margin-bottom: 1rem;
}
