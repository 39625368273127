.ar-hourpicker {
  transition: 0.3s;
}

.ar-hourpicker-input-container {
  width: 100%;
  height: 100%;
}

.ar-hourpicker-input-container input {
  border: none;
  width: auto;
  outline: none;
  text-align: center;
  width: 100%;
  height: 100%;
  text-align: right;
  background: inherit;
  margin-right: 2px;
}

.ar-hourpicker-input-container input:last-child {
  text-align: left;
  margin-left: 2px;
  margin-right: 0;
}

.ar-hourpicker button {
  color: #1e73f0;
  background: inherit;
  border: 0;
  border-radius: 0;
  height: 100%;
  width: 40px;
  min-width: 40px;
  padding: 0;
  border-left: solid 1px #d5dee8 !important;
  overflow: hidden;
}
.ar-hourpicker button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ar-hourpicker button:hover {
  color: white;
  background: #1e73f0;
}
