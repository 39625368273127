.Badge {
  border-radius: 30px;
  padding: 0.25rem 0.5rem;
  background: #e5faf4;
  color: #0dcf93;
}

.Badge .text {
  color: #0e6400;
}

.Badge.error {
  background: #ffeeef;
  color: #ff5962;
}

.Badge.error .text {
  color: #831f12;
}
