.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin: 0;
}

.checkbox-container.sm {
  font-size: 14px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.pointer-none {
  pointer-events: none;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #dee6ed;
  border-radius: 3px;
  transition: 0.3s;
}

.checkbox-container.sm .checkmark {
  height: 18px;
  width: 18px;
}

.checkbox-container input:disabled ~ .checkmark {
  color: #7c98b6 !important;
  border-color: #7c98b6 !important;
  background-color: #eaf0f6 !important;
  box-shadow: none !important;
  border: 0;
  cursor: default !important;
}
.checkbox-container input:disabled ~ .checkmark:after {
  border-color: #7c98b6 !important;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: rgba(20, 115, 230, 0.1);
  box-shadow: 0 0 2px 0 transparent;
}

.checkbox-container input:checked ~ .checkmark {
  /* background-color: #2196F3; */
  background-color: white;
  border-color: #5757d9;
  /* box-shadow: 0 0 2px 0 #1e73f0; */
}

.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 8px;
  top: 3px;
  width: 7px;
  height: 14px;
  border: solid #5757d9;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container.sm .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
}

.Checkbox-text {
  /* display: inline-block; */
  margin-left: 5px;
  vertical-align: middle;
  padding-left: 24px;
  font-size: 14px;
}

.checkbox-container.secondary:hover input ~ .checkmark {
  background-color: rgba(87, 87, 217, 0.1);
}
.checkbox-container.secondary input:checked ~ .checkmark {
  /* background-color: #2196F3; */
  background-color: white;
  border-color: var(--purple50);
  box-shadow: 0 0 2px 0 var(--purple50);
}
.checkbox-container.secondary .checkmark:after {
  border-color: var(--purple50);
}

.file-associations-class .checkmark {
  border-color: #5757d9;
}

.checkbox-container.border-black-invite input:not(:checked) ~ .checkmark {
  border-color: #232357 !important;
}
