.ar-history-date-skeleton {
  height: 22px;
  width: 200px;
}

.ar-history-item-title-image-skeleton {
  height: 20px;
  width: 20px;
}

.ar-history-item-title-skeleton {
  height: 10px;
  width: 120px;
}

.ar-history-item-desc-skeleton {
  height: 14px;
  width: 70%;
  max-width: 600px;
}

.ar-history-item {
  background: white;
  border: solid 1px #cdd5ea;
  box-shadow: 0 3px 10px rgba(227, 231, 241, 0.62);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.ar-history-item > div > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ar-history-item-note {
  padding: 0 !important;
}
.ar-history-item:last-child {
  margin-bottom: 0;
}

.ar-history-item-header-icon {
  width: 1rem !important;
  height: 1rem;
  position: relative;
  margin-right: 1rem;
}

.ar-history-item-header-icon svg {
  width: 100% !important;
  height: 100%;
  display: block;
}

.ar-history-item .skeleton {
  border-radius: 20px;
}

.ar-history-date-group {
  margin-bottom: 1.75rem;
}

.ar-history-item-content {
  padding-top: 0.75rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
}

.ar-history-edit-item {
  margin-bottom: 0.75rem;
  border: solid 1px #d5dee8;
  border-radius: 5px;
}
.ar-history-edit-item-title {
  border-bottom: solid 1px #d5dee8;
}

.ar-history-item .positive-change,
.ar-history-item .negative-change {
  position: relative;
  max-height: 300px;
  overflow: auto;
}

.ar-history-item .positive-change > div,
.ar-history-item .negative-change > div {
  padding: 0.75rem 0.5rem;
}

.ar-history-item .positive-change pre,
.ar-history-item .negative-change pre {
  overflow: inherit;
  margin-bottom: 0;
}

/* .ar-history-item .positive-change::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;

  border-left: 60px solid green;
} */

.ar-history-item .positive-change {
  background: rgba(13, 207, 146, 0.03);
}
.ar-history-item .negative-change {
  background: rgba(255, 89, 97, 0.03) !important;
}

.ar-history-item-seperator {
  position: relative;
  width: 40px;
  overflow: hidden;
  top: 0;
  background: rgba(255, 89, 97, 0.03);
}
.ar-history-item-seperator::after {
  content: "";
  background-image: linear-gradient(
    to left bottom,
    #f7fefc 0%,
    #f7fefc 50%,
    #d5dee8 53%,
    transparent 50%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: -50%;
  left: 0;
}
.ar-history-item-seperator::before {
  content: "";
  background-image: linear-gradient(
    to left top,
    #f7fefc 0%,
    #f7fefc 50%,
    #d5dee8 53%,
    transparent 50%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
}

.ar-history-accordion .title {
  transition: 0.3s;
  cursor: pointer;
}

/* .ar-history-accordion .title:hover {
  background: #f6faff;
} */

.ar-history-accordion-icon {
  width: 0.75rem;
  height: 1rem;
  margin-right: 0.5rem;
  transition: 0.3s;
  transform: rotate(0deg);
}

.ar-history-accordion.active > .title > .ar-history-accordion-icon {
  transform: rotate(90deg);
}

.ar-history-accordion-icon svg {
  display: block;
  width: 100% !important;
  height: 100%;
}

.ar-history-accordion-content {
  line-height: 1.5;
  opacity: 0;
  margin-left: 1.25rem;
  overflow: hidden;
  transition: 0.3s;
  will-change: height, opacity;
}

.ar-history-accordion.active .title {
  margin-bottom: 0.25rem;
}

.ar-history-accordion-content.active {
  opacity: 1;
}

.ar-history-title {
  color: #ff8066;
}

.ar-history-no-items {
  background: url("../../../Assets/Svg/empty_list_history.svg");
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 135px;
}
