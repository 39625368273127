@media all and (min-width: 0px) and (max-width: 425px) {
  .tweet {
    width: 90% !important;
  }
}
.tweet {
  font-size: 15px;
  text-align: left;
  padding: 15px 15px 0 15px;
  background-color: white;
  border: 1px solid #e6ecf0;
  color: #002b63;
}
.user-info-right {
  padding-top: 2px;
}
.user-name {
  font-weight: bold;
}
.user-name-txt {
  display: inline;
}
.user-nickname {
  cursor: pointer;
  color: rgb(101, 119, 134);
}
.tweet-icon {
  margin-left: 3px;
  display: inline;
  position: relative;
  top: 4px;
}
.tweet-icon svg {
  height: 1.25em;
}
.verified-icon-svg {
  fill: rgba(29, 161, 242, 1);
}
.tweet-text {
  padding-top: 6px;
  font-size: 13px;
  padding-bottom: 15px;
  clear: left;
  word-break: break-word;
  white-space: pre-wrap;
}

.date-app-details {
  font-size: 12px;
  color: rgb(101, 119, 134);
  padding-bottom: 15px;
  border-bottom: 1px solid #e6ecf0;
}
.rt-likes {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6ecf0;
  color: rgb(101, 119, 134);
}
.rt-likes strong {
  color: rgb(20, 23, 26);
}
.num-likes {
  margin-left: 20px;
}
.app {
  color: rgb(27, 149, 224);
}
.fake-link {
  cursor: pointer;
}
.fake-link:hover {
  text-decoration: underline;
}
.avatar-container {
  margin-right: 10px;
  float: left;
}
.avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  cursor: pointer;
}
.drop-button {
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  float: right;
  transition: 0.2s;
}
.drop-button svg {
  width: 1em;
  height: 1em;
  fill: rgb(101, 119, 134);
}
.drop-button:hover {
  background-color: rgba(29, 161, 242, 0.1);
}
.drop-button svg:hover {
  fill: rgb(27, 149, 224);
}
.bottom-buttons {
  display: flex;
  flex-direction: row;
}
.bottom-button {
  height: 48px;
  width: 48px;
  display: inline-block;
  cursor: pointer;
  flex: 0 25%;
  box-sizing: border-box;
}
.bottom-button > div {
  display: flex;
  align-items: center;
  height: 100%;
}
.bottom-button svg {
  padding: 2px;
  border-radius: 50%;
  margin: auto;
  fill: rgb(101, 119, 134);
  height: 1.5em;
  width: 1.6em;
  transition: 0.2s;
}
.bottom-button.blue svg:hover {
  fill: rgb(27, 149, 224);
}
.bottom-button.green svg:hover {
  fill: rgb(23, 191, 99);
}
.bottom-button.red svg:hover {
  fill: rgb(215, 42, 94);
}
.bottom-button.blue svg:hover {
  background-color: rgba(29, 161, 242, 0.1);
}
.bottom-button.green svg:hover {
  background-color: rgba(23, 191, 99, 0.1);
}
.bottom-button.red svg:hover {
  background-color: rgba(215, 42, 94, 0.1);
}

.border-radius-0 {
  border-radius: 0 !important;
}
.image-container {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.image-container img {
  border-radius: 13px;
	border: 1px solid #ccd6dd;
	width: 100%;
	height: auto;
	object-fit: cover;
}
.twemoji-sm,
.twemoji-bg {
  position: relative;
  top: 4px;
}
.twemoji-sm {
  height: 18px;
  width: 18px;
}
.twemoji-bg {
  height: 28px;
  width: 28px;
}
