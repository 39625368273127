.ar-typing-loader-container{
    width: 26px;
}

.ar-typing-loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: 10px 0px 0px 0px rgba(0,43,99, 0.2),
    20px 0px 0px 0px rgba(0,43,99, 2);
  animation: line 1s linear infinite alternate;
}

@keyframes line {
  0% {
    background-color: rgba(0,43,99, 1);
    box-shadow: 10px 0px 0px 0px rgba(0,43,99, 0.2),
      20px 0px 0px 0px rgba(0,43,99, 0.2);
  }
  25% {
    background-color: rgba(0,43,99, 0.4);
    box-shadow: 10px 0px 0px 0px rgba(0,43,99, 2),
      20px 0px 0px 0px rgba(0,43,99, 0.2);
  }
  75% {
    background-color: rgba(0,43,99, 0.4);
    box-shadow: 10px 0px 0px 0px rgba(0,43,99, 0.2),
      20px 0px 0px 0px rgba(0,43,99, 2);
  }
}
