.selector-icon {
  background: #1e73f0;
  height: 150px;
  width: 150px;
  padding: 40px;
  border-radius: 28px;
}
.airdesk-text-svg {
  width: 290px;
}

.App.Vertical .page {
  padding-top: 0 !important;
  position: relative;
  flex: 1;
}
.App.Vertical_bar .page {
  position: relative;
  flex: 1;
}

.App.Vertical {
  display: flex !important;
}
.App.Vertical_bar {
  display: flex !important;
}

.selector-item-container {
  border: solid 1px #d5dee8;
  border-right: none;
  border-left: none;
}
.selector-item-container .selector-item:last-child {
  border-bottom: none;
}

.InformationComponent {
  color: #7c98b6;
  cursor: help;
}

.InformationTooltip {
  cursor: context-menu;
  max-width: 400px;
  white-space: normal;
  border: 1px solid transparent;
}

.InformationTooltip.DangerousTooltip {
  border-color: #ff5962;
  color: #ff5962;
  background: #fff6f7;
}

.InformationTooltip.WarningPaymentAlmostUp {
  border-color: #f7bf19;
  color: #f7bf19;
  background: white;
}

.InformationTooltip.TrialEndTooltip {
  border-color: #1e73f0;
  color: #1e73f0;
  background: #e7f1fd;
}

.selector-item {
  color: #002b63 !important;
  text-decoration: none !important;
  padding: 2px 10px;
  padding: 20px 30px;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: solid 1px #d5dee8;
}
@-moz-document url-prefix() {
  .scrollable-nav {
    padding-right: 0px !important;
  }
}

.selector-item:hover {
  background: rgba(142, 219, 236, 0.1);
}

.LogOutButton-SelectorSpace {
  position: absolute;
  top: 0;
  right: 0;
}
