.dragging {
  border-color: #1e73f0 !important;
}

.dragging .GripVerticalIcon {
  color: #1e73f0 !important;
}

.ar-deals-pipeline-item-drag > svg{
  pointer-events: none;
}