.ar-badge {
  background: #7c98b6;
  border-radius: 50%;
  color: #fff;
  /* width: 22px;
  height: 22px; */
	font-weight: 500;
  font-size: 12px;
  /* padding: 0 7px; */
  /* MARGIN: AUTO; */
  display: flex;
  /* margin: auto !important; */
  align-items: center;
  justify-content: center;
	padding: 2px 12px;
	border-radius: 10px;
}
/* .ar-badge.sm {
  font-size: 10px !important;
} */

.ar-badge.ar-pill-rect{
	border-radius: 4px;
}

a.selected .ar-badge {
  background: white !important;
  color: #5656d6;
}

.ar-badge.primary {
  background: #5656d6;
	outline: 1px solid white;
}

.ar-badge.success {
  background: #2B8966;
}

.ar-badge.spicy {
  background: #fa7155;
}
.ar-badge.spicy-alt {
  background: #CC474E;
}
.ar-badge.purple {
  background: #908cff;
}

.ar-badge.span-badge {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ar-badge.danger {
  background: #FFE6E0;
	color: #CC6652;
}

.ar-badge.danger-alt {
  /* background: #ffeeef;
  color: #ff5962; */
  color: rgb(101, 123, 148);
  background: rgba(101, 123, 148, 0.1);
  /* padding: 4px 10px; */
  height: 24px;
}

.ar-badge.disabled {
  color: #7C98B6;
  background: #E5EAF0;
}
.ar-badge.blue {
  color: #4A5B6D;
  background: #CBD6E2;
}
.ar-badge.purple {
  color: #5757D9;
  background: #DDDDF7;
}
.ar-badge.blocked {
  color: #99353B;
  background: #FFBDC0; 
}
.ar-badge.orange {
  color:  #CC6652;
  background: #FFE6E0;
}
