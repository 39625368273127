.ar-entity-summary-breadcrumbs {
  white-space: nowrap;
}

.ar-entity-summary-breadcrumbs-item {
  display: inline-flex;
  text-align: center;
	vertical-align: bottom;
  height: 100%;
  align-items: center;
  max-width: 350px;
}

.ar-sidebar-button-reset{
	padding: 0px !important;
	height: 100% !important;
	background: transparent !important;
	color: #002b63 !important;
}

.ar-sidebar-button-reset:hover{
	text-decoration: underline !important;
	color: #002b63 !important;
}

.ar-entity-summary-breadcrumbs-icon {
  border: 1px solid #00000040;
  border-radius: 4px;
  /* padding: 4px 0; */
  transition: 0.3s;
	height: 26px;
  cursor: pointer;
}

.ar-entity-summary-breadcrumbs-icon:hover {
  background: #0000000d;
}

.ar-entity-summary-breadcrumbs-icon:hover {
  background: #0000000d;
}

.ar-entity-summary-breadcrumbs-link {
  color: #007bff !important;
}
.ar-entity-summary-breadcrumbs-link:hover {
  color: #0056b3 !important;
}
