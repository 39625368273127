.ar-entity-summary-messages {
  transition: 0.3s;
  transition-property: width;
  background: #f9fbff;
  overflow-y: auto;
  overflow-x: hidden;
}

.ar-entity-summary-message-container {
  margin-top: 12px;
  position: relative;
}

.messages-drag-resize {
  cursor: row-resize;
}

.disable-heights-for-editor .editor__content{
	height: unset !important;
  min-height: unset !important;
}

.ar-entity-summary-message {
  margin-left: 8px;
  max-width: 70%;
  background: white;
  /* box-shadow: 0 3px 6px #00000008; */
  border-radius: 10px;
  border-top-left-radius: 0px;
  /* display: inline-block; */
  white-space: pre-wrap;
  padding-top: 6px;
  padding-bottom: 8px;
  position: relative;
}

.ar-entity-summary-message.pinned {
  border: solid 1px #1e73f0;
}
.ar-entity-summary-message-pin {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 26px;
  height: 26px;
  background: #1e73f0;
  color: white;
  border-radius: 50%;
}

.border-dashed {
  border-style: dashed;
}

.ar-entity-summary-message-container.grouped {
  margin-top: 8px;
}
.ar-entity-summary-message-container.grouped > .ar-entity-summary-message {
  margin-left: 40px;
}

.ar-entity-summary-message-container.self {
  justify-content: flex-end;
}

.ar-entity-summary-message.reply {
  background: #eef2f5;
}
.ar-entity-summary-message-container.note > img {
  margin-right: 0.5rem;
}
.ar-entity-summary-message-container.note > .ar-entity-summary-message {
  width: 100%;
  max-width: 100%;
  margin-left: 0 !important;
}

.ar-entity-summary-message-container.note
  > .ar-entity-summary-message
  > .message-container-for-chat
  > div:first-of-type {
  width: 100%;
  flex: 1 1;
}
.ar-entity-summary-message-container.note
  > .ar-entity-summary-message
  > .message-container-for-chat {
  width: 100%;
}

.ar-entity-summary-message.self {
  justify-content: flex-end;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  background: #e7f1fd;
}

.ar-entity-summary-message-options {
  color: #1e73f0;
  transition: 0.3s;
  opacity: 0;
}

.ar-entity-summary-message:hover .ar-entity-summary-message-options {
  opacity: 1;
}

/* Replys */

.ar-entity-summary-message.reply {
  border-radius: 5px;
  /* border: solid 1px #1e73f0; */
  margin: 0;
  max-width: 100%;
  position: relative;
}
.ar-entity-summary-message.reply::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #7c98b6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* MessageSender  */
.ar-entity-summary-message-textarea::placeholder {
  color: #7c98b6;
}

.Tabstrip-content .ar-entity-summary-message-textarea {
  background: white !important;
  color: #002b63;
  border-radius: 5px;
  padding: 12px;
}

.ar-entity-summary-message-textarea {
  background: white;
  color: #002b63;
  border-radius: 5px;
  padding: 12px;
}

.ar-entity-summary-message-textarea.dragging {
  border: 2px solid blue;
  /* border-color: blue; */
}

.ar-entity-summary-message-textarea-minimized .ck-editor__editable {
  min-height: 100px;
  max-height: 100px;
}

.ar-entity-summary-message-rounded-button {
  outline: none !important;
  background: none;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
}

.ar-entity-summary-message-unread-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  background: #1e73f0;
  color: white;
  border-radius: 50%;
  font-size: 12px;
}

.ar-entity-summary-message-send-button {
  color: #7c98b6;
}

.ar-entity-summary-message-send-button:hover {
  background: #e6edf4;
}

.ar-entity-summary-message-send-button:disabled {
  opacity: 0.4;
  background: none;
  cursor: default;
}

.ar-entity-summary-messages-count {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  background: #1e73f0;
  color: white;
  border-radius: 50%;
}

.ar-entity-summary-no-messages {
  background: url("../../../Assets/Svg/empty_list_chat.svg");
  width: 100%;
	user-select: none;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 135px;
}
.ar-entity-summary-no-notes {
  background: url("../../../Assets/Svg/empty_list_chat_notes.svg");
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 135px;
}

.ar-entity-summary-message-typing {
  margin-top: 10px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.ar-entity-summary-message-typing > div {
  background: #f9fbff;
  transition: 0.3s;
  transform: translateY(100%);
  height: 36px;
}

.ar-entity-summary-message-typing.active {
  height: 36px;
}

.ar-entity-summary-message-typing.active > div {
  transition: 0.3s;
  transform: translateY(0);
}

.ar-message-files {
  margin-left: -0.25rem;
  overflow: hidden;
}

.ar-message-file > div {
  -webkit-user-select: none;
  user-select: none;
  margin: 0.15rem 0.25rem;
  /* width: 180px; */
  padding-top: 7px;
}

/* .ar-message-file-icon {
} */

.ar-message-file-image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  box-sizing: border-box;
  pointer-events: none;
  /* max-height: 180px; */
}

.ar-messages-header-item {
  position: relative;
}

.ar-messages-header-item.selected {
  font-weight: 700;
  /* box-shadow: inset 0px -1px 0px black;  */
  /* box-shadow: inset 0px -1px 0px black; */
}

.ar-messages-header-item.selected::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  z-index: 4;
  background: #002b63;
  border-radius: 30px;
}

.ar-message-sender {
	/* transition: height linear 0.3s; */
  /* max-height: 60%; */
}
