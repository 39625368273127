.ssi-button {
  cursor: pointer;
  outline: 0;
  min-width: 100px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 28px 10px;
  background-color: transparent;
  transition: 0.3s;
  outline: none !important;
  border-radius: 5px;
  text-decoration: none !important;
}
.ssi-button.sm {
  min-width: auto;
  height: 32px;
  padding: 0 10px;
}

.ssi-button.sm-40 {
  min-width: 40px;
  height: 40px;
  padding: 0 10px;
}



.ssi-button.xs {
  min-width: auto;
  height: 24px;
  padding: 0 6px;
}

.w-auto {
  width: auto;
}
.c-grid-body .ssi-button {
  height: 32px;
  width: 32px;
}

.h-32 {
  min-width: auto;
  height: 32px;
  padding: 0 10px;
}

.ssi-button:disabled {
  cursor: default;
}

button.ssi-button.ssi-control.ssi-button-primary-ghost.sm.float-right {
  padding: 4px 9px !important;
}

.ssi-button-spinner {
  width: 16px;
}

.ssi-button-link {
  border: none;
  transition: 0.3s;
  width: 100%;
  font-weight: 400;
  color: #002b63;
  /* background: #0db3cf; */
  /* background: #1e73f0;
  color: white; */
}
.ssi-button-link:hover,
.ssi-button-link.selected,
.ssi-button-link:hover .ar-filterlist-icons {
  border-radius: 0.25rem;
  background: rgba(20, 115, 230, 0.1);
  color: #007bff !important;
}
button:disabled.ssi-button-link {
  color: #7c98b6 !important;
  background: white;
}
/* button:disabled.ssi-button-link:hover {
  background: #eaf0f6;
} */

.ssi-button-primary {
  border: none;
  /* background: #0db3cf; */
  background: #5757D9;
  color: white;
}
.ssi-button-primary:hover {
  /* background: #0ec4e4; */
  background: #4646AE;
  color: white;
}
.ssi-button-primary:active {
  /* background: #0ca7c2; */
  background: #343482;
}
.ssi-button-primary:disabled {
  background: #DDDDF7;
  color: #9A9AE8;
}

.ssi-button-tertiary {
  border: 1px solid #BCBCF0;
  /* background: #0db3cf; */
  background: white;
  color: #5757D9;
}
.ssi-button-tertiary:focus{
	border-color: inherit;
}

.ssi-button-tertiary:hover {
  /* background: #0ec4e4; */
  background: #DDDDF7;
  color: #5757D9;
}
.ssi-button-tertiary:active {
  /* background: #0ca7c2; */
  background: #BCBCF0;
}
.ssi-button-tertiary:disabled {
  background: #eaf0f6;
  color: #7c98b6;
}




.ssi-button-primary-ghost {
  border: 1px solid #1e73f0;
  /* background: #0db3cf; */
  background: #fff;
  color: #1e73f0;
}
.ssi-button-primary-ghost:hover {
  /* background: #0ec4e4; */
  background: #1e73f0;
  color: white;
}
.ssi-button-primary-ghost:active {
  /* background: #0ca7c2; */
  background: #1e73f0;
  color: white;
}
.ssi-button-primary-ghost:disabled {
  border-color: #eaf0f6;
  background: #eaf0f6;
  color: #7c98b6;
  /* opacity: 0.3; */
}

.ssi-button-secondary-ghost {
  border: 1px solid #5c5ce6;
  /* background: #0db3cf; */
  background: #fff;
  color: #5c5ce6;
}
.ssi-button-secondary-ghost:hover {
  /* background: #0ec4e4; */
  background: #37248f;
  color: white;
}
.ssi-button-secondary-ghost:active {
  /* background: #0ca7c2; */
  background: #5c5ce6;
  color: white;
}
.ssi-button-secondary-ghost:disabled {
  border-color: #eaf0f6;
  background: #eaf0f6;
  color: #7c98b6;
  /* opacity: 0.3; */
}

.ssi-button-danger-ghost {
  border: none;
  /* background: #0db3cf; */
  background: inherit;
  color: #ff5962;
}
.ssi-button-danger-ghost:hover {
  /* background: #0ec4e4; */
  background: #ff5962;
  color: white;
}
.ssi-button-danger-ghost:active {
  /* background: #0ca7c2; */
  background: #ff5962;
  color: white;
}
.ssi-button-danger-ghost:disabled {
  background: #eaf0f6;
  color: #7c98b6;
  opacity: 0.3;
}

.ssi-button-danger-ghost-alt {
  border: 1px solid #ff5962;
  /* background: #0db3cf; */
  background: white;
  color: #ff5962;
}
.ssi-button-danger-ghost-alt:hover {
  /* background: #0ec4e4; */
  background: #ff5962;
  color: white;
}
.ssi-button-danger-ghost-alt:active {
  /* background: #0ca7c2; */
  background: #ff5962;
  color: white;
}
.ssi-button-danger-ghost-alt:disabled {
  background: #eaf0f6;
  color: #7c98b6;
  opacity: 0.3;
}
.ssi-button-danger-ghost-alt:focus {
  border-color: inherit;
}
.ssi-button-secondary {
  border: none;
  /* background: #0db3cf; */
  background: #5c5ce6;
  color: white;
}
.ssi-button-secondary:hover {
  /* background: #0ec4e4; */
  background: #37248f;
  color: white;
}
.ssi-button-secondary:active {
  /* background: #0ca7c2; */
  background: #5c5ce6;
}
.ssi-button-secondary:disabled {
  background: #eaf0f6;
  color: #7c98b6;
}

.ssi-button-outline-primary {
  border: solid 1px #1e73f0;
  color: #1e73f0;
}
.ssi-button-outline-primary:hover {
  /* background: #0ec4e4; */
  background: #1e73f0;
  color: white;
}
.ssi-button-outline-primary:active {
  border: solid 1px #1e73f0;
  /* background: #0ca7c2; */
  background: #1e73f0;
  color: white;
}
.ssi-button-outline-primary:disabled {
  background: #eaf0f6;
  color: #7c98b6;
  border: solid 1px #eaf0f6;
  /* opacity: 0.3; */
}
.ssi-button-outline-primary:disabled:hover {
  border: solid 1px #eaf0f6;
  color: #7c98b6;
}

.ssi-button-danger {
  border: none;
  /* background: #0db3cf; */
  background: #ff5962;
  color: white;
}
.ssi-button-danger:hover {
  /* background: #0ec4e4; */
  background: #ff6e75;
  color: white;
}
.ssi-button-danger:active {
  /* background: #0ca7c2; */
  background: #ff6e75;
}
.ssi-button-danger:disabled {
  background: #eaf0f6;
}
.ssi-button-primary.disabled {
  background: #eaf0f6;
  pointer-events: none;
  color: #7c98b6;
}
.ssi-button-outline-danger {
  background: white;
  border: solid 1px #ff5962;
  color: #ff5962;
}

.ssi-button-outline-danger:focus {
  border-color: #ff5962;
}

.ssi-button-outline-danger:hover {
  background: #ff5962;
  color: white;
  border-color: #ff5962;
}
.ssi-button-outline-danger:active {
  border: solid 1px #ff5962;
  background: #ff5962;
  color: white;
}
.ssi-button-outline-danger:disabled {
  background: #eaf0f6;
  border-color: #eaf0f6;
  color: #7c98b6;
}

.ssi-button-warning {
  border: none;
  /* background: #0db3cf; */
  background: #f7bf19;
  color: white;
}
.ssi-button-warning:hover {
  /* background: #0ec4e4; */
  background: #f7c83d;
  color: white;
}
.ssi-button-warning:active {
  /* background: #0ca7c2; */
  background: #f7c83d;
}
.ssi-button-warning:disabled {
  background: #eaf0f6;
  color: #7c98b6;
}

.ssi-button-link-danger {
  padding: 0;
  min-width: 1px;
  font-weight: 400;
  border: none;
  background: none;
}

.ssi-button-link-danger {
  color: #ff5962;
}

.ssi-button-link-danger:hover {
  border: none;
  background: none;
  text-decoration: underline;
  color: rgb(236, 49, 49);
}

.ssi-button-link-warning {
  border: none;
  transition: 0.3s;
  width: 100%;
  font-weight: 400;
  color: #002b63;
}

.ssi-button-link-warning:hover {
  color: red !important;
  background-color: #fff6f7 !important;
}

.text-hover_underline:hover {
  text-decoration: underline;
}

.ssi-button-link-primary {
  padding: 0;
  min-width: 1px;

  border: none;
  background: none;
}

.ssi-button-link-primary {
  color: #323D49;
}
.ssi-button-link-primary:hover {
  color: #1e73f0;
}
.ssi-button-link-secondary {
  padding: 0;
  min-width: 1px;

  border: none;
  background: none;
  color: #5c5ce6;
}

.ssi-button-link-primary:hover,
.ssi-button-link-secondary:hover {
  border: none;
  background: none;
  text-decoration: underline;
}
.ExtraActionButton {
  min-width: 40px;
}
.ssi-button-role-primary,
.ssi-button-role-primary-light {
  padding: 0;
  min-width: 1px;
  height: inherit;
}

.ssi-button-role-primary {
  color: #1e73f0;
  background: #e7f1fd;
}
.ssi-button-role-primary:hover {
  background: rgb(209, 228, 252);
}
button.ssi-button.ssi-control.ssi-button-link-primary:disabled {
  color: #7c98b6;
}
.ssi-button-role-primary-light {
  color: #1e73f0;
  background: white;
}
.ssi-button-role-primary-light:hover {
  background: #e7f1fd;
}

.ar-minimal-button {
  width: 25px;
  min-width: 25px;
  height: 25px;
  padding: 0;
  border: none;

  transition: 0.3s;
}

.ssi-button-primary.ar-minimal-button {
  background: none;
  color: #1e73f0;
}
.ssi-button-primary.ar-minimal-button:hover {
  background: #1e73f0;
  color: white;
}

.ssi-button-minimal-primary {
  border: none;
  background: none;
  color: #1e73f0;
}
.ssi-button-minimal-primary:hover {
  background: #1e73f0;
  color: white;
}
.ssi-button-minimal-primary:active {
  background: #1e73f0;
  color: white;
}
.ssi-button-minimal-primary:disabled {
  color: #d0e3ff;
  background: transparent;
}

.ssi-button-standard-link {
  padding: 0;
  background: none;
  border: 0;
  overflow: hidden;
  min-width: auto;
  font-size: inherit;
}

.ssi-button-standard-link:hover {
  text-decoration: underline;
}
