.unread-count {
	top: -1px;
	right: -1px;
	font-size: 11px;
	font-weight: 500;
	background: #1E73F0;
	border-radius: 50%;
	color: white;
	width: 19px;
	height: 19px;
	display: flex;
	align-items: center;
	user-select: none;
	pointer-events: none;
	justify-content: center;
	border: solid 1px white;
  }
  
