.ar-loading-bar {
  position: fixed;
  width: 100%;
  height: 3px;
  z-index: 1000000000;
}

.grid-loader {
  z-index: 999996 !important;
  /* width: calc(100% + -1px) !important; */
}

.ar-loading-bar.absolute {
  position: absolute;
  z-index: 2;
}

.ar-loading-bar-progress {
  width: 0;
  height: 4px;
  background: #1e73f0;
}

.ar-loading-bar-progress.animating {
  width: 90%;
  transition: 3.4s ease-out;
}

.ar-loading-bar-progress.success {
  width: 100%;
  transition: 0.3s ease-in;
  opacity: 0;
  transition-property: opacity;
}
