.ar-board-container {
  cursor: grab;
}

.ar-board-container.kinetic-grabbing {
  cursor: grabbing;
}

.ar-board-container > div {
  display: inline-flex;
}

.ar-board-list-container {
  width: 300px;
  margin-right: 20px;
  margin-left: 5px;
  border-radius: 7px;

  transition: 0.3s;

  flex: 0 0 auto;
}

.ar-board-list-header {
  padding: 0 10px;
	padding-top: 1px;
  padding-bottom: 10px;
  /* border-bottom: solid 4px grey;
  border-radius: 3px; */
  position: sticky;
  top: 0;
  background: #f9fbff;
  z-index: 2;
}
/* .ar-board-list-header span {
  padding: 0 10px;
} */

.ar-board-list-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #7c98b6;
  height: 4px;
  border-radius: 20px;
}

.ar-board-list-header::before {
  content: "";
  position: absolute;
  right: -13px;
  width: 12px;
  height: 12px;
  top: 12px;
  /* border-style: solid; */
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg);
}

.ar-board-list-header.orange::before, .ar-board-list-header.orange + div .ar-board-list-header-title {
  color: #CC6652;
}
.ar-board-list-header.grey::before, .ar-board-list-header.grey + div .ar-board-list-header-title {
  color: #7C98B6;
}
.ar-board-list-header.danger .ar-board-list-header-title, .ar-board-list-header.danger + div .ar-board-list-header-title{
	color: #CC474E;
}
.ar-board-list-header.blue .ar-board-list-header-title, .ar-board-list-header.blue + div .ar-board-list-header-title{
	color: #124590;
}
.ar-board-list-header.orange .ar-board-list-header-title, .ar-board-list-header.orange + div .ar-board-list-header-title{
	color: #CC6652;
}
.ar-board-list-header.success .ar-board-list-header-title, .ar-board-list-header.success + div .ar-board-list-header-title{
	color: #2B8966;
}  
.ar-board-list-header.purple .ar-board-list-header-title, .ar-board-list-header.purple + div .ar-board-list-header-title{
	color: #5757D9;
}
.ar-board-list-header.inactive .ar-board-list-header-title, .ar-board-list-header.inactive + div .ar-board-list-header-title{
	color: #4A5B6D;
}

.ar-board-list-header.danger::before {
  border-color: #CC474E;
}
.ar-board-list-header.grey::before {
  border-color: #7C98B6;
}
.ar-board-list-header.blue::before {
  border-color: #124590;
}
.ar-board-list-header.success::before {
  border-color: #2B8966;
}
.ar-board-list-header.purple::before {
  border-color: #7979E1;
}
.ar-board-list-header.orange::before {
  border-color: #FF8066;
}
.ar-board-list-header.inactive::before {
  border-color: #4A5B6D;
}

.ar-board-list-container:last-child .ar-board-list-header::before {
  content: none;
}

.ar-board-list-header.orange::after {
  background: #FF8066;
}
.ar-board-list-header.grey::after {
  background: #7C98B6;
}
.ar-board-list-header.danger::after {
  background: #CC474E;
}
.ar-board-list-header.blue::after {
  background: #124590;
}
.ar-board-list-header.success::after {
  background: #2B8966;
}
.ar-board-list-header.inactive::after {
  background: #4A5B6D;
}

.ar-board-list-header.purple::after {
  background: #7979E1;
}

.board-item-resized-add {
  margin: auto;
  width: 12px;
  font-size: 18px;
  opacity: 1;
  border-radius: 50%;
}

.board-item-dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  border-radius: 50%;
  margin: 10px auto;
}

.board-item-color-orange {
  background: #CC6652;
}
.board-item-color-danger {
  background: #99353B;
}
.board-item-color-blue {
  background: #2b8966;
}
.board-item-color-success {
  background: #2B8966;
}
.board-item-color-grey {
  background: #7C98B6;
}
.board-item-color-purple {
  background: #7979e1;
}
.board-item-color-yellow {
  background: #F7BF19;
}

.board-item-dot-unread {
	box-shadow: 0 0 0 2px #1e73f0;
}

.ar-board-list {
  transition: 0.3s;
  height: 100%;
  padding: 20px 0;
}

.ar-board-list.dragging {
  background: #eff5fd;
}

.ar-board-item {
  position: relative;
  outline: none !important;
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 0px;
  transition: 0.3s;
  /* box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 6px */
}

.ar-board-item > .ar-board-item {
  background: white;
  border: solid 1px #d7dee8;
  border-radius: 7px;
  margin-bottom: 10px;
  overflow: hidden;
  /* padding: 0.5rem;
  padding-bottom: 0; */
  /* transition: .3s; */
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 0px;
  transition: 0.3s;
}

.ar-board-item:hover > .ar-board-item {
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 6px;
  border-color: #1e73f0;
}

.ar-board-item.dragging > .ar-board-item {
  border-color: #1e73f0;
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 6px;
  transition: 0.3s;
}

.filterDragging {
  border-color: #1e73f0;
  box-shadow: 0 0 10px rgba(227, 231, 241, 1);
  transition: 0.3s;
}

.ar-board-item > div.unread {
  overflow: hidden;
  border: solid 2px #1e73f0;
}

/* .ar-board-item div.unread::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #1e73f0;
  top: 0;
  left: 0;
} */

.ar-board-item .ar-board-time{
	font-size: 10px;
}

.ar-board-time {
  display: inline-block;
  background: rgba(30, 114, 240, 0.1);
  color: #2b8966;
}

/* .ar-board-item:last-child {
  margin-bottom: 0;
} */

.ar-board-pics > * {
  margin-right: 0 !important;
}

.ar-board-pics > *:first-child {
  /* margin-right: -8px !important; */
  z-index: 1;
}

.ar-board-item .ar-board-item-header-tools {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 35px;
  background: white;
  display: none;
  /* display: flex; */
  align-items: center;
  /* justify-content: flex-end; */
  border: solid 1px #d7dee8;
  border-top: none;
  border-right: none;
  border-radius: 7px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
}

.ar-board-item:hover .ar-board-item-header-tools {
  display: flex;
}
