.counterInputContainer{
	border:1px solid #D5DEE8;
	background-color: #F9FBFF;
	border-radius: 3px;
	display: inline-flex;
	align-items: center;
	user-select: none;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
}

/* Chrome, Safari, Edge, Opera */
.counterInputContainer input::-webkit-outer-spin-button,
.counterInputContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.counterInputContainer input[type=number] {
  -moz-appearance: textfield;
}
