.ImportComponent.ModalContainer {
  width: 1200px;
	height: 90vh;
	max-height: 800px;
  border-radius: 3px;
  padding: 1.25rem;
}

.ImportComponent .HeaderImportPaging {
  width: 750px;
  height: 30px;
  position: relative;
}
.ImportNameInput::placeholder {
  color: #7c98b6;
}

.Border-Radius-table{
	border-radius: 5px;
}

.ar-contacts-merge-table tr:hover {
	background-color: #1473e610 !important;
}

.merge-altered-element-box {
	width: 277px;
	border-radius: 5px;
	height: 120px;
	margin: 0.75rem;
}
.merge-altered-element-container {
	margin: -0.75rem;
	margin-left: 0.75rem;
}
.HeaderImportPaging .HeaderPagingCircle {
  width: 23px;
  height: 21px;
  border: 1px solid #7c98b6;
  border-radius: 50%;
  z-index: 3;
  color: white;
  background: white;
  position: relative;
}
.disabled-dropdown-option {
  pointer-events: none;
  cursor: auto;
  color: #7c98b6;
}
.ImportSelectCards.Checked {
  background: #e7f1fd;
  border: 2px solid #1e73f0;
}

.ImportSelectCards:hover {
  background: rgba(20, 115, 230, 0.1);
}

.ImportSelectCards {
  background: white;
  width: 400px;
  height: 125px;
  border: 1px solid #d5dee8;
}
.ImportComponentFileUploader {
  width: 460px;
  margin: auto;
}
.ImportComponentSecondPageDescript {
  width: 500px;
  text-align: center;
  margin: auto;
}
.ImporTabel {
  height: 425px;
}
.HeaderPagingCircle.Midway {
  background-color: #1e73f0;
  border-color: #1e73f0;
  color: #1e73f0 !important;
}
.HeaderPagingCircle.Complete {
  background-color: #1e73f0;
  border-color: #1e73f0;
  color: white !important;
}
.HeaderPagingCircle-WithLine {
  content: "";
  width: 50%;
  margin-top: 10px;
  z-index: 1;
  height: 2px;
  background: #7c98b6;
}

.HeaderPagingCircle-WithLine.Complete {
  background: #1e73f0 !important;
}

.TableImportBorderRounded {
  border-radius: 3px !important;
}

.Bg-HeaderBlueCollumn {
  background-color: #ebf1f7 !important;
}

.HeaderPagingCircle .HeaderPageTitle {
  position: absolute;
  top: 110%;
}
