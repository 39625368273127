.ar-range-input {
  -webkit-appearance: none;
  margin: 7px 0;
  /* width: 100%; */
}
.ar-range-input:focus {
  outline: 0;
}
.ar-range-input::-webkit-slider-runnable-track {
  transition: all 0.2s ease;
  width: 100%;
  height: 5px;
  border: 0;
  cursor: pointer;
  background: #d5dee8;
}
.ar-range-input::-webkit-slider-thumb {
  height: 28px;
  width: 8px;
  background: white;
  border-radius: 30px;
  border: solid 1px #cdd5ea;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12px;
}

.ar-range-input::-moz-range-track {
  transition: all 0.2s ease;
  width: 100%;
  height: 5px;
  border: 0;
  cursor: pointer;
  background: #d5dee8;
}
.ar-range-input::-moz-range-thumb {
  height: 28px;
  width: 8px;
  background: white;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  border-radius: 30px;
  border: solid 1px #cdd5ea;
}
.ar-range-input::-ms-track {
  transition: all 0.2s ease;
  width: 100%;
  height: 4px;
  border: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: solid transparent;
  border-width: 7px 0;
}
.ar-range-input::-ms-fill-lower {
  background: #1e73f0;
}
.ar-range-input::-ms-fill-upper {
  background: #ecf0f1;
}
.ar-range-input::-ms-thumb {
  height: 14px;
  width: 14px;
  background: #c9c9c9;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 0;
}

.ar-range-input::-ms-thumb:hover {
  background: #1e73f0 !important;
  border: solid 1px #1e73f0;
}

.ar-range-input::-webkit-slider-thumb:hover {
  background: #1e73f0 !important;
  border: solid 1px #1e73f0;
}

.ar-range-input::-webkit-slider-thumb:hover {
  background: #1e73f0 !important;
  border: solid 1px #1e73f0;
}

.ar-range-input::-moz-range-thumb:hover {
  background: #1e73f0 !important;
  border: solid 1px #1e73f0;
}
