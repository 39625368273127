.ar-loader__spinner {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  stroke: currentColor;
}

.ar-loader__progress {
  height: 100%;
  transform-origin: center center;
  width: 100%;
}

.ar-loader__spinner-lg {
  width: 128px;
  height: 128px;
}

.ar-loader__spinner-sm {
  width: 64px;
  height: 64px;
}

.ar-loader__spinner-xs {
  width: 32px;
  height: 32px;
}
.ar-modal-preview-file-content-close-button {
  position: absolute;
  top: 0;
  left: -50px;
  flex-direction: column;
  color: #7c98b6;
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ar-modal-preview-file-content {
  /* height: 100%; */

  overflow: visible !important;
}

.ar-modal-preview-files-content-files {
  width: 80%;
  height: 100%;
  overflow: visible !important;
}
/* .ar-modal-preview-image-content {
   width: unset !important; 
} */
.ar-loader__spinner .ar-loader__spinner__path {
  stroke-dasharray: 200, 200;
  stroke-dashoffset: 200;
  /* animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite; */
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
