.ReactPasswordStrength {
  border-radius: 3px;
  color: #090809;
  margin-top: 5px;
  position: relative;
}

.ReactPasswordStrength-input:not(:focus).is-password-invalid {
  color: #d1462f;
}
.ReactPasswordStrength-input:focus {
  outline: none;
}

.ReactPasswordStrength-strength-desc {
  color: transparent;
  margin-top: 5px;
  font-size: 14px;

  transition: color 250ms ease-in-out;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
  color: #ff0000;
}
.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
  color: #ff8066;
}
.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
  color: #36ab80;
}
.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
  color: #36ab80;
}
.ReactPasswordStrength.is-strength-5 .ReactPasswordStrength-strength-desc {
  color: #5c5ce6;
}

.ReactPasswordStrength-strength-bar {
  box-sizing: border-box;
  height: 6px;
  border-radius: 3px;
  position: relative;
  transition: width 300ms ease-out;
  width: 0;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
  background: #ff0000;
  width: 25%;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
  background: #ff8066;
  width: 50%;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
  background: #36ab80;
  width: 75%;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
  background: #36ab80;
  width: 75%;
}

.ReactPasswordStrength.is-strength-5 .ReactPasswordStrength-strength-bar {
  background: #5c5ce6;
  width: 100%;
}
