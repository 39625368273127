.ColumnChooser-ModalWrap {
  top: 267px;
  left: 496px;
  width: 928px;
  height: 558px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}
.p-30 {
  padding: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ColumnChooserContent {
  width: 434px;
  height: 394px;
}

.disableLabelSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.invisibleBorderItem {
  border: 1px solid transparent;
}
.chosenItemActive {
  border: 1px solid #1e73f0;
}

.w-a {
  width: auto;
}
.ColumnChooserContent-borderRight {
  border-right: #d5dee8 solid 1px;
}

.ColumnChooserCheckList {
  /* height: 340px; */
  overflow: hidden;
  overflow-y: auto;
}

.ColumnChooserOrderList {
  height: 340px;
  overflow: auto;

  padding-right: 10px;
}

.ColumnChooserDragableContainer {
  background: #f9fbff;
  border: 1px solid #d5dee8;
  /* margin-top: 10px;
	margin-bottom: 10px; */
  /* padding-top: 7px; */
  border-radius: 3px;
  opacity: 1;
  width: 382px;
  height: 40px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.GripVerticalIcon {
	width: 32px;
	cursor: move;
	padding: 0 0.75rem;
	color: #d5dee8;
	background: transparent;
}

.column-chooser-search-bar {
  background-color: #f9fbff;
}
.dragging .ColumnChooserDragableContainer {
  border-color: #1e73f0 !important;
}

.dragging .GripVerticalIcon {
  color: #1e73f0 !important;
}

.PointerEventsRemove {
  pointer-events: none;
}

.column-chooser-search-bar:focus-within {
  border-color: #007bff;
}

.column-chooser-search-bar input {
  color: #002b63 !important;
}

/* .column-chooser-search-bar input::placeholder { */
/* Chrome, Firefox, Opera, Safari 10.1+ */
/* color: #002b63; */
/* opacity: 1; */
/* Firefox */
/* } */
