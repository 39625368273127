.ar-entity-icon-overview{
    margin: 0 -4px;
}

.ar-entity-icon-overview > div{
    margin: 0 4px;
    display: inline-block;
}

.ar-entity-icon-overview-img{
    margin-right: 4px;
}