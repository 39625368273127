.img {
  width: 48px;
  height: 48px;
  /* flex: 0 0 48px; */
  border: solid 2px #d5dee8;
  user-select: none;
}

img.img {
  display: block;
}
.logo {
  width: 379px;
  height: 94px;
}
.img.lg {
  width: 64px;
  height: 64px;
  /* flex: 0 0 64px; */
}
/* .img.sm {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
} */
.img.avatar.sm {
  width: 32px;
  height: 32px;
  /* flex: 0 0 32px; */
}
.img.xsm {
  width: 24px;
  height: 24px;
  /* flex: 0 0 24px; */
}

.img.country.lg {
  width: 64px;
  height: 32px;
  /* flex: 0 0 64px; */
}
.img.country.sm {
  width: 32px;
  height: 24px;
  /* flex: 0 0 32px; */
}

.img.country.xsm {
  width: 24px;
  height: 18px;
  /* flex: 0 0 24px; */
}

.img.circular {
  border-radius: 50%;
}

.img.country {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border: none;
}

.img.account span {
  text-decoration: none !important;
}

.ar-vertical-header .img.account,
.ar-vertical-header .img.country {
  margin: 0;
  margin-bottom: 5px;
}
.img.account span:first-child {
  letter-spacing: 1px;
}
.img.account.sm,
.img.country.sm {
  font-size: 12px;
}
.img.account.xsm,
.img.country.xsm {
  font-size: 10px;
}
.img.account.lg,
.img.country.lg {
  font-size: 26px;
}

.SocialIconFacebook {
  background: transparent url("../../Assets/Svg/SocialSVG/facebook-2.svg") 0% 0%
    no-repeat padding-box;
  background-size: 50%;
  background-position: center;
}

.SocialIconInstagram {
  background: transparent url("../../Assets/Svg/SocialSVG/Instagram.svg") 0% 0%
    no-repeat padding-box;
  background-size: 50%;
  background-position: center;
}

.SocialIconTwitter {
  background: transparent url("../../Assets/Svg/SocialSVG/twitter.svg") 0% 0%
    no-repeat padding-box;
  background-size: 84%;
  background-position: center;
}

.SocialIconLinkedIn {
  background: transparent url("../../Assets/Svg/SocialSVG/linkedin.svg") 0% 0%
    no-repeat padding-box;
  background-size: 72%;
  background-position: center;
}

.SmallSocialIconCircle {
  position: absolute;
  background: white;
  background-position: center;
  width: 24px !important;
  height: 24px !important;
  max-height: 24px !important;
  border-radius: 50%;
  bottom: 3px;
  left: 25px;
}

.AtDescript {
  text-transform: lowercase;
  color: #7c98b6;
}
