.ar-entity-summary-tabstrip {
  background: #eef2f5;
  padding: 4px 2px;
  height: 40px;
  display: inline-flex;
  border-radius: 8px;
}

.ar-entity-summary-tabstrip > div {
  font-size: 14px;
  border-radius: 5px;
  /* width: 120px; */
  flex: 1;
  height: 100%;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
}

.ar-entity-summary-tabstrip > div:hover,
.ar-entity-summary-tabstrip > div.active {
  background: white;
  color: #1e73f0;
  box-shadow: 0 1px 3px #00000026;
}

.ar-entity-summary-header {
  height: 57px;
  box-shadow: 0 3px 10px #0000001a;
  z-index: 2;
  transition: 0.3s;
}

.ar-entity-summary-container {
  /* box-shadow: 3px 0px 10px #0000001a; */
  z-index: 0;
}

.ar-entity-summary-container .fs-14-enforced {
  font-size: 14px !important;
}
