.ar-modal {
  transition: 0.3s;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000001;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ar-modal.active {
  background: rgba(0, 43, 99, 0.4);
}

.ar-modal-content {
  transform: scale(0.85);
  transform-origin: top;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  /* box-shadow: 0 0 24px rgba(1, 33, 72, 0.24); */
  box-shadow: 0 0 124px 5px rgba(0,0,0,.24);
  max-height: 90%;
  border-radius: 7px;
  background: white;
}

.ar-modal.active .ar-modal-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
