.ar-treeview-item {
  transition: 0.2s;
  display: flex;
  align-items: center;
  user-select: none;
  /* cursor: pointer; */
  height: 42px;
}

.ar-treeview-buttons {
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  right: 0;
  background: #e7f1fd;
  height: 42px;
  width: 110px;
}

.ar-treeview-orin-item-sidebar-link{
	padding: 0px !important;
	height: 100% !important;
	background: transparent !important;
	color: #002b63 !important;
}

.ar-treeview-orin-item-sidebar-link:hover{
	text-decoration: underline !important;
	color: #002b63 !important;
}



.ar-treeview-item:hover .ar-treeview-buttons {
  opacity: 1;
}

.ar-treeview-item:hover {
  background: #e7f1fd;
}

.ar-treeview-item-icon {
  width: 12px !important;
  height: 14px;
  transition: 0.3s;
  margin-right: 0.5rem;
}

.ar-treeview-item-icon svg {
  width: 100% !important;
  height: 100% !important;
  display: block;
  transition: 0.3s;
}

.ar-treeview-item.open .ar-treeview-item-icon > svg {
  transform: rotate(90deg);
}

.ar-treeview-item-content {
  padding: 0 16px;
  overflow: hidden;
}

.ar-treeview-child {
  margin-left: 22px;
  /* position: relative; */
}
/* 
.ar-treeview-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background: #D5DEE8;
} */

.ar-treeview-no-items {
  background: url("../../Assets/Svg/empty_list_treeview.svg");
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 135px;
}
