.ar-sidebar-container {
  /* width: 640px; */
  height: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  display: block;
  margin: auto;
  /* font-size:15px; */
  z-index: 9999999;
  transform: translateX(-100%);
  transition: transform 0.3s, transform 0.3s ease;
  /* visibility: hidden; */
  /* animation: Transformleft .4s; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0 124px 5px rgba(0,0,0,.24);
}
.ar-sidebar-container {
  right: 0;
  transform: translateX(120%);
  /* animation: Transformright .4s; */
}

.ar-sidebar-container.active {
  /* visibility: visible; */
  transform: translateX(0%);
}

.disableFlexColumn {
  flex-direction: row !important;
}
.ar-sidebar-close-button {
  position: absolute;
  color: #7c98b6;
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: 8px;
  left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ar-sidebar-header {
  height: 57px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 10px #0000001a;
  z-index: 1;
}

.ar-sidebar-modal {
  height: 90%;
  width: 85%;
  box-shadow: none;
  background: none;
}

/* .ar-sidebar-modal > div {
  box-shadow: 0 0 24px rgba(1, 33, 72, 0.24);
} */

.ar-sidebar-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000000;
}

.ar-sidebar-close-button-alt {
  color: #7c98b6;
}
.ar-sidebar-close-button-alt > svg {
  width: 16px;
  height: 16px;
}
