.ar-hourpicker {
  transition: 0.3s;
}

.ar-Timepicker {
  border: 1px solid white;
  box-shadow: 0px 0px 9px 1px #d8d8d8;
  border-radius: 3px;
}

.ar-hourpicker-input-container {
  width: 100%;
  height: 100%;
}

.ar-hourPicker {
  padding: 10px 0 10px 0;
  width: 490px;
  height: auto;
  overflow: hidden;
  text-align: center;
  word-break: break-word;
  background: white;

  cursor: pointer;
  user-select: none;
}
.activeTime {
  background: #b2d6ff;
}

.ar-hourPicker .active {
  background: #061d4b !important;
  color: #fff !important;
}

.ar-timePickerSquareSelecter {
  border-radius: 3px;
}

.ar-timePickerSquareSelecter:hover {
  background: #e7f1fd;
  color: #012c64;
}

.ar-minutePicker .active {
  background: #061d4b;
  color: #fff !important;
}
.ar-minutePicker {
  padding: 10px 0 10px 0;
  user-select: none;
  width: 545px;
  height: auto;
  overflow: hidden;
  word-break: break-word;
  background: white;

  cursor: pointer;
}

.ar-timePickerSquareSelecter--hovering {
  background: #e7f1fd;
  color: #012c64;
}

.ar-TimePicker-Footer {
  background: #ffffff;
  height: 50px;
  box-shadow: 0px -3px 10px #e3e7f180;
  border-radius: 0px 0px 2px 2px;
}

.ar-hourpicker-input-container input {
  border: none;
  width: auto;
  outline: none;
  text-align: center;
  width: 100%;
  height: 100%;
  text-align: right;
  background: inherit;
  margin-right: 2px;
}

.ar-hourpicker-input-container input:last-child {
  text-align: left;
  margin-left: 2px;
  margin-right: 0;
}

.ar-hourpicker button {
  color: #1e73f0;
  background: inherit;
  border: 0;
  border-radius: 0;
  height: 100%;
  width: 40px;
  min-width: 40px;
  padding: 0;
  border-left: solid 1px #d5dee8 !important;
  overflow: hidden;
}
.ar-hourpicker button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ar-hourpicker button:hover {
  color: #012c64;
  background: #e7f1fd;
}
