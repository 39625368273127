.DocTemplateTargetItem {
  color: #232357;
  display: flex;
  align-items: center;
}

.DocTemplateTargetItemSimple{
	color: #232357;
	display: flex;
  align-items: center;
	border-radius: 50%;
	height: 24px;
	width: 24px;
	justify-content: center;
	background: #E5EAF0;
	margin-right: 0.5rem;
}