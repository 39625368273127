.ar-onboarding-list-item {
  background: white;
}

.ar-onboarding-list-item label {
  height: 100%;
}

.ar-onboarding-list-item .checkbox-container {
  width: 24px;
  height: 24px;
}

.ar-onboarding-list-item.selected {
  background: #e7f1fd;
  border-color: var(--purple50);
}

.ar-onboarding-list-input {
  width: 100%;
  border: none;
  outline: none;
}
