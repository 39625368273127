.Tabstrip-link {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  color: #022d64;
  padding: 16px 15px;
  font-size: 14px;
}
.Tabstrip-top-menu {
  text-align: center;
  border-bottom: 1px solid #dde4ed;
  padding: 0 10px;
  z-index: 1;
}
.Tabstrip-top-menu.left {
  text-align: left;
}
.Tabstrip-link.selected {
  font-weight: 500 !important;
}
.Tabstrip-link.selected:after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -3px;
  display: block;
  border-radius: 100px;
  left: 0;
  background-color: #002b63;
}
.Tabstrip-content {
  padding: 10px 0;
}
