.appBarContainer::-webkit-scrollbar {
  width: 4px !important;
}

.appBarContainer::-webkit-scrollbar-track {
  background: #061d4b;
  color: #061d4b;
}

.appBarContainer::-webkit-scrollbar-thumb {
  border: none;
}

.appBarContainer {
  background: #11112b;
  /* min-width: 72px; */
  position: absolute;
  left: 0;
  z-index: 10005;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-x: visible;
  overflow-y: auto; */
  align-items: center;
  padding: 8px 0px;
  /* border-right: #343482 2px solid; */
  transition: 0.25s;
  user-select: none;
}

.appBarResizerBar {
  width: 8px;
  height: 100%;
  right: -8px;
  background: transparent;
  position: absolute;
  z-index: 2342344;
  bottom: 0;
  cursor: w-resize;
}
.appBarResizerBar:hover {
  /* border-right: solid 4px var(--purple50); */
}
.appBarResizerBarDragging {
  /* border-right: solid 4px var(--purple50); */
}

.appBarContainerPadder {
  padding: 0px 8px;
  width: 100%;
}
.appBarNewContainerPadder {
  padding: 0px 12px;
  width: 100%;
}

/* .appBarDisableAccordionContent:not(:hover)
  .appBarAccordionDisableContentContent {
  display: none;
} */

.appBarContainer.appBarHoverActive {
  width: 240px;
}

.appBarStopHideWhileDragging {
  transition: none;
}

.appBarPadderContainer:not(.appBarFixedToggled)
  .appBarContainer:not(.appBarHoverActive):not(.appBarStopHideWhileDragging) {
  width: 64px !important;
}

.appBarPadderContainer:not(.appBarFixedToggled)
  .appBarContainer:not(.appBarHoverActive):not(.appBarStopHideWhileDragging)
  .appBarWithOverflowOverlay {
  overflow: hidden !important;
}

.appBarWithOverflowOverlay {
  overflow-y: overlay;
}

.appBarPadderContainer.appBarFixedToggled .appBarContainer {
  width: 240px;
  position: relative !important;
}

/* .appBarPadderContainer.appBarFixedToggled .appbarStickyBlock{
	transform: translateX(0px) !important;
  opacity: 1;
  pointer-events: all;
} */

.appBarContainer a:hover,
.appBarContainer a:visited,
.appBarContainer a:link,
.appBarContainer a:active {
  text-decoration: none;
}

.appBarContainer.alterEgo,
.appBarSecondaryContainer.alterEgo,
.appBarContainer.alterEgo .appBarAirdeskLogo {
  background: #263238 !important;
  border-color: #888888 !important;
}

.appbarStickyBlock {
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  transform: translateX(-104px);
  right: -64px;
  z-index: 10001;
  display: flex;
  pointer-events: none;
  transition-duration: 0.3s;
  opacity: 0;
}

.appbartFavoriteTitle {
  color: #ddddf7;
}

.appBarPadderContainer {
  padding-left: 64px;
  height: 100%;
  position: relative;
  overflow: visible;
}

.appBarPadderContainer.appBarFixedToggled {
  padding-left: 0px !important;
}

.appbarStickyButton:hover {
  background: #5757d9;
  color: white;
}

.appbarStickyButton {
  background: #11112b;
  border-radius: 50%;
  width: 40px;
  transition-duration: 0.3s;
  color: #9a9ae8;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  /* box-shadow: 0px 0px 8px 4px rgba(35, 35, 87, 0.35); */
  box-shadow: 0px 0px 8px 4px rgba(74, 91, 109, 0.25);
}

.appbarStickyButton > svg {
  pointer-events: none;
}

.appBarSecondaryContainer .appbarStickyBlock {
  transform: translateX(-104px) !important;
  opacity: 0 !important;
  transition-duration: 0s;
}
.appBarSecondaryContainer:hover .appbarStickyBlock {
  transform: translateX(0px) !important;
  opacity: 1 !important;
}

.appBarContainer.appBarHoverActive .appbarStickyBlock,
.appbarStickyBlock.forcedActivate {
  transform: translateX(0px);
  opacity: 1;
  pointer-events: all;
}

.IsSticky {
  position: relative !important;
  transition: none !important;
  left: -1px !important;
  margin-right: -1px !important;
}

.appBarSecondaryContainerDragger {
  user-select: none;
  background: transparent;
  height: 100%;
  width: 3px;
  z-index: 999999999;
  position: absolute;
  top: 0;
  right: 0px;
  transition: none;
  cursor: col-resize;
}

.appBarSecondaryContainerDragger:hover {
  background: #1e73f0;
}

.appBarSecondaryContainer {
  background: #11112b;
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999999;
  position: absolute;
  top: 0;
  width: 0;
  transition: 0.4s width cubic-bezier(0.56, -0.07, 0, 0.96);
  left: 60px;
  /* border-left: #263664 2px solid; */
}
.appBarSecondaryContainer.IsFixed {
  position: relative;
  left: 0px;
  width: 221px;
  transition: none;
}
.appBarSecondaryContainer.IsOpen {
  width: 240px;
}
.AppBarFixedToggler {
  position: absolute;
  top: 20px;
  left: 43px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1e73f0;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 999999;
  justify-content: center;
}
.AppBarFixedToggler.TogglerFixed {
  background: #061d4b;
  left: 261px;
  border: 1px solid #263664;
  transform: rotateY(180deg);
}

.AppSidebarContentContainerForFixed {
  width: 0;
}
.AppSidebarContentContainerForFixed.IsFixed {
  width: fit-content;
}

.favoriteDropableContainer > [data-react-beautiful-dnd-droppable="0"] {
  height: 100%;
}

/* .appBarPadderContainer:not(.appBarFixedToggled)
  .appBarContainer:not(.appBarHoverActive):not(.appBarStopHideWhileDragging) .favoriteDropableContainer{
	overflow-x: hidden !important;
} */
.favoriteDropableContainer {
  overflow-x: hidden;
}

.favoriteDropableContainer::-webkit-scrollbar {
  height: 4px !important;
  padding: 0 !important;
}

/* .favoriteDropableContainer::-webkit-scrollbar-track {
  background: #061d4b;
  color: #061d4b;
} */

.favoriteDropableContainer::-webkit-scrollbar-thumb {
  background: #343482 !important;
  border: none;
}

.SecondaryContent {
  overflow: hidden;
  /* padding: 0px 10px; */
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}

/* .SecondaryContent * {
	overflow: hidden !important;
} */

.appBarSecondaryContainer.IsFixed .SecondaryContent,
.appBarSecondaryContainer.IsSticky .SecondaryContent {
  width: 100%;
}

.appBarGroupIcon {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
}

.appBarLinksTitle {
  text-transform: uppercase;
  opacity: 0.6;
  padding-left: 10px;
}

.appBarGroupIcon:hover {
  /* background: #263664; */
  background: #232357;
}
.appBarGroupIcon.selected {
  background: #232357;
}

.appBarGroupIcon.active {
  background: var(--purple50) !important;
}

.ExtraPaddingLeft {
  padding-left: 16px;
}
.appBarPadderContainer:not(.appBarFixedToggled)
  .appBarContainer:not(.appBarHoverActive):not(.appBarStopHideWhileDragging)
  .ExtraPaddingLeft {
  padding-left: 0px;
}

.appBarHoverActive .appBarAccordionChildLink,
.appBarFixedToggled .appBarAccordionChildLink,
.appBarStopHideWhileDragging .appBarAccordionChildLink {
  padding-left: 16px !important;
}

.appBarFavoriteChildItem {
  transition-duration: 0.3s;
}

.appBarPadderContainer:not(.appBarFixedToggled)
  .appBarContainer:not(.appBarHoverActive):not(.appBarStopHideWhileDragging)
  .appBarFavoriteChildItem {
  padding-left: 0px !important;
}

/* .appBarFavoriteChildItem{
	padding-left: 0px !important;
}

.appBarHoverActive  .appBarFavoriteChildItem, .appBarFixedToggled .appBarFavoriteChildItem,.appBarStopHideWhileDragging .appBarFavoriteChildItem {
	padding-left: unset;
} */

.pinnedIcon {
  opacity: 0;
  transition-duration: 0.3s;
  margin-right: 4px;
  width: 32px !important;
}
.appBarButtonWithLabel:hover .pinnedIcon {
  opacity: 1 !important;
}

.appBarAirdeskLogoContainer {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.appBarAirdeskLogoContainer:hover {
  background-color: #343482;
}

.appBarSpaceSelectorDividerBefore {
  margin: 8px 0px;
  /* border-bottom: 1px solid #B0C1D3; */
}
.appBarSpaceSelectorDividerAfter {
  margin: 8px 0px;
  /* border-bottom: 1px solid #B0C1D3; */
}

.appBarSpaceSelectorContainer {
  width: 270px;
  /* overflow-y: auto; */
  border-radius: 4px;
  z-index: 110;
  background: white;
  overflow: hidden;
  padding: 8px 8px;
  height: 380px;
  max-height: calc("100vh-300px");
  /* height: 100%; */
}
/* .appBarSpaceSelectorContainer a {
  color: white !important;
  font-weight: 500;
}
.appBarSpaceSelectorContainer div {
  color: white !important;
  font-weight: 500 !important;
}*/

.appBarSpaceSelectorContainer > span {
  background: white;
}

.appBarContainerHeader {
  /* padding: 4px; */
  border-radius: 4px;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  transition: 0.3s;
  background-color: #232357;
}

.appBarButtonWithLabel {
  /* padding: 8px; */
  width: 100%;
  cursor: pointer;
  justify-content: start;
}
.appBarButtonWithLabel span {
  /* color: #ddddf7; */
  text-decoration: none !important;
}
.appBarButtonWithLabel svg {
  flex: 0 0 auto;
  width: 40px;
}

.appBarButtonWithLabel:hover span {
  color: white !important;
}

/* .appBarContainerHeader:hover {
  background-color: #343482;
} */

.appBarDivider {
  width: 100%;
  border-bottom: 2px solid #343482;
}

.appBarLinkItem {
  height: 32px;
  width: 100%;
  margin-bottom: 2px;
  border-radius: 5px;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  opacity: 0.7;
  padding-left: 10px;
}

.appBarLinksDivider {
  background: #263664;
  height: 1px;
}

.appBarLinkItem:hover {
  /* background: #263664; */
  background: #232357;
}

.appBarLinkItem.selected {
  background: var(--purple50) !important;
  opacity: 1;
}

.appBarLinkItem.selected .badgeSelected {
  background: white !important;
  color: #263664 !important;
  font-weight: 500;
}

.appBarFavorites {
  background: #061d4b 0% 0% no-repeat padding-box;
  width: 221px;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
  position: absolute;
  top: 0;
  transition: 0.2s all;
  left: 51px;
  border-left: #263664 1px solid;
}

.appBarFolderColapseIcon {
  color: #9a9ae8;
  width: 32px;
}

.appBarFolderDragIcon {
  color: #9a9ae8;
  visibility: hidden;
}
.appBarFolderMoreOptionsIcon {
  color: #9a9ae8;
  visibility: hidden;
}

.AppBarFavoriteParentItemContainer:hover .appBarFolderDragIcon,
.AppBarFavoriteParentItemContainer:hover .appBarFolderMoreOptionsIcon,
.appBarFolderContainer:hover .appBarFolderMoreOptionsIcon,
.AppBarFavoriteParentItemContainer.isDragging .appBarFolderDragIcon {
  visibility: visible;
}

.AppBarFavoriteItemContainer {
  /* padding: 8px; */
  height: 40px;
  border-radius: 3px;
}
.AppBarFavoriteItemContainer.active {
  background: var(--purple50) !important;
}

.AppBarFavoriteItemContainer.active * {
  color: white !important;
}

.AppBarFavoriteItemContainer:hover,
.AppBarFavoriteItemContainer.isDragging {
  background: #232357;
}

.AppBarFavoriteItemContainer:hover .appbartFavoriteTitle,
.AppBarFavoriteItemContainer.isDragging .appbartFavoriteTitle {
  color: white;
}

.appBarFolderTitleContainer {
  /* padding: 8px; */
  border-radius: 3px;
  height: 40px;
  width: 100%;
}
.appBarFolderTitleContainer.active {
  background: var(--purple50);
}
.appBarFolderTitleContainer:hover {
  background: #232357;
}
.appBarFolderTitleContainer:hover .appbartFavoriteTitle,
.AppBarFavoriteItemContainer:hover .appbartFavoriteTitle {
  color: white !important;
}

.appBarFolderTitleContainer:hover .appBarFolderColapseIcon,
.AppBarFavoriteItemContainer:hover .appbartFavoriteTitle {
  color: white !important;
}
.appBarFolderTitleContainer:hover .appBarFolderDragIcon,
.AppBarFavoriteItemContainer:hover svg {
  color: white !important;
}
.appBarFolderTitleContainer:hover .appBarFolderMoreOptionsIcon,
.AppBarFavoriteItemContainer:hover .appBarFolderMoreOptionsIcon {
  color: white !important;
}
.appBarFolderIcon.active {
  background: #d5dee8;
}
.appBarFolderIcon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: white;
  background: #ff9f8c;
}

.ar-header-horizontal-favorite-button {
  height: 100%;
  margin-right: 2px;
}

.ar-header-horizontal-favorite-button > div {
  height: 80%;
  border-radius: 5px;
  outline: none !important;
  color: white !important;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 500;
}

.ar-header-horizontal-favorite-button:hover > div {
  background: #232357;
}
.ar-header-horizontal-favorite-button.active > div {
  background: #232357;
}

.appBarActiveClass {
  background: var(--purple50) !important;
  color: white !important;
}

.appBarFavoriteAddItemButton {
  opacity: 0.8;
}
.appBarContainerHeader:hover .appBarSpaceSelectorChev {
  opacity: 1;
}
.appBarSpaceSelectorChev {
  opacity: 0;
  transition-duration: 0.3s;
}

.DisableExtraButtonOnNotHover:not(:hover) .ar-favorite-adder-button-new {
  display: none !important;
}

.ar-favorite-adder-button-new {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.ar-favorite-adder-button-new:hover {
  background: #343482;
}
.appBarFavoriteAddItemButton:hover {
  opacity: 1 !important;
  background: #232357;
}

.AppBarFavoriteCreatorContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 7px;
  opacity: 1;
  width: 800px;
  height: 253px;
}

.favoriteCreator_singleview {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 5px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.favoriteCreator_singleview.active {
  background: #e7f1fd !important;
}

.favoriteCreator_singleview .favoriteCreator_singleview_icon_container {
  color: white;
  background: #7c98b6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 8px;
}

.favoriteCreator_singleview.active .favoriteCreator_singleview_icon_container {
  background: #5151ca !important;
}

.deleteButton:hover {
  color: red !important;
  background-color: #fff6f7 !important;
}

.favoriteCreatorSingleViewContainer {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}
