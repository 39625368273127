.notification-count {
  top: -1px;
  right: -1px;
  font-size: 11px;
  font-weight: 500;
  background: #ff7d63;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
}

/* .ar-filtered-notf-container{

} */

.unread-count.newDesign {
  left: 26px;
  top: 4px;
  width: 10px;
	z-index: 1;
  height: 10px;
  outline: none;
  background: #ff8066;
}

.ar-double-line-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ar-notf-item {
  cursor: pointer;
}

.ar-notf-item:hover {
  background: rgba(20, 115, 230, 0.1);
}

.ar-notf-item,
.ar-filtered-notf-container {
  border: solid 1px rgba(205, 213, 234);
  border-left: none;
  border-right: none;
}

.ar-filtered-notf-container {
  border-bottom: none;
}

/* .ar-filtered-notf-item {
  border: none;
} */

.ar-notf-item:not(:first-child) {
  border-top: none;
}

/* .ar-notf-item:last-child {
  border-bottom: none;
} */

/* .ar-notf-item-container .ar-notf-item:first-child {
  border-top: none;
} */
/* .ar-notf-item-container .ar-notf-item:last-child {
  border-bottom: none;
} */
