.ar-empty-list-container {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ar-empty-list {
  height: 400px;
  width: 500px;
  padding-top: 155px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ar-empty-list.deals {
  background-image: url("../../Assets/Svg/empty_list_deals.svg");
}
.ar-empty-list.import {
  background-image: url("../../Assets/Svg/empty_list_import.svg");
}
.ar-empty-list.check {
  background-image: url("../../Assets/Svg/empty_list_check.svg");
}
.ar-empty-list.documents {
  background-image: url("../../Assets/Svg/empty_list_documents.svg");
}

.ar-empty-list.inbox {
	background-image: url("../../Assets/Svg/empty_inbox.svg");
}

.ar-empty-list.inbox_list {
	background-image: url("../../Assets/Svg/no_conversations.svg");
}
.ar-empty-list.list_signature {
	background-image: url("../../Assets/Svg/empty_list_signature.svg");
}

.ar-empty-list.calls {
  background-image: url("../../Assets/Svg/empty_list_calls.svg");
}
.ar-empty-list.automation {
  background-image: url("../../Assets/Svg/empty_list_automation.svg");
}
.ar-empty-list.tickets {
  background-image: url("../../Assets/Svg/empty_list_tickets.svg");
}
.ar-empty-list.projects {
  background-image: url("../../Assets/Svg/empty_list_projects.svg");
}
.ar-empty-list.tasks {
  background-image: url("../../Assets/Svg/empty_list_tasks.svg");
}
.ar-empty-list.interventions {
  background-image: url("../../Assets/Svg/empty_list_interventions.svg");
}
.ar-empty-list.billing {
  background-image: url("../../Assets/Svg/empty_list_invoices.svg");
}
.ar-empty-list.clients {
  background-image: url("../../Assets/Svg/empty_list_clients.svg");
}
.ar-empty-list.contacts {
  background-image: url("../../Assets/Svg/empty_list_contacts.svg");
}
.ar-empty-list.contracts {
  background-image: url("../../Assets/Svg/empty_list_contracts.svg");
}
.ar-empty-list.subscriptions {
  background-image: url("../../Assets/Svg/empty_subscriptions_list.svg");
}
.ar-empty-list.error {
  background-image: url("../../Assets/Svg/empty_list_error.svg");
}
.ar-empty-list.socialPosts {
  background-image: url("../../Assets/Svg/empty_list_socialPosts.svg");
}

.ar-empty-list.deleted-item {
  background-image: url("../../Assets/Svg/item_eliminated.svg");
}
