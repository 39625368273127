.keyHeaderBadge {
  border-radius: 30px;
  padding: 0.25rem 0.5rem;
  background: #e5faf4;
  color: #0dcf93;
}

.keyHeaderBadge .text {
  color: #0e6400;
}

.keyHeaderBadge.error {
  background: #ffeeef;
  color: #ff5962;
}

.keyHeaderBadge.error .text {
  color: #831f12;
}

.keyPre {
  user-select: none;
  cursor: pointer;
  position: relative;
  background: white;
  border: solid 1px #d5dee8;
  border-radius: 7px;
  transition: 0.3s;
}
.keyPre pre {
  white-space: revert !important;
}
.keyPre.wrap pre {
  white-space: pre-wrap !important;
}
.keyPre:hover {
  background: #ebf1f7;
  border: solid 1px #d5dee8;
  border-radius: 7px;
}

.keyPre pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.keyPre svg {
  opacity: 0;
  position: absolute;
  top: 11px;
  right: 11px;
  /* width: 30px !important;
  height: 30px; */
  min-width: 0;
  padding: 0;
  transition: 0.3s;
}

.keyPre:hover svg {
  opacity: 1;
  display: block;
}

.keyDesc .keyDescIcon {
  opacity: 0;
  width: 30px;
  height: 30px;
  background: white;
  cursor: pointer;
}

.keyDesc:hover .keyDescIcon {
  opacity: 1;
}

.keyDesc .keyDescIcon:hover {
  background: #ebf1f7;
}
