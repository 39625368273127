.ssi-form-error-alert {
  position: relative;
  background-color: #fff6f6;
  color: #9f3a38;
  border: 1px solid rgba(159, 58, 56, 0.7);
  border-radius: 5px;
}

.ssi-form-error-item {
  margin: 0 0 0 1em;
}

.ssi-form-error-item:before {
  position: absolute;
  content: "•";
  left: 1.5em;
  height: 100%;
  vertical-align: baseline;
}
