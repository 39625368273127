.AddressSelector.SearchButton {
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #1e73f0;
}
.AddressSelector.w-100.flex-1 input {
  border: 0;
}

.AddressSelector.Dropdown {
  margin-top: 4px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 3px;
  opacity: 1;
  max-height: 750px;
  transition: 0.3s;
  /* max-height: 0px; */
  overflow: hidden;
  position: relative;
}

.AddressSelector-suggestion{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DisabledClickEvent {
  pointer-events: none !important;
  cursor: default !important;
}

.AddressContainerDiv:focus-within {
  border-color: #1e73f0;
  position: relative;
}

.AddressSelector.w-100.flex-1 input:focus {
  outline: none !important;
  /* border-color: #ced4da !important; */
}

.AddressSelector.Buttons {
  width: 40px;
  color: #1e73f0;
  height: 40px;
  position: relative;
  cursor: pointer;
  /* overflow: hidden; */
  border-left: 1px solid #d5dee8;
}

.AddressSelector.Buttons.AtiveSelector.d-flex.justify-content-center.align-items-center.px-1:after {
  border: solid transparent;
  content: " ";
  bottom: -6px;
  position: absolute;
  pointer-events: none;
  border-width: 9px;
  border-bottom-color: white;
}

.AddressSelector.Buttons.AtiveSelector.d-flex.justify-content-center.align-items-center.px-1:before {
  border: solid transparent;
  content: " ";
  bottom: -6px;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
  border-bottom-color: #d5dee8;
}

.AddressSelector.Suggestion {
  font-size: 16px !important;
  padding: 12px 15px;
}
