.Cancel-Subscription-Container {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 3px;
  opacity: 1;
  width: 100%;
}

.Subscription-Discount-Container {
  border: 1px solid #0dcf93;
  width: 100%;
  border-radius: 3px;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.card_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 3px;
  opacity: 1;
  width: 50%;
}

.PaymentDetailsTable thead,
.PaymentDetailsTable thead tr,
.PaymentDetailsTable thead tr th {
  background: #ebf1f7 !important;
  padding-bottom: 10px;
  padding-top: 10px;
}

.PaymentDetailsTable tbody,
.PaymentDetailsTable tbody tr,
.PaymentDetailsTable tbody tr td {
  background: white !important;
}

.PaymentDetailUpdateCard {
  width: 430px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0121483d;
  border: 1px solid #e5e8ec;
  border-radius: 4px;
  opacity: 1;
}


.danger-zone-icon {
  width: 53px;
  height: 53px;
  background: #ff5962 0% 0% no-repeat padding-box;
  border-radius: 7px;
	color: white;
  opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.danger-zone-container-top{
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.danger-zone-container-bottom{
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.danger-zone-button{
	background: #FFEEEF;
	font-weight: 500;
}