.ar-toast {
  margin-bottom: 15px;
}

.ar-toast.ar-toast__mr {
  position: fixed;
  right: 20px;
  top: 45%;
  z-index: 999999;
  float: right;
  width: min-content;
}

.ar-toast.ar-toast__ml {
  position: fixed;
  left: 20px;
  top: 45%;
  z-index: 999999;
}

.ar-toast.ar-toast__tl {
  position: fixed;
  left: 20px;
  top: 68px;
  z-index: 999999;
}

.ar-toast.ar-toast__tm {
  text-align: -webkit-center;
  position: fixed;
  z-index: 80000000;
  transform: translateX(-50%);
  top: 68px;
  left: 50%;
}

.ar-toast.ar-toast__tr {
  position: fixed;
  right: 20px;
  top: 68px;
  z-index: 80000000;
  float: right;
  width: min-content;
}

.ar-toast.ar-toast__bl {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 999999;
  margin-bottom: -20px;
}

.ar-toast.ar-toast__bm {
  text-align: -webkit-center;
  position: fixed;
  z-index: 999999;
  transform: translateX(-50%);
  margin-bottom: -20px;
  bottom: 20px;
  left: 50%;
}

.ar-toast.ar-toast__br {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  margin-bottom: -20px;
  float: right;
  width: min-content;
}

.ar-toast .ar-toast__item {
  display: flex;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  user-select: none;
}

/* .ar-toast__item__content > *:first-child {
  margin: 2px 0;
} */

.ar-toast__item {
  position: relative;
}

.ar-toast__item::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ar-toast__item .ar-toast__item__content {
  padding: 12px 15px;
  padding-left: 4px;
  padding-right: 16px;
  display: inline-block;
  /* padding-left: 12px; */
  width: 100%;
}

.ar-toast__item .ar-toast__item__content__icon {
  position: relative;
  /* padding: 8px 8px 8px 12px; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* padding-right: 8px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px; */
  font-size: 16px;
  width: 36px;
  padding-left: 12px;
}

.ar-toast__item .ar-toast__item__content__icon .loader-spinner {
  width: 1rem;
  height: 1rem;
}

.ar-toast__item__content__title {
  font-weight: 450;
  font-size: 14px;
  padding-right: 4px;
}

.ar-toast__item__content__closer {
  font-size: 14px;
  padding-top: 10px;
  padding-right: 13px;
}

.ar-toast__item__content__msg {
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 36px;
  /* padding-left: 4px; */
}

.ar-toast__item.ar-toast__danger {
  float: inherit;
  text-align: left;
  background-color: white;
  color: #831f12;
  border: 1px solid #ff5962;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}
.ar-toast__item.ar-toast__danger:before {
  background-color: #ff5962;
}

.ar-toast__item.ar-toast__info {
  float: inherit;
  text-align: left;
  background-color: #ffffff;
  color: #002b63;
  border: 1px solid #1e73f0 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.ar-toast__item.ar-toast__info:before {
  background-color: #1e73f0;
}

.ar-toast__item.ar-toast__warning {
  float: inherit;
  text-align: left;
  background-color: white;
  color: #7e6514;
  border: 1px solid #f7bf19 !important;
  box-shadow: 0px 3px 6px #00000029;
}
.ar-toast__item.ar-toast__warning:before {
  background-color: #f7bf19;
}

.ar-toast__item.ar-toast__success {
  float: inherit;
  text-align: left;
  background-color: #ffffff;
  color: #0e6400;
  border: 1px solid #36ab80 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.ar-toast__item.ar-toast__success:before {
  background-color: #36ab80;
}

.ar-toast__item.ar-toast__neutral {
  float: inherit;
  text-align: left;
  background-color: white;
  border: 1px solid #7c98b6 !important;
  color: #657b94;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.ar-toast__item.ar-toast__neutral:before {
  background-color: #7c98b6;
}

@media only screen and (max-width: 769px) {
  .ar-toast.ar-toast {
    max-width: 95% !important;
    width: 100% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    text-align: -webkit-center !important;
    float: unset;
  }
}

@media only screen and (max-width: 769px) {
  .ar-toast.ar-toast__mr {
    position: fixed !important;
    z-index: 8000 !important;
    transform: translateX(-50%) !important;
    top: 20px !important;
    left: 50% !important;
    text-align: -webkit-center !important;
    float: unset;
  }
}

@media only screen and (max-width: 769px) {
  .ar-toast.ar-toast__ml {
    position: fixed !important;
    z-index: 8000 !important;
    transform: translateX(-50%) !important;
    top: 20px !important;
    left: 50% !important;
    text-align: -webkit-center !important;
    float: unset;
  }
}

.ar-toast__tl .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__tm .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__tr .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__br .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__bm .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__bl .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__mr .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__ml .ar-toast__item--fadeOut {
  animation: ar-toast--fadeOutDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast .ar-toast__item--flipOutY {
  animation: ar-toast--flipOutY 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--flipOutX {
  animation: ar-toast--flipOutX 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--zoomOut {
  animation: ar-toast--zoomOut 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--rubberBandOut {
  animation: ar-toast--rubberBandOut 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__tl .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__tm .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__tr .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInDown 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast__br .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast__bm .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast__bl .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast__mr .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast__ml .ar-toast__item--fadeIn {
  animation: ar-toast--fadeInUp 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--flipInUpY {
  animation: ar-toast--flipInUpY 0.3s cubic-bezier(0.29, 0.19, 0.45, 0.51)
    forwards;
}

.ar-toast .ar-toast__item--flipInDownY {
  animation: ar-toast--flipInDownY 0.3s cubic-bezier(0.29, 0.19, 0.45, 0.51)
    forwards;
}

.ar-toast .ar-toast__item--flipInUpX {
  animation: ar-toast--flipInUpX 0.3s cubic-bezier(0.29, 0.19, 0.45, 0.51)
    forwards;
}

.ar-toast .ar-toast__item--flipInDownX {
  animation: ar-toast--flipInDownX 0.3s cubic-bezier(0.29, 0.19, 0.45, 0.51)
    forwards;
}

.ar-toast .ar-toast__item--zoomIn {
  animation: ar-toast--zoomIn 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--eyeCatcher {
  animation: ar-toast--eyeCatcher 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

.ar-toast .ar-toast__item--flash {
  animation: ar-toast--flash 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9) forwards;
}

.ar-toast .ar-toast__item--rubberBandIn {
  animation: ar-toast--rubberBandIn 0.3s cubic-bezier(0.29, 0.19, 0.5, 0.9)
    forwards;
}

@keyframes ar-toast--fadeOutDown {
  0% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    visibility: visible;
  }

  60% {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes ar-toast--fadeOutUp {
  0% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    visibility: visible;
  }

  60% {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes ar-toast--flipOutY {
  0% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }

  30% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateY(0deg);
  }

  70% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 0;
    transform: perspective(400px) rotateY(90deg);
  }

  to {
    opacity: 0;
  }
}

@keyframes ar-toast--flipOutX {
  0% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }

  30% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateX(-20deg);
  }

  70% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }

  to {
    opacity: 0;
  }
}

@keyframes ar-toast--zoomOut {
  0% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: scale(1);
  }

  60% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 0;
    transform: scale(0.1);
  }
}

@keyframes ar-toast--rubberBandOut {
  0% {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scaleX(1);
  }
  30% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scale3d(1.3, 0.75, 1);
  }

  75% {
    opacity: 0;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scale3d(0.9, 0.5, 0.5);
  }
  to {
    opacity: 0;
  }
}

.ar-toast__tm .noAnimation {
  margin-top: 5px;
  margin-bottom: 15px;
}

@keyframes ar-toast--fadeInDown {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    visibility: visible;
    transform: translateZ(0);
  }
}

@keyframes ar-toast--fadeInUp {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    visibility: visible;
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes ar-toast--flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px) rotateY(90deg);
  }

  40% {
    transform: perspective(400px) rotateY(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px) rotateY(10deg);
  }

  80% {
    opacity: 1;
    transform: perspective(400px) rotateY(-5deg);
  }

  to {
    opacity: 1;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--flipInUpY {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    transform: translateZ(0);
  }

  15% {
    opacity: 0;
    transform: perspective(400px) rotateY(90deg);
  }

  45% {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  60% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateY(10deg);
  }

  80% {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px) rotateY(0deg);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--flipInDownY {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    transform: translateZ(0);
  }

  15% {
    opacity: 0;
    transform: perspective(400px) rotateY(90deg);
  }

  45% {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  60% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateY(10deg);
  }

  80% {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px) rotateY(0deg);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }

  40% {
    transform: perspective(400px) rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px) rotateX(10deg);
  }

  80% {
    opacity: 1;
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--flipInDownX {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    transform: translateZ(0);
  }

  15% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }

  45% {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  60% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateX(10deg);
  }

  80% {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px) rotateX(0deg);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--flipInUpX {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    transform: translateZ(0);
  }

  15% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }

  45% {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  60% {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: perspective(400px) rotateX(10deg);
  }

  80% {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px) rotateX(0deg);
  }

  to {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 15px;
    transform: perspective(400px);
  }
}

@keyframes ar-toast--zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  40% {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ar-toast--eyeCatcher {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

@keyframes ar-toast--flash {
  0%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
  to {
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 1;
  }
}

@keyframes ar-toast--rubberBandIn {
  0% {
    transform: scaleX(1);
  }
  30% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scale3d(1.25, 0.75, 1);
  }

  50% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scale3d(1.15, 0.85, 1);
  }

  75% {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    margin-top: 5px;
    margin-bottom: 15px;
    transform: scaleX(1);
  }
}
