.img {
  width: 48px;
  height: 48px;
  /* flex: 0 0 48px; */
  border: solid 2px #d5dee8;
  user-select: none;
}

img.img {
  display: block;
}
.logo {
  width: 379px;
  height: 94px;
}
.img.lg {
  width: 64px;
  height: 64px;
  /* flex: 0 0 64px; */
}
.img.sm {
  width: 24px;
  height: 24px;
  /* flex: 0 0 32px; */
}

.img.xsm {
  width: 18px;
  height: 18px;
  /* flex: 0 0 24px; */
}

.img.mds {
  width: 32px;
  height: 32px;
}

.img.md {
  width: 40px;
  height: 40px;
}
.img.mdx {
  width: 48px;
  height: 48px;
}

.img.country.lg {
  width: 64px;
  height: 32px;
  /* flex: 0 0 64px; */
}
.img.country.sm {
  width: 32px;
  height: 24px;
  /* flex: 0 0 32px; */
}

.img.country.xsm {
  width: 24px;
  height: 18px;
  /* flex: 0 0 24px; */
}

.img.circular {
  border-radius: 50%;
}

.img.country {
	overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border: none;
}

.img.account {
  font-weight: 600;
  background: white;
  color: #1e73f0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 0.5rem; */
}
.img.account span {
  text-decoration: none !important;
	text-transform: capitalize;
}

.ar-vertical-header .img.account,
.ar-vertical-header .img.country {
  margin: 0;
  margin-bottom: 5px;
}
.img.account span:first-child {
  letter-spacing: 1px;
}
.img.account.sm,
.img.country.sm {
  font-size: 12px;
}
.img.account.xsm,
.img.country.xsm {
  font-size: 10px;
}
.img.account.lg,
.img.country.lg {
  font-size: 26px;
}
