.ar-line-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ar-line-header:before,
.ar-line-header:after {
  content: "";
  border-top: 2px solid;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.ar-line-header:after {
  margin: 0 0 0 20px;
}
