.ar-dropdown-base {
  position: fixed;
  top: 0;
  z-index: 10000002;
  margin-left: -1px;
}

.ar-dropdown-container {
  display: inline-block;
  height: auto;
  width: 100%;
  position: relative;
  /* border: 1px solid #dee6ed; */
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: 0 0 0 rgba(1, 28, 61, 0.3);
  border-color: #ced4da;
}

.ar-dropdown-list-button .no-entity-button {
  padding: 0 !important;
  background: none !important;
}

.ar-dropdown-container:focus {
  /* border-color: #ced4da; */
}

.ar-dropdown-container.ar-disabled {
  /* opacity: 0.6; */
  background-color: #eaf0f6 !important;

  border-color: transparent !important;
}
.ar-dropdown-container.ar-disabled * {
  cursor: default !important;
  color: #7c98b6 !important;
}

.ar-dropdown-container.selected {
  /* box-shadow: 0 0 8px rgba(1, 28, 61, 0.16);
  border-color: #ced4da !important; */
}

.ar-dropdown-container.selected,
.dropdown-popup {
  /* border-color: #ced4da !important; */
}

.ar-dropdown-container:not(.up).selected {
  /* border-radius: 5px 5px 0 0;
  border-bottom-color: transparent !important; */
  border-color: #1e73f0;
  border-radius: 5px;
}

.ar-dropdown-container.up.selected {
  border-radius: 0 0 5px 5px;
  /* border-bottom-color: #ced4da  !important; */
  /* border-top-color: transparent !important; */
}

.ar-dropdown-container.auto {
  width: auto;
}

.ar-dropdown-container.auto svg {
  margin-left: 0.75rem;
}

.dropdown-popup {
  /* overflow: visible; */
  margin-top: 4px !important;
  margin-bottom: -10px !important;
  background: #fff;
  transition: transform 0.2s ease !important;
  transform-origin: top center;
  box-shadow: 0 0 0 rgba(1, 28, 61, 0.3);
  transform: scaleY(0);
  /* border-bottom: none !important;
  border-top: none !important; */
  min-height: 20px;
  border-radius: 5px;
}

.ar-dropdown-base.up .dropdown-popup {
  margin-top: -5px !important;
  transform-origin: bottom center !important;
  border-radius: 5px 5px 0 0;
}

.dropdown-popup-appear-active,
.dropdown-popup-enter-done {
  transform: scaleY(1);
  box-shadow: 0 4px 8px rgba(1, 28, 61, 0.16);
}

.ar-dropdown-base.up .dropdown-popup-appear-active,
.ar-dropdown-base.up .dropdown-popup-enter-done {
  box-shadow: 0 -4px 8px rgba(1, 28, 61, 0.16);
  transform: scaleY(1);
}

/* .dropdown-popup-exit-active,
.dropdown-popup-exit-done {
  transform: scaleY(0);
  opacity: 0;
} */

.ar-alt-dropdown-container > label,
.ar-dropdown-container > label {
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 2px 4px;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  cursor: pointer;
  /* background: #ffffff; */
  border-radius: 0.25rem;
}

.ar-dropdown-container.sm > label {
  min-height: 30px;
}

.ar-dropdown-container > label.multiple {
  padding-top: 4px;
  padding-bottom: 0;
}

.ar-dropdown-container > label.multiple .ar-dropdown-button-value {
  margin-top: 0px;
  margin-bottom: 4px;
}

.ar-alt-dropdown-container > label.ar-dropdown-label-search,
.ar-dropdown-container > label.ar-dropdown-label-search {
  cursor: text;
}

.large-dropdown {
  height: 50px;
}
.large-dropdown > label {
  min-height: 50px;
}
.ar-dropdown-list-button {
  color: #002b63;
  font-size: 15px;
  display: block;
  border-radius: 5px;
  border: 0;
  background: transparent;
  padding: 8px 10px;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.ar-dropdown-list-button:hover,
.ar-dropdown-list-button-hint {
  background-color: #1473e610;
}
.ar-dropdown-menu {
  overflow: visible;
  /* padding: 8px 8px; */
  user-select: none;
}

/* .ar-dropdown-menu:after {


	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #ced4da;
	left: 17%;
	top: -5px;
	transform: translateX(-50%);
} */
.ar-dropdown-button::placeholder {
  color: #7c98b6 !important;
}
.ar-dropdown-button {
  width: 100%;
  height: 100%;
  min-height: 30px;
  border: 0;
  background: transparent;
  outline: 0;
  /* line-height: 30px; */
  /* padding: 0 10px; */
  /* pointer-events: none; */
}

.ar-dropdow-container {
  /* padding: 8px 0 0 0; */
  /* padding-bottom: 8px; */
}

.ar-dropdow-container > div:first-child {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 8px 10px;
}

.ar-dropdow-container.loading {
  opacity: 0.5;
}

.ar-dropdown-list-column {
  padding: 3px 10px;
  margin-bottom: 8px;
  /* color: #1e73f0; */
  background: #f6faff;
  color: #002b63;
  font-size: 16px;
}

.ar-dropdown-button-value > div {
  vertical-align: middle;
  display: inline-block;
}

/* .input-preview-mode-container .ar-dropdown-button-value {
  background-color: #cbd6e2 !important;
} */

.ar-dropdown-button-value {
  display: inline-block;
  /* padding: 4px 0; */
  vertical-align: middle;
  margin-right: 4px;
  background-color: #e5eaf0;
  padding: 4px 8px;
  /* border: 1px solid #d5dee8; */
  border-radius: 4px;
}
.ar-dropdown-button-value.preview {
  /* background: none;
  border: none;
  padding: 3px 0; */
}

.ar-dropdown-button-value-remove {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  height: 15px;
  font-size: 15px;
  color: #e10707;
  cursor: pointer;
}

.ar-dropdown-button-multiple {
  display: inline-block;
  width: 100px !important;
  height: auto;
  padding: 7px 0;
}

.ar-dropdown-nodata {
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  color: #7c98b6;
  text-transform: uppercase;
}
