.dropdown-container.alt {
  border-radius: 30px !important;
  box-shadow: none;
}
.dropdown-container.alt .dropdown-popup {
  border-radius: 15px;
  border: 1px solid #cdd5ea !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-container.alt.success {
  background: #AFDDCC !important;
  border-color: #2B8966 !important;
  color: white !important;
}
.dropdown-container.alt.orange {
  background: #ffa166 !important;
  border-color: #ffa166 !important;
  color: white !important;
}
.dropdown-container.alt.purple {
  background: #908cff !important;
  border-color: #908cff !important;
  color: white !important;
}
.dropdown-container.alt.grey {
  background: #7c98b6 !important;
  border-color: #7c98b6 !important;
  color: white !important;
}

.dropdown-container.alt > label {
  background: unset;
}
