.ar-text-Pending-domains {
  color: #ff8066 !important;
}

.ar-text-Success-domains {
  color: #0e6400 !important;
}

.text-danger-alt {
  color: #ff5962 !important;
}

.table-header-background {
  background-color: #ebf1f7 !important;
}
