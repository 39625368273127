.ar-time-tracking-button-container {
  transition: 0.3s;
}
.ar-time-tracking-button-container.active {
  margin-right: 13px;
}

.ar-time-tracking-button {
  position: relative;
  min-width: 0;
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
}

.ar-time-tracking-button-container .counter span {
  margin-right: 2px;
}

.ar-vertical-header .ar-time-tracking-button > svg {
  height: inherit !important;
}
.ar-time-tracking-button > svg {
  width: 100% !important;
  height: 100% !important;
}

.counter {
  left: 29px;
  top: 1px;
  font-size: 10px;
  font-weight: 500;
  background: #5656d6;
  border-radius: 50%;
  color: white;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  pointer-events: none;
  outline: solid 1px white;
  border: none;
}

.ar-time-tracker-item {
  transition: 0.3s;
  border-bottom: 1px solid #dee2e6;
}

.ar-time-tracker-item:last-child {
  border-bottom: none;
}

.ar-time-tracker-item.active {
  background: #e7f1fd;
}

.ar-time-tracker-table.tracker-header {
  /* background: white; */
  /* z-index: 438758; */
  /* position: sticky; */
  /* top: 0; */
  box-shadow: 0 3px 10px #0000001a;
}

/* .ar-time-tracker-table > div {
  width: 100%;
} */

.ar-time-tracker-title {
  background: #f6faff;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #dee2e6;
  z-index: 1;
}

/* .ar-tracker-button {
  height: 30px;
  min-width: 0px;
  width: 30px;
  border: none;
  padding: 0;
  border: 1px solid #007bff;
  border-radius: 50%;
  padding: 6px;
} */

.ar-tracker-button svg {
  width: 100% !important;
  height: 100% !important;
}
