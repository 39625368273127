.ar-profilepicture {
  position: relative;
  display: block;
}
.ar-profilepicture label {
  cursor: pointer;
}
.ar-profilepicture .ar-picture {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.ar-logopicture {
  position: relative;
  display: block;
  height: 94px;
}

.ar-logopicture label {
  cursor: pointer;
}

.rotate-45 {
  transform: rotate(45deg);
}

.ar-logos-close_circular_corner {
  color: #ff5962;
  border: 1px solid #d5dee8;
  width: 26px;
  height: 26px;
  top: -10px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: white;
}

.ar-logopicture .ar-picture {
  position: relative;
  display: block;
  width: 379px;
  height: 94px;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
}

.ar-organizationPicture {
  width: 94px;
  height: 94px;
  pointer-events: none;
  background: #ecf7fd;
  border: 1px dashed #1e73f0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar-organizationLogo {
  width: 362px;
  pointer-events: none;
  height: 94px;
  background: #ecf7fd;
  border-radius: 3px;
  border: 1px dashed #1e73f0;
  display: flex;
  justify-content: center;
  background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  align-items: center;
}
