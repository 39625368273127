.ar-spent-hours-preview {
  color: #36ab80;
  border: solid 1px #36ab80;
  border-radius: 30px;
}

.ar-spent-hours-preview.negative {
  color: #ff5962;
  border: solid 1px #ff5962;
}
.ar-spent-hours-preview.equal {
  color: #7c98b6;
  border: solid 1px #7c98b6;
}
.ar-spent-hours-preview.isDone {
  color: #232357;
  border: solid 1px #232357;
}

.ar-spent-hours-preview.negative svg {
  transform: rotate(180deg);
}
