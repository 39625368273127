.ar-server-aware-input {
  max-width: 300px;
  background: white;
}

.ar-server-aware-input-icon {
  width: 20px;
}

.ar-virtual-grid tbody tr:hover > td .ssi-button-primary-ghost,
.ar-virtual-grid tbody tr:hover > td .ssi-button-danger-ghost-alt,
.ar-virtual-grid tbody tr:hover > td .ar-breadcrumbs-icon,
.CGrid .c-grid-body tbody tr:hover > td .ssi-button-primary-ghost,
.CGrid .c-grid-body tbody tr:hover > td .ssi-button-danger-ghost-alt,
.CGrid .c-grid-body tbody tr:hover > td .ar-breadcrumbs-icon {
  visibility: visible;
  transition: visibility 0s linear;
}

.ar-virtual-grid .ssi-button-primary-ghost,
.ar-virtual-grid .ssi-button-danger-ghost-alt,
.ar-virtual-grid .ar-breadcrumbs-icon {
  visibility: hidden;
  transition: visibility 0s ease-out;
}

.ar-virtual-grid .ar-breadcrumbs-icon {
  margin-left: 6px;
}
