.IconContainer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: white;
  cursor: pointer;
}

.IconPickerPopup {
  width: 336px;
  height: 330px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
  border: 1px solid #d5dee8;
  border-radius: 5px;
  opacity: 1;
  padding-top: 5px;
}

.IconHover:hover {
  background: #e8e8ea;
}
.IconHover {
  transition: 0.3s linear all;

  /* padding-left: 0.35rem; */
  /* padding-right: 0.35rem; */
  padding: 0.1rem;
  width: 30px;
}
