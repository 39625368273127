.ar-animated-accordion .title {
  transition: 0.3s;
  cursor: pointer;
}

/* .ar-animated-accordion .title:hover {
    background: #f6faff;
  } */

.ar-animated-accordion-icon {
  color: #7c98b6;
  width: 0.75rem;
  height: 1rem;
  margin-right: 0.5rem;
  transition: 0.3s;
  transform: rotate(0deg);
}

.ar-animated-accordion.active > .title > .ar-animated-accordion-icon {
  
  transform: rotate(90deg);
}

.ar-animated-accordion-icon svg {
  display: block;
  width: 100% !important;
  height: 100%;
}

.ar-animated-accordion-content {
  line-height: 1.5;
  opacity: 0;
  /* margin-left: 1.25rem; */
  overflow: hidden;
  transition: 0.3s;
  will-change: height, opacity;
}

/* .ar-animated-accordion.active .title {
  margin-bottom: 0.25rem;
} */

.ar-animated-accordion-content.active {
  opacity: 1;
}
