.loader-spinner {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  stroke: currentColor;
  /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; */
}

.loader-spinner.lg {
  width: 128px;
  height: 128px;
}

.loader-spinner.sm {
  width: 64px;
  height: 64px;
}

.loader-spinner.xs {
  width: 32px;
  height: 32px;
}

.loader-spinner.xxs {
  width: 20px;
  height: 20px;
}

.loader-spinner.xxxs {
  width: 14px;
  height: 14px;
}

.loader-spinner .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  /* animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite; */
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.main-loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-loader {
  height: 300px;
  width: 300px;
}
