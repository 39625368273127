.ar-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #11112b;
  position: fixed;
  width: 100%;
  height: 64px;
  padding: 0 8px;
  z-index: 999999;
  line-height: normal;
}

.ar-header.ego {
  background-color: #263238;
}

.ar-header-main-section {
  height: 100%;
  margin-bottom: 0;
  white-space: nowrap;
  display: inline-flex;
  padding: 0;
  /* overflow: hidden; */
}

.header-ignore-welcome {
  transition: 0.3s;
  padding: 3px 10px;
  border-radius: 3px;
}

.header-ignore-welcome:hover {
  background: #37248f !important;
  color: white !important;
}
.header-welcome-bar {
  background: #e7f1fd 0% 0% no-repeat padding-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* top: 64px; */
  /* height: 40px; */
	padding: 9px;
  width: 100%;
  /* z-index: 99999; */
}

.ar-header-main-section li {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-right: 2px;
}

.ar-header-link {
  height: 80%;
  border-radius: 5px;
  outline: none !important;
  color: white !important;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 500;
}
.ar-header-link .ar-header-group-arrow {
  margin-left: 8px;
}

.ar-header-main-section > li:hover > .ar-header-link,
.ar-header-main-section > li.selected > .ar-header-link {
  background: #232357;
}

.ar-header-main-section > li:hover > .ar-header-link .ar-header-group-arrow,
.ar-header-main-section > li.selected > .ar-header-link .ar-header-group-arrow {
  color: var(--purple50);
}

.ar-header-group-popup {
  /* display: none; */

  /* position: absolute; */
  /* top: 85%; */
  /* left: 0; */
  background: #232357;
  padding: 10px 8px;
  width: 190px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ar-header-group-popup .ar-header-link {
  width: 100%;
  height: 100%;
}

.ar-header-group-popup > ul {
  padding: 0;
  width: 100%;
  list-style-type: none;
  white-space: normal;
	margin-bottom: 0;
}

.ar-header-group-popup > ul > li {
  width: 100%;
  height: 40px;
  margin-bottom: 2px;
  list-style-type: none;
}

.ar-header-main-section li.active .ar-header-group-popup {
  display: block;
}

.ar-header-group-popup li:hover .ar-header-link {
  background: #343482;
  height: 100%;
}

.ar-header-group-popup .ar-header-link.selected {
  background: var(--purple50);
  height: 100%;
}

.ar-header-group-popup hr {
  border-width: 2px;
  border-color: #061d4b;
  margin: 0.75rem 10px;
}

.ar-header-rounded-icon:hover {
  color: white !important;
}

.ar-header-rounded-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: 0.3s;
  min-width: 32px;
  color: #ddddf7;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  border-radius: 5px;
}

.app-sidebar-icons .ar-header-rounded-icon {
  border-radius: 5px !important;
}

.ar-header-rounded-icon.alternative-color.selected {
  background: var(--purple50) !important;
}
.ar-header-main-section .ar-header-rounded-icon.alternative-color.selected {
  background: #232357 !important;
}

.ar-header-rounded-icon:hover {
  background: #232357;
}
.ar-header-rounded-icon.selected {
  background: var(--purple50);
}

.ar-header-dashboard-icon {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.ar-header-dashboard-icon svg {
  width: 25px;
}

.ar-header-link-icon {
  width: 18px;
  margin-right: 14px;
  height: 16px;
}

.ar-header-link-icon-grid {
  width: 18px;
  height: 16px;
}

.ar-header-link-icon svg,
.ar-header-link-icon-grid svg {
  width: 100% !important;
  height: 100%;
}

.ar-header-entities-count {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e73f0;
  border-radius: 50%;
  font-size: 12px;
}
