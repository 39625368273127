.popup,
.sub-popup {
  box-shadow: 0 0 8px rgba(1, 28, 61, 0.16);
	/* box-shadow: 0 3px 10px rgba(1, 28, 61, 0.15);
	 */
	 /* box-shadow: 0 0 124px 5px rgba(0,0,0,.24); */
  border-radius: 3px;
}

.popup {
	border-radius: 3px;
  margin: 0px 0px;
  /* position: absolute; */
  /* left: 14px;
    top: 400px; */
  /* transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.175); */
  /* transition-duration: 300ms !important; */
  /* transition-property: opacity, -webkit-transform, transform !important; */
  /* transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
    transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1),
    -webkit-transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
    transform-origin: top; */
    transition: .2s;
}

.popup-appear {
  /* transform: scale(0.9); */
  /* transform: scale(0.85); */
  opacity: 0;
}
.popup-appear-active,
.popup-enter-done {
  opacity: 1;
  /* transition-property: opacity, -webkit-transform, transform !important;
  transform-origin: top; */
  /* transform: scale(1); */
}
.popup-exit-active,
.popup-exit-done {
  /* transition-duration: 0.5s; */
  /* transform: scale(0.95); */
  opacity: 0;
}

.sub-popup {
  margin: 0 -4px;
}

.sub-popup-popper {
  position: relative;
}
