.weekView {
  background: #fcfdfe;
  border: solid 1px #d5dee8;
}

.headerContainer {
  border-bottom: solid 1px #d5dee8;
  padding-left: 52px;
}

.headerDay {
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-right: solid 1px #d5dee8;
}
.headerDay:last-child {
  border-right: none;
}

.fakeScroll {
  overflow: scroll;
  visibility: hidden;
}

.gridContainer {
  overflow-y: scroll;
}

.hourList {
  width: 52px;
}

.hourItem {
  height: 60px;
  background: #d5dee840;
  color: rgba(0, 43, 99, 0.5);
  border-bottom: solid 1px #d5dee8;
}
.hourItem:last-child {
  border-bottom: none;
}

.dayHourItem {
  height: 60px;
  border-bottom: solid 1px #d5dee8;
}
.dayHourItem:last-child {
  border-bottom: none;
}

.dayHourColumn {
  border-right: solid 1px #d5dee8;
  position: relative;
}

.dayHourColumn:last-child {
  border-right: none;
}

.dayHourColumn.selected {
  background: rgba(30, 114, 240, 0.1);
}

.columnItem {
  position: absolute;
  z-index: 1;
}
.columnItem > div {
  padding-left: 0.5rem !important;
  background: #f9fbff;
  border: solid 1px #d5dee8;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.columnItem > div:hover {
  background: #e1ecfd;
}
