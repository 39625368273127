/* .automationLayout{
    width: 100;
} */

.block {
  background: white;
  box-shadow: 0 0 124px 5px rgb(0 0 0 / 24%);
}


.ExpressionModalContainer{
	max-width: 1020px;
	width: 90vw;
	height: 80vh;
	border-radius: 7px;
	overflow: hidden;
	background: white;
}

.ExpressionModalContainerLeft{
	z-index: 2;
	box-shadow: 0px 3px 10px #0000001A;
	background: #F9FBFF 0% 0% no-repeat padding-box;
}
.ExpressionModalContainerRight{
	z-index: 1;
	background: white;
}
.ExpressionModalContainerBottom{
	z-index: 3;
	box-shadow: 0px -3px 10px #E3E7F19E;
	background: white;
	padding: 20px 30px;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -ms-user-select: none; /*Internet Explorer */
  user-select: none;
}

.ExpressionModalContainerRightContainer{
	outline: none;
	border-radius: 3px;
	resize: none;
}