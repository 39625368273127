.plan-dropdown-text-PLAN_STARTER {
  color: #00c7e5;
  font-weight: 500;
}
.plan-dropdown-text-PLAN_BASIC {
  color: var(--purple50);
  font-weight: 500;
}
.plan-dropdown-text-PLAN_PRO {
  color: #4b8ff3;
  font-weight: 500;
}
.plan-dropdown-text-PLAN_COMPANY {
  color: #0dcf93;
  font-weight: 500;
}
.plan-dropdown-text-NONE {
  font-weight: 500;
}

.disable-last-component-margin-right:last-child {
  margin-right: 0 !important;
}

.cupon-grid-none-component {
  border-radius: 3px;
  font-weight: 500;
}

.cupon-grid-starter-component {
  padding: 0.5rem !important;
  /* border: 1px solid #00C7E5; */
  color: #00c7e5;
  background: #e6fcff;
  border-radius: 3px;
  font-weight: 500;
}

.cupon-grid-basic-component {
  padding: 0.5rem !important;
  /* border: 1px solid var(--purple50); */
  color: var(--purple50);
  background: #ccccf4;
  border-radius: 3px;
  font-weight: 500;
}

.cupon-grid-pro-component {
  padding: 0.5rem !important;
  /* border: 1px solid #4B8FF3; */
  color: #4b8ff3;
  background: #e7f1fd;
  border-radius: 3px;
  font-weight: 500;
}

.cupon-grid-company-component {
  padding: 0.5rem !important;
  /* border: 1px solid #36AB80; */
  color: #36ab80;
  background: #e5faf4;
  border-radius: 3px;
  font-weight: 500;
}
