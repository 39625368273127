.InformationComponent {
  color: #7c98b6;
  cursor: help;
}

.InformationTooltip {
  cursor: context-menu;
  max-width: 400px;
  white-space: normal;
	background: #232357;
	color: white;
}

