.ar-omnibutton {
  color: #ffffff !important;
  fill: #ffffff !important;
}

@media only screen and (max-width: 700px) {
  .OmniButtonModalContainer {
    width: 400px !important;
  }
}
@media only screen and (max-height: 660px) {
  .OmniButtonModalContainer {
    height: 80vh;
  }
}

.OmniButtonModalContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 1px solid #d5dee8; */
  border-radius: 7px;
  overflow: auto;
  opacity: 1;
  width: 560px;
}

.AppBarFavoriteCreatorContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 7px;
  opacity: 1;
  width: 560px;
  height: 253px;
}

.favoriteCreator_singleview {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  border-radius: 5px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  transition: 300ms all cubic-bezier(0.56, -0.07, 0, 0.96);
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.favoriteCreator_singleview:hover {
  background: #e7f1fd !important;
}

.favoriteCreator_singleview .favoriteCreator_singleview_icon_container {
  color: white;
  background: #7c98b6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 8px;
  transition: 300ms all cubic-bezier(0.56, -0.07, 0, 0.96);
}

.favoriteCreator_singleview:hover .favoriteCreator_singleview_icon_container {
  background: #1e73f0 !important;
}

.deleteButton:hover {
  color: red !important;
  background-color: #fff6f7 !important;
}

.favoriteCreatorSingleViewContainer {
  display: grid;
  grid-gap: 8px;
  margin-bottom: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.label-title {
  color: #7c98b6;
  margin-left: 10px;
  font-size: 14px;
  margin-top: 10px;
}
