.a-radio-button-container {
  margin: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.a-radio-button-container input {
    display: none;
}
.a-radio-button-container input:checked ~ .a-radio-button .a-radio-button-checked {
    display: block;
}
.a-radio-button-container input:checked ~ .a-radio-button {
    /* box-shadow: 0px 0px 5px -1px #15B6D0; */
    box-shadow: 0px 0px 5px -1px #1E73F0;
    border-color: #1E73F0;
}
.a-radio-button-container .a-radio-button-checked {
    display: none;
    /* background-color: #15b6d0; */
    background-color: #1E73F0;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.a-radio-button-text {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
.a-radio-button-container .a-radio-button {
    width: 21px;
    height: 21px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  /* border: 1px solid #DEE6ED; */
  border: 1px solid #bbbbbb;
  background-color: #fbfbfb;
  border-radius: 50%;
}
