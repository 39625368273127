.unread-count {
  top: -1px;
  right: -1px;
  font-size: 10px;
  font-weight: 500;
  background: "#FF8066";
  border-radius: 50%;
  color: white;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
	user-select: none;
	pointer-events: none;
  outline: none;
  border: none;
}

.personal-export-svg {
  --fa-primary-color: currentColor;
  --fa-secondary-color: white;
}

.unread-count.many {
  width: auto;
  padding: 0 4px;
  border-radius: 10px;
  right: -6px;
}
