.airWelcome_banner {
  -webkit-user-select: none;
  width: 100%;
  display: flex;
  position: relative;
  height: 600px;
  background: #061d4b;
  align-items: flex-end;
  overflow: hidden;
  margin-bottom: 80px;
  -webkit-user-select: none;
}

.airWelcome_Cards_Container {
  -webkit-user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 0px 300px 105px 300px; */
  justify-content: center;
}

.airWelcome_Cards_Container > div {
  margin-bottom: 71px;
}

.rowWidth {
  width: 1144px;
  margin: auto;
}

.airWelcome_Cards_Container .cardTitle {
  font-size: 26px;
  color: #002b63;
  font-weight: 500;
}

.airWelcome_Cards_Container .card {
  width: 564px;
  height: 290px;
  overflow: hidden;
  border-radius: 13px;
  border-color: transparent;
}

.airWelcome_Cards_Container .card *:hover {
  text-decoration: none;
}

.airWelcome_Cards_Container .card.fullWidth {
  width: 100%;
}

.airWelcome_Cards_Container .card .content {
  width: 100%;
  height: 193px;
  margin-bottom: 1px;
}

.airWelcome_Cards_Container .card .content.clients {
  background: #dbdbf5;
}

.airWelcome_Cards_Container .card .content.services {
  background: transparent
    linear-gradient(80deg, var(--purple50) 0%, #4949b6 60%, #3d3d99 100%) 0% 0%
    no-repeat padding-box;
}

.airWelcome_footer {
  color: #7c98b6;
  size: 14px;
}

.airWelcome_allReady {
  display: flex;
  background: #e7f1fd;
  font-size: 24px;
  color: #1e73f0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 80px;
}

.airWelcome_allReady:hover {
  color: #1e73f0 !important;
  text-decoration: none;
}

.airWelcome_footer a {
  color: #7c98b6 !important;
}

.airWelcome_Cards_Container .card .content.contacts {
  background: #ff947f;
}

.airWelcome_Cards_Container .card .content.deals {
  background: #0f4faa;
}

.airWelcome_Cards_Container .card .content.contracts {
  background: #29b686;
}

.airWelcome_Cards_Container .card .subtitle {
  height: 97px;
  width: 100%;
  padding: 20px;
  color: white;
}

.airWelcome_Cards_Container .card .content.services .services-links-container {
  padding: 30px 30px 30px 70px;
  line-height: 75px;
}

.airWelcome_Cards_Container .card .WelcomeButtonContainer {
  width: 220px;
  height: 50px;
  color: white;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
.airWelcome_Cards_Container .card .WelcomeButtonContainer .WelcomeButtonIcon {
  height: 50px;
  width: 50px;
  display: flex;
  font-size: 25px;
  justify-content: center;
  background: white;
  color: #5050c9;
  align-items: center;
}

.airWelcome_Cards_Container .card .subtitle.clients {
  background: #a1a1e6;
}
.airWelcome_Cards_Container .card .subtitle.contacts {
  background: #ff8066;
}

.airWelcome_Cards_Container .card .subtitle.deals {
  background: #1e73f0;
}
.airWelcome_Cards_Container .card .subtitle.contracts {
  background: #36ab80;
}
.airWelcome_Cards_Container .card .subtitle.services {
  background: #3d3d99;
}

.airWelcome_banner .LeftBackdrop {
  width: 683px;
  height: 335px;
  background: transparent;
}

.airWelcome_banner .CenterBackdrop {
  width: 795px;
  height: 600px;
  background: transparent;
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.airWelcome_banner .RightBackdrop {
  width: 400px;
  height: 340px;
  background: transparent;
}

.airWelcome_banner .WelcomeMsg {
  position: absolute;
  color: white;
  width: 100%;
  padding: 0px 300px 105px 300px;
  top: 0px;
  align-items: center;
  height: 600px;
}

.airWelcome_banner .WelcomeMsg .HelloMsg {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 50px;
  display: inline-block;
  white-space: break-spaces;
}

.airWelcome_banner .WelcomeMsg .ConfigurationMsg {
  font-size: 33px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 83px;
  width: 363px;
}

.airWelcome_banner .WelcomeMsg .Divider {
  width: 1px;
  height: 50px;
  border-left: 1px solid white;
}

.airWelcome_banner .WelcomeMsg .CircleLinkLeft {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 1px solid white;
  color: #061d4b;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}

.airWelcome_banner .WelcomeMsg .CircleLinkLeft:hover {
  background: #b5bcc9;
}

.airWelcome_banner .WelcomeMsg .CircleLinkRight {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: #061d4b;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.86, 0, 0.07, 1);
}

.airWelcome_banner .WelcomeMsg .CircleLinkRight:hover {
  background: #05173c;
}
