.ar-dashboard-container {
  /* background-image: url("../../Assets/Svg/tilted_airdesk_logo.svg");
  background-color: white; */
  background-repeat: no-repeat;
  background-size: 115%;
  background: #f9fbff;
  background-position: center;
  /* overflow-x: hidden; */
  padding: 0 20px;
  overflow-y: auto;
  height: 100%;
}

.dashboardHeader {
  height: 91px;
  box-shadow: 0px 3px 10px #e3e7f19e;
  border-radius: 0px 7px 0px 0px;
}

.ar-dashboard-empty-icon {
  width: 70px;
  max-height: 70px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ar-dashboard-card-settings-link {
  display: none;
}

.ar-dashboard-layout .react-grid-item:hover .ar-dashboard-card-settings-link {
  display: block;
  height: auto;
}

.ar-dashboard-empty-icon.deals {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_empty_deals.svg");
}

.ar-dashboard-empty-icon.birthday {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_birthday_empty.svg");
}

.ar-dashboard-empty-icon.calls {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_call_empty.svg");
}
.ar-dashboard-empty-icon.interventions {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_time_empty.svg");
}
.ar-dashboard-empty-icon.timetracking {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_timetracking_empty.svg");
}
.ar-dashboard-empty-icon.projects {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_project_empty.svg");
}
.ar-dashboard-empty-icon.contracts {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_task_empty.svg");
}
.ar-dashboard-empty-icon.tasks {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_task_empty.svg");
}
.ar-dashboard-empty-icon.tickets {
  background-image: url("../../Assets/Svg/Dashboard/dashboard_ticket_empty.svg");
}

.ar-welcome-module-container {
  width: 376px;
  margin: 12px 15px;
  background: white;
}

.ar-welcome-module-container:first-child {
  margin-left: 0;
}

.ar-welcome-module-container:last-child {
  margin-right: 0;
}

.ar-time-charge-table {
  width: 100%;
  table-layout: fixed;
  font-size: 14px;
  cursor: auto;
}

.ar-time-charge-table thead tr {
  background: #ffffff;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.ar-time-charge-table thead tr th:last-child {
  border: none !important;
}

.ar-time-charge-table td {
  color: #7c98b6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

/* width: 40%; */
.ar-time-charge-table td,
.ar-time-charge-table th {
  padding: 10px 5px;
}

.ar-time-charge-table td:first-child,
.ar-time-charge-table th:first-child {
  padding-left: 1.5rem;
}

.ar-time-charge-table td:last-child,
.ar-time-charge-table th:last-child {
  padding-right: 1.5rem;
}

.ar-settings-table th {
  font-weight: 400;

  padding-top: 0;
}

/* .ar-settings-table tbody {
    box-shadow: 0 0 0 1px #d5dee8;
    border-radius: 3px;
  } */

/* .ar-settings-table tbody tr {
  border-bottom: solid 1px #d5dee8;
} */

.ar-settings-table tbody tr:last-child {
  border-bottom: none;
}

.ar-missing-time-charges {
  max-width: 1280px;
}

.unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;

	/*
		Introduced in IE 10.
		See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
	*/
	-ms-user-select: none;
	user-select: none;
}

.ar-dashboard-layout {
  margin: 0 -20px;

  /* width: calc(100% + 40px); */
}
.ar-dashboard-layout > div {
  /* box-shadow: 0 0 10px rgba(227, 231, 241, 0.62); */
  box-shadow: rgba(6, 29, 75, 0.1) 0px 0px 6px;
  border: 1px solid rgb(213, 222, 232);
  border-radius: 7px;
  background: white;
  padding: 1rem 0 !important;
  cursor: grab;
  display: flex;
	/* transition : border 0.3s linear, box-shadow 0.3s linear; */

  flex-direction: column;
}
.ar-dashboard-layout > div:hover {
 border-color: #1e73f0;
}
.ar-dashboard-layout > div.react-draggable-dragging {
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 8px,
    rgba(6, 29, 75, 0.14) 0px 0px 12px !important;
  cursor: grabbing !important;
}

.ar-dashboard-table-container {
  cursor: auto;
}

.ar-dashboard-layout .react-grid-placeholder {
  background: #bddbff;
  /* opacity: 1; */
}

.react-grid-item.react-draggable:hover {
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 6px;
}

.react-grid-item.react-draggable .ssi-button-primary-ghost {
  visibility: hidden;
  transition: visibility 0s ease-out;
}

.react-grid-item.react-draggable
  .ar-dashboard-table-container
  .ar-table
  tbody
  tr:hover
  > td
  .ssi-button-primary-ghost {
  visibility: visible;
  transition: visibility 0s linear;
}

.react-grid-item.react-draggable
  .ar-table
  tbody
  tr
  td:hover
  > div
  .ssi-button-primary-ghost {
  visibility: visible;
  transition: visibility 0s linear;
}

.react-grid-item.react-draggable .ar-breadcrumbs-icon {
  visibility: hidden;
  transition: visibility 0s ease-out;
}

.react-grid-item.cssTransforms{
	transition-property: transform, box-shadow, border !important;
}

.react-grid-item.react-draggable.cssTransforms.react-resizable:hover
  > .mb-4.px-4
  .ssi-button.ssi-control.ssi-button-primary-ghost {
  visibility: visible;
  transition: visibility 0s linear;
}
.react-grid-item.react-draggable.cssTransforms.react-resizable:hover
  > .hideActionButton
  .ssi-button.ssi-control.ssi-button-primary-ghost {
  visibility: visible;
  transition: visibility 0s linear;
}

.ar-table-fixed {
  table-layout: fixed;
}

.react-grid-item.react-draggable
  .ar-dashboard-table-container
  .ar-table
  tbody
  tr:hover
  > td
  .ar-breadcrumbs-icon {
  visibility: visible;
  transition: visibility 0s linear;
}

.ar-dashboard-table {
  color: #002b63;
  width: 100%;
}

.ar-dashboard-table td {
  padding-left: 12px;
  padding-right: 12px;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px; */
}

.ar-dashboard-table thead {
  text-transform: uppercase;
  font-size: 14px;
}

.ar-dashboard-table tbody {
  font-size: 12px;
}

.ar-dashboard-table thead th {
  padding-bottom: 15px;
  position: sticky;
  top: 0px;
  z-index: 1;
  background: white;
  font-weight: 400;
}

.ar-dashboard-table td,
.ar-dashboard-table th {
  padding: 4px 4px;
}

.ar-dashboard-table tbody td {
  border: solid 1px #ebebeb;
  border-left: none;
  border-right: none;
}
.ar-dashboard-table td:first-child,
.ar-dashboard-table th:first-child {
  padding-left: 0;
  padding-right: 10px;
}
.ar-dashboard-table td:last-child,
.ar-dashboard-table th:last-child {
  padding-right: 0;
}

.ar-dashboard-report {
  width: 100%;
}

.ar-dashboard-report td {
  min-width: 120px;
}

.ar-dashboard-report td:first-child,
.ar-dashboard-report th:first-child {
  padding-left: 30px;
  position: sticky;
  left: 0;
  max-width: 200px;
  z-index: 1;
}
.ar-dashboard-report td:last-child,
.ar-dashboard-report th:last-child {
  padding-right: 30px;
}

.ar-dashboard-report .department {
  background: #e7f1fd;
}

.bg-lightBlue {
  background: #e7f1fd;
}

.ar-aniversaryDateComponent-past {
  background-color: #e6edf4;
  color: #657b94;
}

.text-Expired {
  color: #657b94;
}
.ar-aniversaryDateComponent-present {
  background-color: #e6faf4;
  color: #0e6400;
}
.ar-aniversaryDateComponent-future {
  background-color: #e7f1fd;
  color: #002b63;
}

.ar-dashboard-report td,
.ar-dashboard-report th {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ar-dashboard-report .department td {
  padding-top: 6px;
  padding-bottom: 6px;
  position: sticky;
  left: 0;
}

.ar-dashboard-report thead th:first-child {
  z-index: 2;
}

.ar-dashboard-report thead th {
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.ar-dashboard-report tr.line td {
  background: white;
}
.ar-dashboard-report tr:nth-child(odd).line td {
  background: #f6faff;
}

.ar-dashboard-description {
  color: #7c98b6;
  font-size: 14px;
}

.ar-dashboard-open-values {
  font-size: 36px;
  color: #0e6400 !important;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-active-values {
  font-size: 36px;
  color: #7979e1 !important;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-draft-values {
  font-size: 36px;
  color: #ff8066 !important;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-review-values {
  font-size: 36px;
  color: #f7bf19 !important;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-closed-values {
  font-size: 36px;
  color: #0e6400 !important;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-values {
  font-size: 36px;
  color: #002b63;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-closed-blue-values {
  font-size: 36px;
  color:#002B63 !important;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-closed-red-values {
  font-size: 36px;
  color: #831f12 !important;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-canceled-values {
  font-size: 36px;
  color: #657b94 !important;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-active-description {
  font-size: 14px;
  color: #7979e1;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-closed-description {
  font-size: 14px;
  color: #0e6400;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-won-description {
  font-size: 14px;
  color: #002b63;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-draft-description {
  font-size: 14px;
  color: #ff8066;
  font-family: inherit;
  font-weight: bold;
}
.ar-dashboard-review-description {
  font-size: 14px;
  color: #f7bf19;
  font-family: inherit;
  font-weight: bold;
}

/* .ar-dashboard-closed-description {
  font-size: 14px;
  color: #002B63;
  font-family: inherit;
  font-weight: bold;
} */

.ar-dashboard-closed-red-description {
  font-size: 14px;
  color: #831f12;
  font-family: inherit;
  font-weight: bold;
}

.ar-dashboard-canceled-description {
  font-size: 14px;
  color: #657b94;
  font-family: inherit;
  font-weight: bold;
}
