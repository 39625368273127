.ar-percentage-bar {
  height: 8px;
  background: #e7f1fd;
  border-radius: 15px;
  overflow: hidden;
}

.ar-percentage-bar .slider {
  background: #1e73f0;
  border: none;
  transition: 0.3s ease-out;
  cursor: auto;
}
.ar-percentage-bar.danger .slider {
  background: #ff5962;
}
.ar-percentage-bar.alt .slider {
  background: #002B63;
}

.ar-percentage-bar-percentage-skeleton {
  width: 30px;
  height: 8px;
  border-radius: 15px;
}

.ar-percentage-bar-description-skeleton {
  margin-top: 7px;
  margin-bottom: 3px;
  width: 40%;
  height: 8px;
  border-radius: 15px;
}

.ar-percentage-bar-title-skeleton {
  width: 50px;
  height: 8px;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ar-percentage-bar-month-title-skeleton {
  width: 50px;
  height: 8px;
  border-radius: 15px;
  margin-top: 7px;
  margin-bottom: 3px;
}
