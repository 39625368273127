.bg-darkerWhite {
  background: #f2f6f9;
}

.ClassificationReport .weekend {
  text-align: center;
  font: Medium 14px/19px Roboto;
  letter-spacing: 0;
  color: #7c98b6 !important;
  text-transform: capitalize;
  opacity: 1;
}

.ClassificationReport .weekend-dashboard {
  font: Medium 14px/19px Roboto;
  letter-spacing: 0;
  color: #7c98b6 !important;
  text-transform: capitalize;
  opacity: 1;
}

.ClassificationReport .holiday {
  background: #ffeeef !important;
  text-align: center;
  font: Medium 14px/19px Roboto;
  letter-spacing: 0;
  color: #ff5962 !important;
  text-transform: capitalize;
  opacity: 1;
}

.ClassificationReport .holiday-dashboard {
  background: #ffeeef;

  font: Medium 14px/19px Roboto;
  letter-spacing: 0;
  color: #ff5962 !important;
  text-transform: capitalize;
  opacity: 1;
}

.ClassificationReport .TimeRange {
  box-shadow: 0px 3px 6px #0000000d;
}

.Cursor-Grab {
  cursor: grab;
}

.border-color-white {
  border-color: white !important;
}

.ClassificationReport .timeContainer .TimeRange:first-child {
  border-left: 1px solid #dee2e6 !important;
}

.ClassificationReport .bg-weekendStripes {
  background: url("../../Assets/Jpg/ar_weekends_background.jpg") !important;
}

.font-color-Gray {
  color: #7c98b6 !important;
}

.ClassificationReport .bg-holidayStripes {
  background: url("../../Assets/Jpg/ar_holidays_background.jpg") !important;
}

.ClassificationReport .TimeRangeMonths {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 46px;
  text-align: left;
  font-size: 15px;
  contain: content;
  font: Regular 16px/21px Roboto;
  letter-spacing: 0;
  color: #002b63;
  border-left: none;
  opacity: 1;
}

.ClassificationReport .TimeRangeDays {
  height: 37px;
  width: 80px;
  text-align: center;
  font: Regular 14px/19px Roboto;
  letter-spacing: 0;
  color: #002b63;
  text-transform: capitalize;
  opacity: 1;
}
.border-color-airdesk {
  border-color: #f9fbff !important;
}

.ClassificationReport .TimeRangeTotal {
  height: 38px;
  width: 80px;

  text-align: center;
  font: Medium 14px/19px Roboto;
  letter-spacing: 0;
  color: #002b63;
  text-transform: capitalize;
  opacity: 1;
  background: #f2f6f9 0% 0% no-repeat padding-box;
}

.ClassificationReport .DepartmentName {
  padding-top: 10px;
  padding-bottom: 9px;
  height: 45px;
  width: 171px;
  z-index: 1;
  left: 0;
  padding-left: 0.5rem !important;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  color: #002b63;
  opacity: 1;
}

.ClassificationReport .BasicCellWidth {
  width: 80px;
}

.ClassificationReport .DepartmentNameContainer:first-child {
  box-shadow: -5px 3px 6px #0000001a;
}

.ClassificationReport .StateNameContainer {
  padding-top: 9px;
  padding-left: 0.5rem !important;

  padding-bottom: 9px;
  width: 171px;
  left: 0;
}

.ClassificationReport .CellValue {
  text-align: center;
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  color: #002b63;
  text-transform: capitalize;
  opacity: 1;
}

.ClassificationReport .HorizontalTotal {
  text-align: right;
  font-size: 14px;
  left: 0;
  padding-left: 0.5rem !important;

  width: 171px;
  padding-top: 8px;
  padding-bottom: 8px;
  letter-spacing: 0;
  color: #002b63;
  text-transform: capitalize;
  opacity: 1;
  padding-right: 21px;
}

/* .AccountNameContainer:first-child {
  box-shadow: -5px 3px 6px #0000001a;
} */

.ClassificationReport .AccountName {
  padding-top: 7px;
  padding-left: 0.5rem !important;
  z-index: 324;
  padding-bottom: 7px;
  width: 171px;
  left: 0;

  /* padding-left: 30px; */
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #002b63;
  opacity: 1;
}

/* 
.ClassificationReport .DaysContainer:first-child {
  border-left: none !important;
} */

/* .ClassificationReport .DaysContainer:last-child {
  border-left: none !important;
} */

/* 
.ClassificationReport .TimeRangeDays:first-child {
  border-left: 1px solid #dee2e6 !important;
}

.ClassificationReport .TimeRangeDays:last-child {
  border-right: none !important
  ;
} */
