.ar-deal-const-selector {
  margin: -4px -9px;
}

.ar-deal-const-selector > * {
  padding: 4px 9px;
}

.ar-deal-const-selector input {
  border: none;
  outline: none;
  background: inherit;
  min-width: 0;
}

.moneyContainer {
  color: #0e6400;
  background: #e6faf4;
}
.ar-entity-summary-messages {
  transition: 0.3s;
  transition-property: width;
  background: #f9fbff;
  overflow-y: auto;
  overflow-x: hidden;
}

.ar-entity-summary-message-container {
  margin-top: 12px;
}

.ar-entity-summary-message {
  margin-left: 8px;
  max-width: 80%;
  background: white;
  /* box-shadow: 0 3px 6px #00000008; */
  border-radius: 10px;
  border-top-left-radius: 0px;
  /* display: inline-block; */
  white-space: pre-wrap;
  padding-top: 6px;
  padding-bottom: 8px;
}

.ar-entity-summary-message-container.grouped {
  margin-top: 8px;
}
.ar-entity-summary-message-container.grouped > .ar-entity-summary-message {
  margin-left: 40px;
}

.ar-entity-summary-message-container.self {
  justify-content: flex-end;
}

.ar-entity-summary-message.reply {
  background: #eef2f5;
}

.ar-entity-summary-message.self {
  justify-content: flex-end;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  background: #e7f1fd;
}

.ar-entity-summary-message-options {
  color: #1e73f0;
  transition: 0.3s;
  opacity: 0;
}

.ar-entity-summary-message:hover .ar-entity-summary-message-options {
  opacity: 1;
}

/* Replys */

.ar-entity-summary-message.reply {
  border-radius: 5px;
  /* border: solid 1px #1e73f0; */
  margin: 0;
  max-width: 100%;
  position: relative;
}
.ar-entity-summary-message.reply::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #7c98b6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* MessageSender  */
.ar-entity-summary-message-textarea::placeholder {
  color: #7c98b6;
}

.ar-entity-summary-message-textarea {
  background: white;
  color: #002b63;
  border-radius: 5px;
  padding: 12px;
}

.ar-entity-summary-message-rounded-button {
  outline: none !important;
  background: none;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.ar-entity-summary-message-send-button {
  color: #7c98b6;
}

.ar-entity-summary-message-send-button:hover {
  background: #e6edf4;
}

.ar-entity-summary-message-send-button:disabled {
  opacity: 0.4;
  background: none;
  cursor: default;
}

.ar-entity-summary-messages-count {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  background: #1e73f0;
  color: white;
  border-radius: 50%;
}

.br-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.br-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.PostFormDescription {
  max-height: 200px;
  overflow-y: auto;
}

.uppload-modal {
  z-index: 120000000 !important;
}
.uppload-modal section .uppload-active-container .uppload-service {
  color: #002b63 !important;
}

.uppload-modal section .uppload-active-container .uppload-service > p {
  font-weight: 500 !important;
}
.uppload-modal {
  font-family: Roboto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
.uppload-modal .uppload-service--default .uppload-services button {
  color: #002b63 !important;
}
.disableTimePicker input,
.disableTimePicker svg,
.disableTimePicker .DatePicker-FullContainer {
  color: #7c98b6 !important;
  background: #eaf0f6 0% 0% no-repeat padding-box;
  pointer-events: none;
}

.ImageContainer .RemoveContainer {
  color: #ff5962;
  border: 1px solid #d5dee8;
  width: 26px;
  height: 26px;
  top: -10px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: white;
}

.ImageBlock {
  margin: -1rem;
  margin-left: 0;
}

.ImageContainer {
  width: 103px;
  height: 103px;
  padding: 1px;
  margin: 16px;
  margin-left: 0;
  display: inline-block;
  border: 1px solid #d5dee8;
}

.PostDescription {
	max-height: 321px;
	word-break: break-word;
  overflow: visible !important;
}

.ImageContainer img {
  object-fit: contain;
  object-position: center;
}
.mb-6 {
  margin-bottom: 3.5rem !important;
}
.SideBarSocialPost-InputSide {
  box-shadow: 0px 8px 10px #0000001a;
  /* width: 1040px; */
}

.postsProfile.link-color.status-color-publishing > .status-dot {
  background-color: #736eff;
}

.postsProfile.link-color.status-color-scheduled > .status-dot {
  background-color: #36ab80;
}

.postsProfile.link-color.status-color-draft > .status-dot {
  background-color: #f7bf19;
}

.postsProfile.link-color.status-color-deleted > .status-dot {
  background-color: #000000;
}

.postsProfile.link-color.status-color-publishingFail > .status-dot {
  background-color: #ff5962;
}

.postsProfile.link-color.status-color-published > .status-dot {
  background-color: #1e73f0;
}

.postsProfile.link-color.status-color-canceled > .status-dot {
  background-color: #7c98b6;
}

.PreviewOpen .PreviewWidth {
  width: 520px;
}

.PreviewClosed .PreviewWidth {
  width: 50px;
}

.PreviewLinkContainer {
  /* width: 398px; */
  height: 115px;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  left: 2px !important;
}

.emoji-mart-preview-skins,
.emoji-mart-skin-swatches,
.emoji-mart-skin,
.emoji-mart-emoji {
  outline: none !important;
}

.border-danger-text-enable .TextInputPostsBorderDanger {
  border-right: 1px solid rgba(159, 58, 56, 0.7);
  border-left: 1px solid rgba(159, 58, 56, 0.7);
  border-top: 1px solid rgba(159, 58, 56, 0.7);
}

.border-danger-text-enable .TextInputPostsBorderDangerBottomHalf {
  border-right: 1px solid rgba(159, 58, 56, 0.7) !important;
  border-left: 1px solid rgba(159, 58, 56, 0.7) !important;
  border-bottom: 1px solid rgba(159, 58, 56, 0.7) !important;
}
