.month {
  border: solid 1px #d5dee8;
  background: white;
  position: relative;
}

.weekRow {
  position: relative;
  flex: 1;
  display: flex;
  border-bottom: solid 1px #d5dee8;
}
.weekRow:last-child {
  border-bottom: none;
}

.weekRowDays {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.weekItems {
  position: relative;
  width: 100%;
  margin-top: 32px;
}

.weekDay {
  flex: 1;
  border-right: solid 1px #d5dee8;
  color: #002b63;
  transition: 0.3s;
  user-select: none;
}
.weekDay:last-child {
  border-right: none;
}

.weekDay.outOfMonth {
  background: #d5dee840;
}
.weekDay.today {
  background: rgba(30, 114, 240, 0.1);
}

/* .weekDay:hover {
  color: white;
  background: #1e73f080;
} */

.weekDay .weekDayHeader {
  padding: 8px;
  line-height: 16px;
}

.rowItem {
  position: absolute;
  /* height: 24px; */

  z-index: 1;
  display: flex;
  align-items: center;
}

.rowContent {
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  background: #f9fbff;
  color: #002b63;
  border: solid 1px #d5dee8;
  width: 100%;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
}

.rowContent:hover {
  background: #e1ecfd;
}

.CalenderItemUpdated{
	border-color:orange;;
}

.rowWeekDays {
  display: flex;
}
.rowWeekDays > div {
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-right: solid 1px #d5dee8;
}
.rowWeekDays > div:last-child {
  border-right: none;
}

.dateBadge {
  background: rgba(30, 114, 240, 0.1);
  border-radius: 3px;
  padding: 0 4px;
}

.moreItemsPopper {
  max-height: 80%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.moreItemPopup {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
