.ar-alt-dropdown-container{
    height: fit-content;
	min-height: 40px;
	border-radius: 0.25rem !important;
}

.ar-alt-dropdown-popup{
    background: white;
    min-height: 100px;
    min-width: 370px;
    max-width: 540px;
    max-height: 400px;
    overflow: auto;
    /* padding: 4px 10px; */
}