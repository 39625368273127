.BillingPlan-Header {
  width: 100%;
  position: sticky;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dee8;
  opacity: 1;
  z-index: 1;
  padding: 20px;
}

.BillingPlan-Header-Line {
  width: fit-content;
  margin: auto;
}

.BillingPlan-Header-Line .IconCircleBillingPlan {
  color: white;
  border-radius: 50%;
  background: #002b63;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BillingPlan_Page {
  overflow: auto;

  max-height: 100%;
  position: relative;
  padding-top: 50px;
  height: 100%;
}

.BillingPlan_Content {
  width: fit-content;
  margin: auto;
}

.BillingPlan_Banner {
  width: 300px;
  position: relative;

  margin-right: 20px;
  overflow: hidden;
  border-radius: 5px;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000001a;

  opacity: 1;
}

.BillingPlan_Banner.starter {
  border-top: #00c7e5 5px solid;
}

.BillingPlan_Banner.starter .svg-inline--fa.fa-check.fa-w-16 {
  color: #00c7e5;
}

.BillingPlan_Banner.starter .UpgradeButton,
.starter.UpgradeButton {
  background: #e6fcff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  color: #00c7e5;
  width: 260px;
  cursor: pointer;
  transition: 0.3s;
  height: fit-content;
  padding: 10px;
}

.BillingPlan_Banner.starter .UpgradeButton:hover,
.starter.UpgradeButton:hover {
  background: #00c7e5;
  color: white;
}

.BillingPlan_Banner.basic {
  border-top: var(--purple50) 5px solid;
}

.BillingPlan_Banner.basic .svg-inline--fa.fa-check.fa-w-16 {
  color: var(--purple50);
}
.BillingPlan_Banner.basic .UpgradeButton:hover,
.basic.UpgradeButton:hover {
  background: var(--purple50);
  color: white;
}

.BillingPlan_Banner.basic .UpgradeButton,
.basic.UpgradeButton {
  background: #ccccf4 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  transition: 0.3s;
  color: var(--purple50);
  width: 260px;
  height: fit-content;
  cursor: pointer;
  padding: 10px;
}

.BillingPlan_Banner.pro {
  border-top: #4b8ff3 5px solid;
}

.BillingPlan_Banner.pro .svg-inline--fa.fa-check.fa-w-16 {
  color: #4b8ff3;
}

.BillingPlan_Banner.pro .UpgradeButton:hover,
.pro.UpgradeButton:hover {
  background: #4b8ff3;
  color: white;
}

.BillingPlan_Banner.pro .UpgradeButton,
.pro.UpgradeButton {
  background: #e7f1fd 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  transition: 0.3s;
  color: #4b8ff3;
  cursor: pointer;
  width: 260px;
  height: fit-content;
  padding: 10px;
}
.MostPopularBanner {
  top: 31px;
  left: -27px;
  font-size: 13px;
  width: 133px;
  background: #4b8ff3;
  color: white;
  transform: rotate(-45deg);
  text-align: center;
}

.BillingPlan_Banner.company {
  border-top: #0dcf93 5px solid;
}
.BillingPlan_Banner.company .svg-inline--fa.fa-check.fa-w-16 {
  color: #0dcf93;
}

.BillingPlan_Banner.company .UpgradeButton:hover,
.company.UpgradeButton:hover {
  background: #0dcf93;
  color: white;
}

.BillingPlan_Banner.company .UpgradeButton,
.company.UpgradeButton {
  background: #e6faf4 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-radius: 3px;
  opacity: 1;
  transition: 0.3s;
  color: #0dcf93;
  width: 260px;
  height: fit-content;
  padding: 10px;
}

.BillingPlan_Banner .BillingPlan_Banner-Separator {
  width: 160px;
  border-top: 1px solid #d5dee8;
}

.BillingPlan-Payment-Plan {
  height: 34px;
  padding: 8px 17px;
  font-weight: 500;
  border-radius: 3px;
}

.Free-Trial-Ended-Banner {
  height: 122px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f7bf19;
  border-radius: 3px;
  opacity: 1;
}

.Free-Trial-Small-Text {
  color: #7e6514 !important;
}

.Free-Trial-Small-Icon {
  background: #7e6514 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.Free-Trial-Ended-Banner .Free-Trial-Ended-Banner-Icon {
  background: #7e6514 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  font-size: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Free-Trial-Ended-Banner .Free-Trial-Ended-Banner-Title {
  font: Medium 30px/39px Roboto;
  letter-spacing: 0px;
  color: #7e6514;
  opacity: 1;
}

.Free-Trial-Ended-Banner .Free-Trial-Ended-Banner-Description {
  font: Regular 18px/24px Roboto;
  letter-spacing: 0px;
  color: #7e6514;
  opacity: 1;
}

.Free-Trial-Ended-Banner::before {
  height: 5px;
  width: 100%;
  background: #f7bf19 0% 0% no-repeat padding-box;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  content: " ";
  position: absolute;
  top: -1px;
}

/* ---------------------------------------------------------------------------------------------- */

.Renovation-Uncessefull-Banner {
  height: 122px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #7c98b6;
  border-radius: 3px;
  opacity: 1;
}

.Renovation-Uncessefull-Small-Text {
  color: #657b94 !important;
}

.Renovation-Uncessefull-Small-Icon {
  background: #657b94 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.Renovation-Uncessefull-Banner .Renovation-Uncessefull-Banner-Icon {
  background: #657b94 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  font-size: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Renovation-Uncessefull-Banner .Renovation-Uncessefull-Banner-Title {
  font-size: 30px;
  font-weight: medium;
  letter-spacing: 0px;
  color: #657b94;
  opacity: 1;
}

.Renovation-Uncessefull-Banner .Renovation-Uncessefull-Banner-Description {
  font-size: 18px;
  font-weight: regular;
  letter-spacing: 0px;
  color: #657b94;
  opacity: 1;
}

.Renovation-Uncessefull-Banner::before {
  height: 5px;
  width: 100%;
  background: #7c98b6 0% 0% no-repeat padding-box;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  content: " ";
  position: absolute;
  top: -1px;
}

/* ------------------------------------------------------------------------------ */

.Renovation-Canceled-Banner {
  /* height: 122px; */
  border-radius: 3px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ff5962;
  opacity: 1;
}

.Renovation-Canceled-Small-Text {
  color: #831f12 !important;
}

.Renovation-Canceled-Small-Icon {
  background: #831f12 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.Renovation-Canceled-Banner .Renovation-Canceled-Banner-Icon {
  background: #831f12 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  font-size: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Renovation-Canceled-Banner .Renovation-Canceled-Banner-Title {
  font-size: 30px;
  font-weight: medium;
  letter-spacing: 0px;
  color: #831f12;
  opacity: 1;
}

.Renovation-Canceled-Banner .Renovation-Canceled-Banner-Description {
  font-size: 18px;
  font-weight: regular;

  letter-spacing: 0px;
  color: #831f12;
  opacity: 1;
}

.Renovation-Canceled-Banner::before {
  height: 5px;
  width: 100%;
  background: #ff5962 0% 0% no-repeat padding-box;
  border-radius: 3px 3px 0px 0px;
  opacity: 1;
  content: " ";
  position: absolute;
  top: -1px;
}

.BillingPlan-Payment-Plan.starter {
  background: #e6fcff 0% 0% no-repeat padding-box;
  color: #00c7e5;
}

.BillingPlan-Payment-Plan.basic {
  background: #ccccf4 0% 0% no-repeat padding-box;
  color: var(--purple50);
}

.BillingPlan-Payment-Plan.pro {
  background: #e7f1fd 0% 0% no-repeat padding-box;
  color: #4b8ff3;
}

.BillingPlan-Payment-Plan.company {
  background: #e6faf4 0% 0% no-repeat padding-box;
  color: #0dcf93;
}

.AlignWithMarginCenter {
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.Transition-all {
  transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.AlignWithMarginCenter *:hover {
  text-decoration: none;
}

.BillingPlan_Banner .AppendToBottom {
  margin-top: 20px;
}

.BillingPlan_Banner_Current_Plan_Button.starter {
  color: #00c7e5;
  width: 260px;
  text-align: center;
  padding: 10px;
}

.Banner_disable_select * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

.BillingPlan_Banner_Current_Plan_Button.basic {
  color: var(--purple50);
  width: 260px;
  text-align: center;
  padding: 10px;
}

.BillingPlan_Banner_Current_Plan_Button.pro {
  color: #4b8ff3;
  width: 260px;
  text-align: center;
  padding: 10px;
}

.BillingPlan_Banner_Current_Plan_Button.company {
  color: #0dcf93;
  width: 260px;
  text-align: center;
  padding: 10px;
}

.Billing_Plan_More_Users_Container .IconContainer {
  border-radius: 50%;
  background: #e7f1fd;
  color: #1e73f0;
  width: 48px;
}

.Billing_Plan_More_Users_Container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 5px;
  opacity: 1;
  margin-top: 30px;
  padding: 20px;
  width: 1260px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
