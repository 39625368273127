.ar-onboarding-dep-items {
  margin: -10px;
}

.ar-onboarding-dep-item {
  width: 250px;
  height: 200px;
  background: white;
  border: solid 1px #d5dee8;
  margin: 10px;
  border-radius: 0.75rem;
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
}
.ar-onboarding-dep-item:hover {
  background: #e7f1fd;
}
.ar-onboarding-dep-item.selected {
  background: #e7f1fd;
  border-color: var(--purple50);
}

.ar-onboarding-dep-item-icon {
  width: 85px;
  height: 85px;
  background: #d5dee8;
  color: #ffffff;
  border-radius: 0.75rem;
  transition: 0.3s;
}

.ar-onboarding-dep-item.selected .ar-onboarding-dep-item-icon {
  /* background: #3d3d99; */
  background: linear-gradient(45deg, var(--purple50) 0%, #4949b6 50%, #3d3d99 100%);
}
