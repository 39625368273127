.ar-breadcrumbs-item {
  position: relative;
  height: 51px;
  /* margin-bottom: 8px; */
}
.ar-breadcrumbs-detail {
  padding: 8px 8px;
}

.ar-breadcrumbs-circle {
  height: 13px;
  width: 13px;
  /* margin-left: -1.4px; */
  border-radius: 50%;
  /* border: solid 1px #d5dee8; */
}

.ar-breadcrumbs-circle svg {
  height: 13px !important;
  width: 13px !important;
  /* border: solid 1px #d5dee8; */
}

.ar-breadcrumbs-item .status-dot {
  height: 13px !important;
  width: 13px !important;
}

.ar-breadcrumbs-circle .status-dot {
  border-width: 1px;
  border-style: solid;
  margin: 0;
}

.ar-breadcrumbs-item::after {
  content: "";
  top: 32px;
  left: 5px;
  height: 41px;
  position: absolute;
  display: block;
  border-left: 2px solid #d5dee8;
  border-left-style: dashed;
}

.ar-breadcrumbs.highlight .ar-breadcrumbs-detail {
  transition: 0.3s;
  opacity: 0.5;
}

.ar-breadcrumbs.highlight .ar-breadcrumbs-detail.highlight {
  opacity: 1;
  border: solid 1px #1e73f0;
}

.ar-breadcrumbs-item:hover .ar-breadcrumbs-detail {
  background: #1473e610;
  opacity: 1;
}

.ar-breadcrumbs-item:last-child {
  margin-bottom: 0;
}
.ar-breadcrumbs-item:last-child::after {
  content: none;
}

.ar-breadcrumbs-item::before {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  transform: rotate(-44deg);
  border-width: 2px;
  border-color: #d5dee8;
  border-style: none none solid solid;
  top: 44px;
  left: 2px;
}
.ar-breadcrumbs-item:last-child::before {
  content: none;
}

.ar-breadcrumbs-date-text-yellow {
  background: #fdf2d1;
  color: #f7bf19;
}

.ar-breadcrumbs-date-text-orange {
  background: #ffe6e0;
  color: #ff8066;
}

.ar-breadcrumbs-date-text-purple {
  background: #ddddf7;
  color: #7979e1;
}

.ar-breadcrumbs-date-text-blue {
  background: #afddcc;
  color: #2b8966;
}

.ar-breadcrumbs-date-text-green {
  background: #AFDDCC;
  color: #2B8966;
}

.ar-breadcrumbs-date-text-red {
  background: #FFBDC0;
  color: #CC474E;
}
.ar-breadcrumbs-date-text-grey {
  background: #e5eaf0;
  color: #96adc5;
}
