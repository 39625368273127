.header {
  height: 60px;
  z-index: 1;
  box-shadow: 0 3px 10px 0 #0000001a;
}

.headerTitle {
  font-size: 22px;
  font-weight: 300;
  color: #002b63;
  text-align: left;
  line-height: 1;
}

.import-textarea {
  max-height: 350px;
}

.nodeNoteContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
  cursor: pointer;
}

.nodeIcon {
  width: 48px;
}

.AutomationPropertySetterContainer {
  border-radius: 3px;
}

.reactFlowMinimap {
  top: 10px !important;
  left: 5px !important;
}

.automationContainer {
  flex: 1;
  outline: none;
}

.node {
  /* width: 500px; */
  border-radius: 7px;
  border: 1px solid #d5dee8;
  overflow: hidden;
  max-width: 250px;
  max-height: 200px;
}

.nodeIcon {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: #d5dee8;
  color: #002b63;
}

.nodeIcon.condition_if,
.nodeIcon.delay,
.nodeIcon.connect_workflow,
.nodeIcon.split_in_batches,
.nodeIcon.property_setter,
.nodeIcon.do_nothing {
  background: #ffccc2;
  color: #994d3d;
}

.nodeIcon.email_creation,
.nodeIcon.conversation_creation,
.nodeIcon.task_creation,
.nodeIcon.call_creation,
.nodeIcon.deal_creation,
.nodeIcon.contact_creation,
.nodeIcon.contract_creation,
.nodeIcon.subscription_creation,
.nodeIcon.client_creation,
.nodeIcon.ticket_creation,
.nodeIcon.project_creation {
  background: #e7f1fd;
  color: #1e73f0;
}

.nodeIcon.export_case_emails {
  background: #ffbdc0;
  color: #99353b;
}

.nodeIcon.get_deal,
.nodeIcon.get_client,
.nodeIcon.get_contact,
.nodeIcon.get_contract,
.nodeIcon.get_subscription,
.nodeIcon.get_task,
.nodeIcon.get_call,
.nodeIcon.get_ticket,
.nodeIcon.get_project,
.nodeIcon.get_ticket_conversations,
.nodeIcon.http_status_request,
.nodeIcon.http_request {
  background: #d4eee2;
  color: #0e6400;
}

.edgeButton {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  color: #1e73f0;
  background: white;
  border: 1px solid #1e73f0;
  outline: none !important;
}

.edgeButton:hover {
  background: #1e73f0;
  color: white;
  transition: 0.2s all linear;
}

.handle {
  background: "#555" !important;
  width: 16px !important;
  height: 16px !important;
}

.outline-0 {
  outline: none !important;
}
.border-0 {
  border: none !important;
}

.WorkflowFormBackground {
  background: #f9fbff url(../../../Assets/Svg/bg_automation.svg) repeat 0 0;
}

.keyDropdownTextStyle {
  text-transform: capitalize;
}

.ifElseValueItem {
  border-radius: 3px;
}

.FormInputExpressionContainer {
  padding: 1px 10px;
  border-radius: 3px;
  height: 40px;
  font-size: 16px;
  background: #e7f1fc;
  cursor: pointer;
}

.disableInputArrows input::-webkit-outer-spin-button,
.disableInputArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -ms-user-select: none; /*Internet Explorer */
  user-select: none;
}

.outline.selectedOutline {
  box-shadow: 0 0 0 3px rgb(30 115 240) !important;
}

.outlineDanger + div .outline {
  box-shadow: 0 0 0 3px rgb(255 89 98);
}

.outline.selectedOutline.DisabledNode {
  box-shadow: 0 0 0 3px rgb(190, 190, 190) !important;
}

.DisabledNode .nodeIcon,
.DisabledNode > div:last-child {
  color: #a0a0a0 !important;
  text-decoration: line-through;
  /* border: 1px solid #eee!important; */
  background-color: #eee;
}

.EdgeLabelsBottom {
  position: absolute;
  bottom: -32px !important;
  width: min-content !important;
  height: auto !important;
}
