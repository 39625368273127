.AutomationSidebarHeader {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
  opacity: 1;
	padding: 16px 20px;
  width: 100%;
  height: 57px;
	z-index: 3;
}
.AutomationSidebarFooter {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px -3px 10px #E3E7F19E;
	opacity: 1;
  width: 100%;
  height: 80px;
	padding: 20px 30px;
}


.keyPre {
  user-select: none;
  cursor: pointer;
  position: relative;
  background: white;
  border: solid 1px #d5dee8;
  border-radius: 7px;
  transition: 0.3s;
}
.keyPre pre {
  white-space: revert !important;
}
.keyPre.wrap pre {
  white-space: pre-wrap !important;
}
.keyPre:hover {
  background: #ebf1f7;
  border: solid 1px #d5dee8;
  border-radius: 7px;
}

.keyPre pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.keyPre svg {
  opacity: 0;
  position: absolute;
  top: 11px;
  right: 11px;
  /* width: 30px !important;
  height: 30px; */
  min-width: 0;
  padding: 0;
  transition: 0.3s;
}

.keyPre:hover svg {
  opacity: 1;
  display: block;
}

.keyDesc .keyDescIcon {
  opacity: 0;
  width: 30px;
  height: 30px;
  background: white;
  cursor: pointer;
}

.keyDesc:hover .keyDescIcon {
  opacity: 1;
}

.keyDesc .keyDescIcon:hover {
  background: #ebf1f7;
}

.disableSideBarPreview .nodebaseSidebarPreviewSide{
	width: 60px !important;
  overflow: hidden;
}

.nodebaseSidebarPreviewSide{
	width: 450px;
}