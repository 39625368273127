.CGrid {
  overflow: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.CGrid table .Checkbox-container {
  vertical-align: middle;
  margin: 0;
}

.hideScrollBar {
  /* This is the magic bit for Firefox */
  scrollbar-width: none;
}

.hideScrollBar::-webkit-scrollbar {
  /* This is the magic bit for WebKit */
  display: none;
}

.CGrid table {
  margin: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  outline: 0;
  table-layout: fixed;
}

/* .CGrid .c-grid-body table {
  table-layout: fixed;
} */
/* .CGrid .c-grid-header table th svg {
  display: none;
} */
.CGrid .c-grid-header table.c-groupable th span {
  pointer-events: none;
}

.CGrid .c-grid-header table thead th span {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.static-background {
  background-color: #ebf1f7 !important;
}

th.unsortable {
  border-right: 2px solid #d5dee8 !important;
}

.CGrid .c-grid-header table.c-sortable th span {
  vertical-align: middle;
  pointer-events: none;
}

/* .CGrid .c-grid-header table th svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  pointer-events: none;
  width: 15px;
  height: 15px;
} */

.CGrid .c-grid-header table.c-sortable th,
.CGrid .c-grid-header table.c-groupable th {
  cursor: pointer;
  user-select: none;
}

.CGrid .c-grid-header {
  /* flex: 0 0 auto; */
  /* background-color: #e6edf4bf; */
  background-color: rgb(235, 241, 247);
  border-bottom: 1px solid #d5dee8;

  /* border: 1px solid #d5dee8; */
  /* border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  border-right: none;
  display: inline-block;
  /* padding-right: 11px; */
  position: sticky;
  top: 0px;
  z-index: 1;
}

.c-grid-footer .c-grid-page:hover svg,
.c-grid-page:hover {
  color: #5757d9 !important;
}

.c-grid-footer .c-grid-page svg {
  user-select: none;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #b0c1d3;
  text-align: center;
  vertical-align: middle;
  margin-top: -2px;
}

.c-grid-footer .c-grid-forward svg,
.c-grid-footer .c-grid-backward svg {
  margin-top: -1px;
  width: 100%;
  height: 13px;
}

.c-grid-footer .c-grid-pagesize,
.c-grid-footer .c-grid-page {
  cursor: pointer;
  color: #b0c1d3;
  text-align: center;
  padding: 5px;
  user-select: none;
  display: inline-block;
  position: relative;
}

.c-grid-footer .c-grid-pagesize {
  /* height: 29px; */
  width: auto;
}

.c-grid-footer .c-grid-page {
  width: 45px;
  height: 29px;
	font-weight: 500;
}

.c-grid-footer .c-grid-page-selector {
  width: auto;
  height: 36px;
  margin-right: 0.5rem;
  color: #5757d9;
}

.c-grid-page-selector > label > .flex-1 {
  margin-right: 5px;
}

.c-grid-page-selector > label svg {
  color: #5757d9 !important;
}

.c-grid-footer .c-grid-page.c-current-page {
  color: #5757d9;
}

.CGrid .c-grid-body tbody tr.unread td:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  /* margin: auto 0; */
  /* display: block; */
  width: 5px;
  height: 100%;
  background: #5757d9;
  overflow: hidden;
  /* border-top-right-radius: 50px;
  border-bottom-right-radius: 50px; */
}

.CGrid .c-grid-body tbody tr.unread.notAssigned td:first-child:before {
  background: #d5dee8 !important;
}

/* .CGrid .c-grid-header:after {
  width: 1px;
  height: 100%;
  content: "";
  right: 16px;
  top: 0;
  position: absolute;
  display: block;
  background-color: #d5dee8;
} */
.CGrid .c-grid-table-wrap {
  position: relative;
  display: block;
}

.CGrid .c-grid-header-wrap {
  overflow: hidden;
}

.CGrid.header-scroll .c-grid-header-wrap {
  overflow-y: hidden;
  overflow-x: auto;
}

.CGrid .c-grid-body td,
.CGrid .c-grid-body-footer td,
.CGrid .c-grid-header th {
  text-align: left;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
}

.CGrid .c-grid-content {
  border: solid 1px #d5dee8;
}

.CGrid
  .c-grid-body
  tr.c-grid-subcomponent-collapsable
  td.c-grid-subcomponent-collapsable-td,
.CGrid .c-grid-body tr.c-grid-collapsable td {
  padding: 0;
}

.CGrid .c-grid-body tr.c-grid-collapsable td span {
  padding: 10px 0;
}

.CGrid .c-grid-header th {
  height: 40px !important;
  color: #002b63;
  text-transform: uppercase;
  border-right: 2px solid #d5dee8;
  line-height: 30px;
  position: relative;
}

.CGrid .c-grid-body {
  /* flex: 1 1 auto;
  min-height: 50px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d5dee8;
  border-top: 0;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-x: auto;
  overflow-y: scroll; */
  margin: -5px 0;
  display: inline-block;
}

.CGrid .c-grid-body-footer {
  position: sticky;
  bottom: 0px;
  display: inline-block;
  margin-bottom: -6px;
  padding: 5px 0;
}

.CGrid .c-grid-body tr.c-grid-subcomponent-collapsable button,
.CGrid .c-grid-body tr.c-grid-collapsable button {
  width: 20px;
  height: 42px;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  vertical-align: middle;
  padding: 10px 0;
}

.CGrid .c-grid-body tr.c-grid-subcomponent-collapsable svg,
.CGrid .c-grid-body tr.c-grid-collapsable svg {
  width: 20px;
  height: 20px;
  padding: 1px;
  position: relative;
  margin: 0 auto;
  display: none;
}

.CGrid .c-grid-body tr svg.c-grid-coll {
  display: block;
}

.CGrid .c-grid-body tr.c-grid-sc-expanded svg.c-grid-coll,
.CGrid .c-grid-body tr.c-grid-expanded svg.c-grid-coll {
  display: none;
}

.CGrid .c-grid-body tr.c-grid-sc-expanded svg.c-grid-expa,
.CGrid .c-grid-body tr.c-grid-expanded svg.c-grid-expa {
  display: block;
}

/* .CGrid .c-grid-body tr.c-grid-subcomponent-collapsable span, */
.CGrid .c-grid-body tr.c-grid-collapsable span {
  color: #33475b;
  font-size: 15px;
  font-weight: 600;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.CGrid .c-grid-body td {
  border-bottom: 1px solid rgb(213, 222, 232);
  color: #002b63;
}

.CGrid .c-grid-body tr.c-grid-collapsable td {
  padding-left: 0;
  padding-right: 0;
}

.CGrid .c-grid-body tbody > tr {
  width: 100%;
  background-color: #fff;
}

.CGrid .c-grid-body tbody > tr.c-isOdd {
  width: 100%;
  background-color: #fcfcff;
}

.CGrid .c-grid-body tbody > tr:hover {
  background-color: #1473e610;
}

.CGrid .c-grid-body tr.c-grid-selected {
  background-color: #e7f1fd !important;
}

.CGrid .c-grid-body tr:last-child td {
  border: 0;
}

.c-grid-footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
}

.c-resize {
  position: absolute;
  display: flex;
  width: 3px;
  height: 100%;
  top: 0;
  left: -5px;
  border-left: 5px solid transparent;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.c-resize::before {
  content: "";
  width: 9px;
  padding-right: 7px;
  background: transparent;
  margin-right: -8px;
  opacity: 0.5;
}

.c-resize::after {
  content: "";
  width: 9px;
  background: transparent;
  opacity: 0.5;
  margin-left: -15px;
}

.c-resize.active {
  background-color: #007bff !important;
}

.hovering {
  border-right-color: transparent !important;
}

.sortable-icon {
  position: absolute;
  color: #ced4da;

  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.sortable-icon svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  pointer-events: none;
  width: 15px;
  height: 15px;
}

.c-grid-table-wrap th:not(.static-background):hover {
  background-color: #e7f1fd !important;
}

.c-sorting-desc,
.c-sorting-asc {
  background-color: #dfe7f0;
}

body.c-column-resize {
  cursor: col-resize !important;
}

.CGrid .c-grid-table-wrap:hover .c-resize {
  background-color: #007bff;
}

.c-grid-table-wrap {
  pointer-events: none;
}

.CGrid .c-grid-table-wrap .c-resize {
  pointer-events: auto;
}

.CGrid .c-grid-table-wrap table thead tr th {
  pointer-events: auto;
}

.c-resize:hover,
.c-resizing .c-resize {
  background-color: #d5dee8;
  cursor: col-resize;
}

.CGrid.c-resizing th {
  user-select: none !important;
  cursor: col-resize !important;
}

.CGrid .c-grid-dropzone {
  min-height: 56px;
  position: relative;
  display: block;
  flex: 0 0 auto;
  padding: 10px;
  line-height: 26px;
}

.c-grid-dragclone {
  position: fixed;
  width: 180px;
  height: 40px;
  background-color: #fff;
  display: none;
  color: #506e94;
  border: 1px solid #506e94;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 9px 1px #d8d8d8;
  box-shadow: 0px 0px 9px 1px #d8d8d8;
  pointer-events: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  /* text-indent: 31px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.c-grid-dragclone.clone-visible {
  display: block;
}

.c-grid-dropzone-item {
  display: inline-block;
  position: relative;
  /* margin: 10px; */
  margin-right: 15px;
  height: 36px;
  padding: 6px 8px 6px 10px;
  background: #edeff4;
  border-radius: 3px;
  border: 1px solid #d5dee8;
  /* border: 1px solid #506e94; */
}

.c-grid-dropzone-item svg {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  /* padding: 5px; */
  margin-left: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.c-grid-dropzone-nodata {
  line-height: 35px;
}

body.c-grid-clonedrag * {
  cursor: -webkit-grabbing !important;
}

.c-grid-body .ssi-button-primary-ghost,
.c-grid-body .ssi-button-danger-ghost-alt,
.c-grid-body .ar-breadcrumbs-icon {
  visibility: hidden;
  transition: visibility 0s ease-out;
}

.ar-virtual-grid tbody tr:hover > td .ssi-button-primary-ghost,
.ar-virtual-grid tbody tr:hover > td .ssi-button-danger-ghost-alt,
.ar-virtual-grid tbody tr:hover > td .ar-breadcrumbs-icon,
.CGrid .c-grid-body tbody tr:hover > td .ssi-button-primary-ghost,
.CGrid .c-grid-body tbody tr:hover > td .ssi-button-danger-ghost-alt,
.CGrid .c-grid-body tbody tr:hover > td .ar-breadcrumbs-icon {
  visibility: visible;
  transition: visibility 0s linear;
}

/* .CGrid {
  position: relative;
  display: grid;
  padding: 10px;
}

.c-grid-header {
  display: grid;
  position: relative;
  grid-gap: 10px;
  background-color: #2196f3;
  grid-auto-flow: column;
}
.c-grid-header-item {
  color: #fff;
}

.c-grid-body {
  display: grid;
  position: relative;
  grid-gap: 10px;
  background-color: rgb(221, 114, 114);
  grid-auto-flow: row;
}
.c-grid-item {
  color: #fff;
}

.c-grid-footer {
  display: grid;
  position: relative;
  grid-gap: 10px;
  background-color: rgb(95, 163, 95);
  grid-auto-flow: column;
}

.c-grid-footer-item {
  color: #fff;
} */

.grid-skeleton {
  animation: gridSkeleton ease-in-out 2s 0.2s infinite;
  background: #eaf1fd;
  height: 20px;
  width: 100%;
}

@keyframes gridSkeleton {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* .ar-main-grid td:last-child{
  position: sticky;
  right: 0;
} */
