.ticket-rating-pill-1 {
  background: #ffeeef;
  color: #ff5962;
}
.ticket-rating-pill-2 {
  background: #ffe3de;
  color: #ff8066;
}
.ticket-rating-pill-3 {
  background: #fef8e7;
  color: #f7bf19;
}
.ticket-rating-pill-4 {
  background: #e6faf4;
  color: #0dcf93;
}
.ticket-rating-pill-5 {
  background: #e5faf4;
  color: #36ab80;
}
.face-sizes {
  width: 24px;
  height: 24px;
}

pre.tirac {
  outline: none;
  border-radius: 7px;
}
.tirac .string {
  color: green;
}
.tirac .number {
  color: darkorange;
}
.tirac .boolean {
  color: blue;
}
.tirac .null {
  color: magenta;
}
.tirac .key {
  color: red;
}
