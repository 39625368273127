.headerContainer {
  padding: 45px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6edf4;
}

.headerContainer img {
  max-width: 202px;
  padding-bottom: 0;
  display: inline !important;
  vertical-align: bottom;
}

.bodyContainer {
  padding-top: 36px;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
}

.ticketTitle {
  color: #002b63;
  font-size: 18px;
  margin-bottom: 8px;
}
.subTitle {
  color: #7c98b6;
  font-size: 14px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.textContainer {
  font-size: 14px;
  color: #002b63;
  word-break: normal;
}

.feedbackTitle {
  display: flex;
  justify-content: center;
  color: #002b63;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 22px;
  margin-top: 30px;
}

.feedback-rating-faces-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.feedback-rating-faces-container svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.feedback-rating-faces-container .feedback-face-box:not(:last-child) {
  margin-right: 15px;
}

.feedback-face-box {
  position: relative;
  width: 40px;
  height: 40px;
}

.feedback-face-box svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.feedback-face-box svg.default,
.feedback-face-box svg.hover,
.feedback-face-box svg.active {
  transition: all 0.3s;
}
.feedback-face-box svg.default {
  opacity: 1;
}

.feedback-face-box:hover svg.default,
.feedback-face-box.active svg.default,
.feedback-face-box.active:hover svg.hover {
  opacity: 0;
}

.feedback-face-box svg.hover {
  opacity: 0;
  z-index: 2;
}

.feedback-face-box:hover svg.hover {
  opacity: 1;
  transform: scale(1.2);
}

.feedback-face-box svg.active {
  opacity: 0;
  z-index: 3;
  transform: scale(1.2);
}
.feedback-face-box.active svg.active {
  opacity: 1;
}

.bodyContainer textarea {
  color: #002b63;
  width: 400px;
  outline: none;
  background: #f9fbff;
  border: solid 1px #d5dee8;
  resize: none;
  border-radius: 3px;
  height: 110px;
  padding-left: 10px;
  margin-bottom: 16px;
  padding-top: 13px;

  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.bodyContainer textarea:focus {
  border-color: #1e73f0 !important;
}

.bodyContainer .textareaContainer {
  height: 0px;
	overflow: hidden;
  transition: all 0.3s linear;
}
.bodyContainer .textareaContainer.active {
  height: 130px;
}

.bodyContainer textarea::-webkit-scrollbar {
  padding: 4px 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
}
.bodyContainer textarea::-webkit-scrollbar-thumb {
  background: #c1c9d2;
  border-radius: 12px;
  border: 2px solid transparent;
  background-clip: content-box;
}


.UnderSubText{
	font-size: 12px;
	color: #002B63;
}