.ar-messages-files-table thead th {
  font-weight: 400;
  position: sticky;
  top: 0;
  background: #f9fbff;
}

.ar-messages-files-table th,
.ar-messages-files-table td {
  padding: 8px 4px;
  border-bottom: solid 1px #d5dee8;
}

.ar-messages-files-table th:first-child,
.ar-messages-files-table td:first-child {
  padding-left: 0;
}
.ar-messages-files-table th:last-child,
.ar-messages-files-table td:last-child {
  padding-right: 0;
}

.ar-entity-summary-no-files {
  background: url("../../../../Assets/Svg/empty_list_files.svg");
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-top: 135px;
}
