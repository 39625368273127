.ar-html-input {
  height: auto;
  min-height: 100px;
  resize: none;
  transition: 0.3s;
}

.ar-html-input::placeholder,
.ar-html-input::-moz-placeholder,
.ar-html-input::-webkit-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  color: #7c98b6 !important;
  font-size: 16px !important;
  font-weight: 400;
}

.ar-html-input .DraftEditor-root {
  padding: 0.375rem 0.75rem;
}

.cke_editable {
  background: #f5f8fa !important;
}

/* .ar-htmlinput {
    border: 1px solid #D5DEE8;
    background-color: #F9FBFF;
    border-radius:3px;
    display: flex;
    flex-direction: column;
    height:120px;
}
.ar-htmlinput label {
    width: 100%;
    flex: 1 1 auto;
    color: #000;
    background: none;
    outline:0;
    border:0;
    font-size:14px;
    cursor: text;
    padding: 10px;
    box-sizing: border-box;
    margin:0;
    overflow: auto;
}
.ar-htmlinput .ar-htmlinput-button-area {
    flex: 0 0 30px;
    display: block;
    position:relative;
}
.ar-htmlinput .ar-htmlinput-button-area button {
    width: 27px;
    height: 27px;
    outline:0;
    background: transparent;
    border:0;
    cursor: pointer;
}
.ar-htmlinput .ar-htmlinput-button-area button svg {
    color: #9ab4bf;
}
.ar-htmlinput .ar-htmlinput-button-area button:hover svg, .ar-htmlinput .ar-htmlinput-button-area button.ar-active svg, .ar-htmlinput .ar-htmlinput-button-area button.ar-active-keep svg {
    color: #1E73F0;
} */
