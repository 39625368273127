.ar-virtual-grid-container {
  border: 1px solid #d5dee8;
  outline: none;
  /* border-radius: 30px; */
}

.ar-virtual-grid thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  padding: 0 5px;
  color: #657b94;
  background-color: #ebf1f7;
  text-transform: uppercase;
  height: 36px;
  /* border-right: 2px solid #d5dee8; */
  box-shadow: 0px 3px 10px #e3e7f1;
}

.ar-virtual-grid thead tr th::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d5dee8;
}

.deleted_row {
  opacity: 0.4 !important;
  pointer-events: none !important;
}

/* .ar-virtual-grid thead tr th::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d5dee8;
} */
.ar-virtual-grid thead tr th::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d5dee8;
}

.disableBackground tr {
  background: #f9fbff !important;
  border-bottom: 1px solid #d5dee8;
}

.disableBorder {
  border-color: transparent !important;
}

table.ar-virtual-grid.disableHeader thead {
  visibility: hidden;
}

.ar-virtual-grid {
  table-layout: fixed;
  width: 100%;
  color: #002b63;
}

.ar-virtual-grid td {
  position: relative;
  overflow: hidden;
  font-size: 12px;
}

.ar-virtual-grid tbody td.pinned {
  position: sticky;
  z-index: 3;
  /* box-shadow: 0px 3px 10px #e3e7f1; */
}

.ar-virtual-grid tbody td.lastPin {
  z-index: 2;
  box-shadow: 0px 3px 10px #e3e7f1;
}
.ar-virtual-grid tbody td.lastPin::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d5dee8;
}

/* .ar-virtual-grid tbody td.pinned:last-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d5dee8;
} */
.ar-virtual-grid tbody td.lastPin {
  box-shadow: 0px 3px 10px #e3e7f1;
}

.ar-virtual-grid tbody tr,
.ar-virtual-grid tbody td.pinned {
  background-color: white;
}
.ar-virtual-grid tbody tr.odd,
.ar-virtual-grid tbody tr.odd td.pinned {
  background-color: #fcfcff;
}

.ar-virtual-grid thead tr th.chooser {
  width: 40px;
  position: sticky;
  right: 0;
  cursor: pointer;
  z-index: 2;
}
.ar-virtual-grid thead tr th.chooser:hover {
  background-color: #e7f1fd;
}
.ar-virtual-grid thead tr th.chooser::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: #d5dee8;
}

.ar-virtual-grid thead tr th.chooser {
  width: 40px;
  position: sticky;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.ar-virtual-grid tbody tr td > div {
  padding: 5px;
  display: flex;
  align-items: center;
}

.ar-virtual-grid tbody tr td:first-child > div,
.ar-virtual-grid thead tr th:first-child,
.ar-virtual-grid tfoot tr td:first-child {
  padding-left: 1rem;
}

.ar-virtual-grid tbody tr td:last-child > div,
.ar-virtual-grid thead tr th:last-child,
.ar-virtual-grid tfoot tr td:last-child {
  padding-right: 1rem;
}

.ar-virtual-grid tbody tr:hover,
.ar-virtual-grid tbody tr.selected,
.ar-virtual-grid tbody tr:hover td.pinned,
.ar-virtual-grid tbody tr.selected td.pinned {
  background-color: #eaf1fc !important;
}

.ar-virtual-grid-resizer {
  width: 14px;
  border-right: solid 5px #007bff;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  visibility: hidden;
  cursor: col-resize;
}
.ar-virtual-grid th:hover .ar-virtual-grid-resizer {
  visibility: visible;
}

.ar-virtual-grid th.sortable {
  user-select: none;
  cursor: pointer;
}

.ar-virtual-grid th.sortable:hover {
  background-color: #e7f1fd;
}

.ar-virtual-grid th.sorted {
  background-color: #dfe7f0 !important;
}

.ar-virtual-grid tfoot tr td {
  position: sticky;
  bottom: -1px;
  z-index: 3;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 5px;
  background-color: #ebf1f7;
  /* border-right: 2px solid #d5dee8; */
}

.ar-virtual-grid tfoot {
  font-size: 12px;
}

.ar-virtual-grid tbody tr.unread td:first-child:before,
.ar-virtual-grid tbody tr.updated td:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  /* margin: auto 0; */
  /* display: block; */
  width: 5px;
  height: 100%;
  background: #1e73f0;
  overflow: hidden;
  /* border-top-right-radius: 50px;
  border-bottom-right-radius: 50px; */
}

.ar-virtual-grid tbody tr.updated td:first-child:before {
  background: orange;
}
