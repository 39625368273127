.airReportingContainer {
  width: 1146px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.airReportingContainer .air-Report-button {
  padding: 20px;
  background: white;
  border: 1px solid #d5dee8;
  width: 47%;
  text-decoration: none;
  height: 125px;
  border-radius: 5px;
  margin: 10px 10px;
}

.NonBillableContainer{
	background: #d7eee6;
	font-weight: normal;
}

.airReportingContainer .air-Report-button:hover {
  background: rgba(20, 115, 230, 0.1);
}

.airReportingContainer .air-Report-button .air-report-icon {
  width: 85px;
  height: 85px;
  font-size: 28px;
  color: white;
  background: transparent
    linear-gradient(45deg, var(--purple50) 0%, #4949b6 99%, #3d3d99 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
}

.ar-reports-container {
  overflow: auto;
  display: flex;
}

.ar-reports-container .report-table {
  /* padding-left: 270px; */
  /* padding-top: 20px; */
  overflow: auto;
  margin: 0;
  position: relative;
}
.ar-reports-container .report-table .body {
  /* padding-top: 50px; */
  overflow: scroll;
}

.ar-reports-container .fixed-bar {
  width: 270px;
  /* padding-top: 20px; */
  /* position: absolute; */
  overflow: hidden;
  display: block;
  position: relative;
  /* padding-top: 50px; */
  padding-bottom: 20px;
  box-shadow: 3px 0 10px 0px #e3e7f1;
  z-index: 1;
}

.ar-reports-container .fixed-bar .content {
  overflow: auto;
  position: relative;
  display: block;
  height: calc(100% - 47px);
  width: calc(100% + 20px);
  padding-right: 20px;
  box-sizing: unset;
  z-index: -1;
  /* width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  display: block; */
}

.ReportsContainerSelector:hover .AccountNameContainer{
	opacity: 0.65;
	transition: 0.2s linear opacity;
}

.AccountNameContainer:hover{
	opacity: 1 !important;
	outline: 1px solid #cbd6e2;
	box-shadow: -5px 3px 6px #0000001a;
}

.ar-reports-container .report-header {
  /* display: inline-block; */
  height: 50px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* position: absolute; */
  /* background: white; */
  padding-right: 20px;
  z-index: 1;
  box-shadow: 0px 3px 10px 0px #e3e7f1;
}
.ar-reports-container .month-container {
  height: 50px;
  box-shadow: 0px 3px 10px 0px #e3e7f1;
}

.ar-reports-container .report-header .report-header-container {
  overflow: hidden;
  display: flex;
  width: 100%;
}

.ar-reports-container .fixed-bar .line {
  display: flex;
  align-items: center;
}

.ar-reports-container .report-table .line {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.ar-reports-container .report-table .department-line {
  width: 100%;

  white-space: nowrap;
}

.ar-reports-container .report-table .department-line,
.ar-reports-container .fixed-bar .department-line,
.ar-reports-container .report-table .line,
.ar-reports-container .fixed-bar .line {
  height: 40px;
  min-width: 100%;
  margin: 4px 0;
}
/* .ar-reports-container .fixed-bar .line:nth-child(odd),
.ar-reports-container .report-table .line:nth-child(odd) {
  background: #f6faff;
} */

.ar-reports-container .report-table .department-line,
.ar-reports-container .fixed-bar .department-line {
  background: #e5f0ff;
  display: flex;
}
.ar-reports-container .fixed-bar .department-line {
  display: flex;
  align-items: center;
}

.ar-reports-container .report-table .department-line > .table-item {
  background: #e5f0ff;
  height: 100%;
}

.ar-reports-container .header-item,
.ar-reports-container .table-item {
  min-width: 100px;
  width: 100%;
  margin: 0 2px;
  /* padding: 0 20px; */
  display: inline-block;
}
.ar-reports-container .table-item {
  height: 100%;
}

.total-field {
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.ar-reports-container .report-table .body {
  cursor: grab;
}

/* .ar-reports-container .report-table .body.kinetic-grabbing {
  cursor: grabbing;
} */
