.FilterList {
  font-size: 14px;
  padding-right: 20px;
	padding-bottom: 20px;
}

.FilterList > div {
  width: 190px !important;
}

.a-filter-item {
  display: block;
  position: relative;
  font-size: 12px;
  /* height: 36px; */
  /* padding: 6px 8px 6px 10px; */
  /* background: #e6edf4bf; */
  color: #002b63;
  /* border-radius: 6px;
  border: 1px solid #d5dee8; */
  /* border: 1px solid #506e94; */
}

.ar-filterlist-icons{
	color: #657b94
}

.dragging .Filter-GripVerticalIcon {
  color: #1e73f0 !important;
}

.Filter-GripVerticalIcon {
  width: 19px;
  cursor: move;
  color: #d5dee8;
  background: transparent;
}

.a-filter-item .removal-cross {
  color: #ff5962;
}

.FilterChooserOrderList {
  height: 340px;
  overflow: auto;

  /* padding-right: 10px; */
}

.disabled-pipeline-dropdown {
  width: 194px;
  height: 40px;
  background-color: #e6edf4;
  color: #7c98b6;
  text-align: left;
}

.a-ordenable-filters {
  background: #ffffff;
  border: 1px solid #d5dee8;
	overflow-x: hidden;
  /* margin-top: 10px;
		  margin-bottom: 10px; */
  padding-top: 7px;
  border-radius: 3px;
  opacity: 1;

  /* height: 40px; */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
										supported by Chrome and Opera */
}

.a-filters-sortable-icon .active {
  fill: #1e73f0 !important;
}
.a-filters-sortable-icon {
  color: #ced4da;
  height: 14px;
  width: 9px;
  margin-right: 0.5rem;
	flex: 0 0 auto;
  align-items: center;
  justify-content: center;
}
.breakWord {
  word-break: break-word;
}
.a-filter-linkbutton {
  /* color: #0db4cf; */
  color: #1e73f0;
  cursor: pointer;
  user-select: none;
}
.a-filter-title {
  color: #002b63;
}
.a-filter-group-item {
  margin-top: 8px;
}
.a-filter-group-item > label,
.a-filter-group-item > div {
  margin: 0;
  vertical-align: middle;
}
.a-filter-group-item > div {
  display: inline-block;
  margin-left: 10px;
}
.a-filter-group-view .a-filter-title {
  margin-bottom: 5px;
}
.a-filter-group-view .a-filter-linkbutton,
.a-filter-view-1 .a-filter-linkbutton,
.a-filter-view-2 .a-filter-linkbutton {
  margin-bottom: 20px;
}
.a-filter-group-area .a-filter-linkbutton {
  margin-bottom: 15px;
}
.a-filter-group-area .a-filter-item,
.a-filter-filter-area .a-filter-item {
  margin: 8px 0;
}
.a-filter-group-area {
  padding: 0 7px 13px;
  margin-bottom: 13px;
  border-bottom: 2px solid #d5dee8;
}
.a-filter-filter-area {
  padding: 0 7px;
}
.a-filter-filter-title {
  color: #002b63;
  font-weight: 600;
  font-size: 15px;
}
.a-filter-filter-item {
  color: #002b63;
  cursor: pointer;
}
/* .a-filter-radio-area {
  display: none;
} */
.a-filter-radio-area input,
.a-filter-view-2 .DatePicker-FullContainer {
  margin-top: 10px;
  margin-bottom: 15px;
}
.a-filter-view-2 button {
  margin-top: 15px;
}

.a-filter-view-2 .DatePickerComponent-R button {
  margin-top: 0px;
}

.a-filter-filter-area .a-filter-linkbutton {
  margin-top: 12px;
}
.a-filter-filter-area .a-filter-item {
  padding-right: 31px;
  height: auto;
}
.a-filter-radio-full-container ~ div {
  margin-top: 8px;
}
.a-filter-view-1 .a-filter-filter-title {
  margin-bottom: 7px;
}
.a-filter-view-1 .a-filter-filter-item {
  padding: 2px 0;
}
.a-filter-view-2 .a-filter-filter-title {
  font-size: 15px;
  margin: 10px 0;
}
.a-filter-view-2 .DatePicker-FullContainer {
  width: 100%;
}

.a-advanced-filter-canceling {
  position: absolute;
  height: 100%;
}

.ar-filter-list-toggler.open > div {
  transition: 0s;
  background: #1e73f0;
  color: #fff;
}
.ar-filter-list-toggler.closed > div {
  /* transform: rotate(180deg); */
  transition: 0s;
}
