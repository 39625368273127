.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: 1px solid #d5dee8;
  transition: 0.4s;
  border-radius: 3px;
}

input:checked + .slider {
  border-color: #1e73f0;
  background-color: #1e73f0;
}

.slider .handle {
  position: absolute;
  left: -1px;
  height: 28px;
  width: 28px;
  bottom: -3px;
  transition: 0.4s;
  background-color: white;
  border-radius: 3px;
  border: solid 1px #d5dee8;
  box-shadow: 0 1px 3px #e3e7f1;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:checked + .slider .handle {
  border-color: #1e73f0;
  box-shadow: none;
}

input:checked + .slider .handle {
  left: calc(100% + 1px);
  transform: translateX(-100%);
}

.slider .handle svg {
  color: #1e73f0;
  visibility: hidden;
}

input:checked + .slider .handle svg {
  visibility: visible;
}

.switch.sm {
  width: 36px;
  height: 16px;
}

.switch.sm .slider .handle {
  width: 20px;
  height: 20px;
}

.switch.lg {
  width: 80px;
  height: 32px;
}

.switch.lg .slider .handle {
  width: 36px;
  height: 36px;
}

.switch .on,
.switch .off {
  position: absolute;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

label *{
	user-select: none;
}

.switch .on {
  visibility: hidden;
  color: white;
  padding-right: 34px;
}

.switch .off {
  color: #002b63;
  padding-left: 34px;
}

input:checked + .slider .on {
  visibility: visible;
}

input:disabled + .slider {
  /* border-color: #ebecef;
  background-color: #ebecef; */
  opacity: 0.6;
  cursor: auto;
}

/* input:disabled + .slider .handle {
  border-color: #cfcfcf;
  background: #f5f6f8;
}

input:disabled + .slider .handle svg {
  color: #8e8e8e;
} */
