.ar-fileUploader__container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  clear: left;
}

.ar-fileUploader__header {
  height: 25px;
  margin-bottom: 3px;
}
.ar-custom-upploader {
  opacity: 1 !important;
  transition: none;
}
.uppload-modal-bg {
  background-color: rgba(0, 43, 99, 0.4) !important;
  z-index: 100000000 !important;
  opacity: 1 !important;
}
.UnsplashModal {
  width: 800px;
  height: 500px;
  padding: 30px;
}
.unsplash-react .p-r {
  top: 0 !important;
}
.ar-fileUploader__header__title {
  color: #002b63;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-size: 14;
  font-weight: 500;
}
.unsplash-react .f-1 {
  text-align: center;
}
.unsplash-react,
.unsplash-react a {
  color: #1e73f0 !important;
  text-decoration: none !important;
}
.unsplash-react a:hover {
  color: #1e73f0 !important;
  text-decoration: underline !important;
}
.unsplash-react,
.unsplash-react span:first-of-type {
  color: #002b63 !important;
}
.ar-fileUploader__header__icons--active {
  fill: #1e73f0 !important;
  color: #1e73f0 !important;
}

.ar-fileUploader__header__icons {
  -webkit-user-select: none;
  user-select: none;
  fill: #7c98b6;
  color: #7c98b6;
  float: right;
  clear: right;
}

.ar-fileUploader__header__icons__list {
  margin-right: 15px;
  cursor: pointer;
}

.ar-fileUploader__header__icons__grid {
  cursor: pointer;
}
.ar-fileUploader__header__icons__list.ar-fileUploader__header__icons--active
  ~ .ar-fileUploader__header__icons__grid {
  margin-right: 0px;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__container--wrapper {
  width: 100%;
  height: inherit;
  margin-right: 5px;
  margin-bottom: 0px;
}

.ar-filedropper__container--wrapper {
  width: 100%;
  height: inherit;
  margin-right: 5px;
  margin-bottom: 0px;
}

.logo-mode .ar-fileUploader__container label.ar-fileUploader__dropzone {
  width: 379px;
  height: 94px;
}

.logo-mode
  .ar-fileUploader__container
  label
  .ar-fileUploader__container__gridText {
  left: 47px;
}

.ar-fileUploader__container .ar-fileUploader__container--wrapper {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  margin-bottom: 45px;
}
/* .FileUploader-Container.hasImage{
    border: 2px solid #EBF0F5;
    background-color:#F5F8FA;
} */
.ar-fileUploader__container label.ar-fileUploader__dropzone {
  width: 100px;
  height: 100px;
  background-color: #f2fbfd;
  border: 1px dashed #1e73f0;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  margin-bottom: 45px;
}

.ar-fileUploader__container label * {
  pointer-events: none;
}

.ar-fileUploader__container label.ar-fileUploader__dropzone--hovering {
  border: 1px solid #1e73f0;
}

.ar-fileUploader__container label > svg {
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 17px;
}
.ar-fileUploader__imageContainer svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.ar-fileUploader__imageContainer .resetSVG > svg {
  position: relative !important;

  margin: unset !important;
}

.ar-fileUploader__imageContainer svg.fa-file-pdf {
  font-size: 22px;
  width: 16px;
  height: 22px;
  color: #e10707;
}

.ar-fileUploader__imageContainer svg.fa-file-excel {
  font-size: 22px;
  width: 16px;
  height: 22px;
  color: #1e6e42;
}

.ar-fileUploader__imageContainer svg.fa-file-word {
  font-size: 22px;
  width: 16px;
  height: 22px;
  color: #295394;
}

.ar-fileUploader__imageContainer svg.fa-file-image {
  font-size: 22px;
  width: 16px;
  height: 22px;
  color: #1e73f0;
}

.ar-fileUploader__imageContainer svg.fa-file {
  font-size: 22px;
  width: 16px;
  height: 22px;
  color: #212529;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper {
  margin-right: 0.5rem;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-envelope {
  font-size: 22px;
  width: 11px;
  height: 12px;
  color: #212529;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-envelope {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #212529;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-file-pdf {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #e10707;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-file-excel {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #1e6e42;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-file-word {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #295394;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-file-image {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #1e73f0;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper svg.fa-file {
  font-size: 22px;
  width: 20px;
  height: 25px;
  color: #212529;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-file-pdf {
  font-size: 22px;
  width: 9px;
  height: 12px;
  color: #e10707;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-file-excel {
  font-size: 22px;
  width: 9px;
  height: 12px;
  color: #1e6e42;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-file-word {
  font-size: 22px;
  width: 9px;
  height: 12px;
  color: #295394;
  vertical-align: baseline;
}
.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-file-image {
  font-size: 22px;
  width: 9px;
  height: 12px;
  color: #1e73f0;
  vertical-align: baseline;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__imageContainer__Title
  svg.fa-file {
  font-size: 22px;
  width: 9px;
  height: 12px;
  color: #212529;
  vertical-align: baseline;
}

.ar-fileUploader__imageContainer {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  border: 2px solid #ebf0f5;
  background-color: #f9fbff;
  border-radius: 5px;
  margin-right: 19px;
  margin-bottom: 0px;
  user-select: none;
}
.ar-fileUploader__dropzone {
  width: 100%;
  height: 100%;
  display: block;
}

.close_circular_corner {
  color: #ff5962;
  border: 1px solid #d5dee8;
  width: 26px;
  height: 26px;
  top: -10px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: white;
}

span#Span-With-Split-Text::before,
span::after {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  max-width: 50%;
  overflow: hidden;
  white-space: pre;
}

span#Span-With-Split-Text::before {
  content: attr(data-content-start);
  text-overflow: ellipsis;
}

span#Span-With-Split-Text::after {
  content: attr(data-content-end);
  text-overflow: clip;
  /* direction: rtl; */
}

.Preview_Image_Container {
  min-width: 250px;
  min-height: 250px;
}

.Preview_Image_Container img {
  object-fit: scale-down;
  max-width: 900px;
  min-width: 250px;
}
.download_circular_corner {
  border: 1px solid #d5dee8;
  width: 26px;
  height: 26px;
  top: 22px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: white;
}
.label_circular_corner {
  border: 1px solid #d5dee8;
  width: 26px;
  height: 26px;
  top: 54px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: white;
}
.ar-fileUploader__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ar-fileUploader__close {
  width: 22px;
  height: 22px;
  font-size: 15px;
  position: absolute;
  top: 5px;
  right: 2px;
  color: #e10707;
  cursor: pointer;
}
.ar-fileUploader__close svg {
  margin: 3px 5px;
}
.ar-fileUploader__inputFile {
  display: none;
}
.ar-fileUploader__container.ar-fileUploader--extended-mode {
  flex-direction: column;
}
.uppload-modal {
  overflow: visible !important;
}
.uppload-modal-extra-close {
  right: -50px;
  top: 0px;
  position: absolute;
  color: #7c98b6;
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  label.ar-fileUploader__dropzone {
  width: 100%;
  margin-right: 0;
  display: flex;
  height: 50px;
  color: #1e73f0;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.ar-fileUploader__container label .ar-fileUploader__container__gridText {
  color: #1e73f0;
  font-size: 10px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 20px;
}
.uppload-modal form input {
  width: 100%;
  text-align: center !important;
}

.uppload-modal form label {
  width: 85%;
}
.ar-fileUploader_Preview_Email_Attachments_Container {
  width: 250px;
}

.ar-fileUploader_Preview_Email_Attachments_Container > div:first-of-type {
  width: 31px;
}

.uppload-modal .need-help-link {
  display: none;
  pointer-events: none;
}
.ar-fileUploader__imageContainer__details {
  color: #7c98b6;
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  justify-content: start;
  align-items: center;
  text-align: start;
}

.ar-fileUploader__imageContainer__Title {
  color: #002b63;
  font-size: 12px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  justify-content: start;
  align-items: center;
  text-align: start;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode label > svg {
  display: inline-block;
  margin: 0 10px 0 0;
  right: unset;
  left: unset;
  top: unset;
  bottom: unset;
  width: 35px;
  height: 35px;
  position: relative;
}
.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__imageContainer {
  display: flex;
  width: 100% !important;
  height: 50px;
  align-items: center;
  color: #002b63;
  font-size: 14px;
  user-select: text;
  margin-bottom: 5px;
}
.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__imageContainer
  .ar-fileUploader__image__iconContainer
  > svg {
  display: block;
  position: relative;
  right: unset;
  left: unset;
  top: unset;
  bottom: unset;
  margin: 0 10px;
  font-size: 19px;
  height: 18px;
  width: 13px;
}
.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__close {
  top: 0px;
  right: 10px;
  bottom: 0;
  margin: auto 0;
  border: 0;
  background-color: transparent;
  font-size: 15px;
}

.ar-fileUploader__container__wrapper
  .ar-fileUploader__image__spinnerLoader
  svg {
  color: #36ab80;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 33px;
  right: 0;
  margin: auto;
}

.ar-fileUploader__container__wrapper .ar-fileUploader__image__cancelbutton {
  width: 57px;
  height: 20px;
  font-size: 11px;
  color: #7c98b6;
  border-radius: 3px;
  text-align: center;
  border: solid 1px #d5dee8;
  background-color: inherit;
  bottom: 12px;
  position: absolute;
  left: 18px;
  outline: none;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__image__iconContainer {
  height: 100%;
  border-right: 1px solid #d5dee8;
  margin-right: 15px;
  padding-top: 15px;
}

.ar-fileUploader__container a {
  margin: 0;
  padding: 0;
  height: 100%;
  border: 0;
  font-size: 100%;
  font: inherit;
  text-decoration: none;
  vertical-align: baseline;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__imageContainer__Description {
  padding-top: 15px;
  line-height: 18px;
  margin-right: 15px;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__image__progressBarContainer {
  flex-grow: 2;
  width: auto;
  height: 6px;
  background-color: #e7f1fd;
  border: 1px solid #d5dee8;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__image__progressBar {
  transition: width 0.03s linear;
  background-color: #36ab80;
  height: 4px;
}

.ar-fileUploader__container.ar-fileUploader--extended-mode
  .ar-fileUploader__image__cancelbuttonList {
  margin-right: 13px;
  width: 57px;
  height: 20px;
  font-size: 11px;
  color: #7c98b6;
  border-radius: 3px;
  text-align: center;
  border: solid 1px #d5dee8;
  background-color: inherit;
  margin-left: 10px;
  outline: none;
}
