

.TemplaterChooserOrderList {
  height: 340px;
  overflow: auto;

  padding-right: 10px;
}

.TemplateContainer {
  background: #f9fbff;
  border: 1px solid #d5dee8;
  /* margin-top: 10px;
	margin-bottom: 10px; */
  padding-top: 7px;
  border-radius: 3px;
  opacity: 1;
  width: 382px;
	display: flex;
  height: 40px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.TemplateModalWrap {
  width: 450px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

