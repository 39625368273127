.card {
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
}
.card:hover {
  background: #e7f1fd;
}

.cardIcon {
  width: 32px;
  height: 32px;
  background: #e7f1fd;
  color: #1e73f0;
}
