.Container {
  background: transparent;
  border-radius: 3px;
}

.Container div{
	width: 14px;
	/* margin-right: 15px; */
}

.ButtonExtra {
  transition: 0.3s all linear;
}

.ButtonExtra:hover {
  color: #1d70ea;
}
