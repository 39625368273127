.info-bar-Warning {
  background: #fdf2d1;
  color: #634c0a;
  padding: 9px;
  width: 100%;
}

.info-bar-Info {
  background: #1e73f0;
  color: #ffffff !important;
  text-decoration: none !important;
  padding: 9px;
  width: 100%;
}
.info-bar-Trial {
  background: #1e73f0;
  color: #ffffff !important;
  text-decoration: none !important;
  padding: 9px;
  width: 100%;
}

.info-bar-Trial span,.info-bar-Trial svg {
  color: #ffffff !important;
}

.InforBarMaster{
	position: absolute;
	z-index: 10004;
	transition: opacity 0.3s linear;
}

.InforBarMaster:hover{
	opacity: 0.5;
}

.infoBarContent a:hover{
	text-decoration: none !important;
}

.infoBarContent {
  height: 22px;
  font-size: 14px;
  font-weight: 500;
}

.infoBarClose {
  height: 22px;
  width: 22px;
  display: flex;
  cursor: pointer;
  /* position: absolute; */
  /* top: 9px;
  right: 5px; */
  align-items: center;
  justify-content: center;
}
