.ssi-input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: 0.3s;
}

.ssi-input:disabled {
  background: #eaf0f6;
  border-color: #eaf0f6;
  color: #7c98b6;
  /* opacity: 0.6; */
}
.ssi-input::placeholder {
  color: #7c98b6;
}

.ssi-input::-ms-reveal,
.ssi-input::-ms-clear {
  display: none;
}

.ssi-input.disabled {
  /* background: #ebecef; */
  opacity: 0.6;
  cursor: default !important;
  color: #7c98b6 !important;
}

.ssi-input:disabled ~ label {
  background: #eaf0f6;
  border-color: #eaf0f6;
  color: #7c98b6;
  /* opacity: 0.6; */
}

.ssi-input.disabled ~ label {
  /* background: #ebecef; */
  opacity: 0.6;
  cursor: default !important;
  color: #7c98b6 !important;
}

.ssi-input:focus {
  border-color: #1e73f0;
}

.ssi-input:focus + label {
  border-color: #1e73f0;
}

.ssi-input.error {
  border-color: rgba(159, 58, 56, 0.7);
}

.search-input {
  /* max-width: 200px; */
  position: relative;
  display: inline-block;
}

.search-input input {
  padding-left: 30px;
}

.search-icon {
  position: absolute;
  left: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.phone-input .dropdown-container {
  width: 95px;
}

.phone-input.error .ssi-control,
.phone-input.error .dropdown-popup {
  border-color: rgba(159, 58, 56, 0.7);
}

.phone-input.error .Dropdown-button .DropdownArrow {
  color: rgba(159, 58, 56, 0.7);
}

.phone-input .Dropdown-button svg {
  margin-left: 0.5rem !important;
}

.input-preview-mode-container {
  border-radius: 4px;
  overflow: hidden;
  background: #f2f4f8;
  padding: 4px;
  row-gap: 4px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
