.no-permission-svg {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-permission-airdesk-title {
	height: 66px;
	margin-top: 105px;
	z-index: 3;
}
