.DatePicker-FullContainer {
  border-radius: 5px;
  max-width: 250px;
  /* height: 39px; */
  /* background-color: #f5f8fa;
  border: 1px solid #d5dee8; */
  position: relative;
  /* display:inline-block; */
}

.DatePicker-FullContainer.sm {
  max-width: 150px;
}

.DatePickerComponent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}
.DatePickerComponent-R {
  /* width: 57px; */

  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #d5dee8;

  /* float: right; */
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.DatePicker-FullContainer.w-100{
	max-width: unset !important;
}

.DatePickerComponent-R button {
  padding: 0;
  background: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  /* margin: 0 6px; */
  /* margin:9px 4px; */
  /* margin:7px 4px; */
  /* vertical-align: bottom; */
  vertical-align: middle;
}
.DatePickerComponent-R svg {
  width: 17px;
  vertical-align: middle;
}
.DatePickerComponent-L {
  /* max-width: calc(100% - 65px); */
  /* float: left; */
  display: flex;
  /* justify-content: center; */
  height: 100%;
  flex: 1 1 auto;
  align-items: center;
  flex-wrap: wrap;
  /* display: table; */
}
.DatePickerComponent-L span {
  /* display: table-cell;
  vertical-align: middle; */
  padding-left: 7px;
  line-height: 15px;
}
.ar-timepicker-w {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}
.ar-timepicker-w-scroller {
  overflow: hidden;
  flex: 1 1 50%;
}
.ar-timepicker-w-scroller:hover {
  overflow: auto;
}
.ar-timepicker-time input,
.ar-timepicker-date input {
  width: 22px;
  display: inline-block;
  border: none;
  background-color: transparent;
  outline: 0;
  text-align: center;
}
.ar-timepicker-date input:last-child {
  width: 36px;
}
.ar-timepicker-w .ar-timepicker-w-Item {
  padding: 6px 10px 6px 20px;
  font-size: 17px;
  cursor: pointer;
  color: #012c64;
}
.ar-timepicker-w .ar-timepicker-w-Item:hover {
  background-color: #e7f1fd;
  background-color: #012c64;
}
.DatePicker-Container {
  width: 370px;
  height: 426px;
  overflow: hidden;
  padding: 0;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 9px 1px #d8d8d8;
  box-shadow: 0px 0px 9px 1px #d8d8d8;
  /* z-index: 99;
  position: absolute; */
}
.DatePicker-Container-WithScheduler-DateParte {
  width: 370px;
  padding: 0;
  overflow: hidden;
}
.DatePicker-Container-WithScheduler {
  width: 650px;
  height: 375;
  overflow: hidden;
  padding: 0;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 9px 1px #d8d8d8;
  box-shadow: 0px 0px 9px 1px #d8d8d8;
  /* z-index: 99;
	position: absolute; */
}

.DatePicker-Container-WithScheduler-SchedulesParte {
  padding-top: 25px;
  padding-bottom: 10px;
}
.DatePickerW {
	outline: none;
}
.HourPicker-Container {
  min-width: 160px;
  height: 300px;
  overflow: hidden;
  padding: 3px 3px 3px 0;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 9px 1px #d8d8d8;
  box-shadow: 0px 0px 9px 1px #d8d8d8;
  /* z-index: 99;
  position: absolute; */
}
.DatePickerW-ButtonArea {
  padding: 10px 10px 10px;
  position: relative;
  display: block;
  box-shadow: 0px -3px 10px #e3e7f180;
  border-radius: 0px 0px 2px 2px;
}
.DatePicker-Button {
  cursor: pointer;
  outline: 0;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  background-color: #f5f8fa;
  border-radius: 7px;
  border: 1px solid #dee6ed;
  text-align: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  color: #002b63;
}

.DatePicker-FullContainer.right .DatePicker-Container,
.DatePicker-FullContainer.right .HourPicker-Container {
  right: 0;
}

.DatePickerW-Content {
  padding: 10px 10px 0 10px;
  display: block;
  height: 294px;
	user-select: none;
}
.DatePickerW-DayItem {
  width: 50px;
  border: 1px solid #fff;
  position: relative;
  display: inline-block;
  padding: 7px 11px;
  text-align: center;
  color: #012c64;
  border-radius: 7px;
}
.DatePickerW-DayItem.dayofweek {
  color: #012c64;
}
.DatePickerW-DayItem.dayofweek.weekend-d {
  opacity: 0.6;
}
.DatePickerW-DayItem.selectable {
  cursor: pointer;
}
.DatePickerW-DayItem.selectable:hover,
.DatePickerW-DayItem.selectable.hint {
  background-color: #e7f1fd;
  color: #012c64;
  /* background-color:#fff;
    border-color: #1E73F0; */
}
.DatePickerW-DayItem.selectable.today {
  /* background-color: #fff;
    border-color: #1E73F0; */
  color: #1e73f0;
}
.DatePickerW-DayItem.disabled {
  /* background-color: #fff;
      border-color: #1E73F0; */
  color: #d6d6d6;
}
.DatePickerW-DayItem.selectable.selected {
  background-color: #061d4b;
  color: #fff;
}

.DatePickerW-MonthItem {
  width: 116px;
  border: 1px solid transparent;
  position: relative;
  display: inline-block;
  padding: 8px 11px;
  text-align: center;
  cursor: pointer;
  border-radius: 7px;
  color: #012c64;
}
.DatePickerW-MonthItem.selectable:hover,
.DatePickerW-MonthItem.selectable.hint {
  background-color: #e7f1fd;
  color: #012c64;
  /* background-color:#fff;
    border-color: #1E73F0; */
}
.DatePickerW-MonthItem.selectable.today {
  color: #1e73f0;
}
.DatePickerW-MonthItem.selectable.selected {
  color: #fff;
  background-color: #012c64;
}

.DatePickerW-YearItem {
  width: 70px;
  border: 1px solid #fff;
  position: relative;
  display: inline-block;
  padding: 8px 11px;
  text-align: center;
  cursor: pointer;
  border-radius: 7px;
  color: #012c64;
}
.DatePickerW-YearItem.selectable:hover,
.DatePickerW-YearItem.selectable.hint,
.DatePickerW-YearItem.invalid.hint {
  background-color: #e7f1fd;
  color: #012c64;
}
.DatePickerW-YearItem.selectable.today {
  color: #1e73f0;
}
.DatePickerW-YearItem.selectable.selected {
  color: #ffffff;
  background-color: #012c64;
}
.DatePickerW-YearItem.invalid {
  color: #d6d6d6;
  background-color: #fff;
  cursor: default;
}

.DatePickerW-Header {
  text-align: center;
  padding: 25px 0 10px;
  position: relative;
  display: block;
}
.DatePickerW-Header-Title {
  font-size: 19px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  width: 240px;
  margin: 0 14px;
  padding: 6px 0;
  user-select: none;
  color: #012c64;
  border-radius: 7px;
}
.DatePickerW-Header-Title.selectable {
  cursor: pointer;
}

.DatePickerW-Header-Title.selectable:hover {
  background-color: #e7f1fd;
  color: #012c64;
}
.DatePickerW-LeftArrow {
  position: absolute;
  display: inline-block;
  /* margin-top: 18px; */
  cursor: pointer;
  width: 20px;
  height: 26px;
  margin-left: -19px;
  padding: 6px 0;
}
.DatePickerW-LeftArrow:before {
  content: "";
  width: 10px;
  height: 3px;
  position: absolute;
  background-color: #1e73f0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 14px;
  left: 0;
}
.DatePickerW-LeftArrow:after {
  content: "";
  width: 10px;
  height: 3px;
  margin-top: -12px;
  position: absolute;
  background-color: #1e73f0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: 9px;
  left: 0;
}

.DatePickerW-RightArrow {
  position: absolute;
  display: inline-block;
  /* margin-top: 18px; */
  cursor: pointer;
  width: 20px;
  height: 26px;
  padding: 6px 0;
}
.DatePickerW-RightArrow:before {
  content: "";
  width: 10px;
  height: 3px;
  position: absolute;
  background-color: #1e73f0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: 14px;
  right: 0;
}
.DatePickerW-RightArrow:after {
  content: "";
  width: 10px;
  height: 3px;
  position: absolute;
  background-color: #1e73f0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 9px;
  right: 0;
}
