.ar-classification-table {
  width: 100%;
  font-size: 14px;
  color: #002b63;
}

.ar-classification-table tr td {
  padding: 14px 10px 14px 0px;
}

.ar-classification-table tr td:first-child,
.ar-classification-table tr th:first-child {
  padding-left: 1rem;
  width: 160px;
}
.ar-classification-table tr td:last-child,
.ar-classification-table tr th:last-child {
  padding-right: 1rem;
  padding-left: 0px !important;
  width: 260px;
}

.ar-classification-table thead th {
	padding-top: 0;
	position: sticky;
	top: -16px;
	background: white;
  padding-bottom: 8px;
  padding-top: 8px;
  font-weight: 500;
}

.ar-classification-table tbody tr {
  border-bottom: solid 1px #d5dee8;
}

.ar-classification-table tbody tr:last-child {
  border-bottom: none;
}

.ar-classification-table tbody tr.new {
  background: #1473e610;
}

.ar-classification-hourpicker {
  width: 200px;
}

/* .ar-classification-duration-picker{
  width: 200px;
} */
