.air-reception-sidenav {
  width: 280px;
  height: 100%;
  box-shadow: 0px 3px 10px #0000001a;
  /* border-right: 1px solid #d5dee8; */
  transition: all 0.3s ease-in-out;
  z-index: 11;
  /* padding: 0px 20px; */
}

.air-reception-sidenav.closed {
  width: 50px;
}

.air-reception-sidenav.minimized {
  position: absolute;
  left: 0;
}

/* .air-reception-sidenav .NavItemHeader {
  min-height: 74px;
} */

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

.reset-this span {
  color: black;
}
.reset-this body {
  background: white;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -ms-user-select: none; /*Internet Explorer */
  user-select: none;
}

.air-reception-sidenav .NavBarItemFilter:hover,
.air-reception-sidenav .NavBarItemFilter.selected {
  background: #e6edf4 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

.receptionChatStatus.open
  .dropdown-container.ssi-control.ar-dropdown-container {
  background-color: #e6faf4;
  color: #36ab80;
  border-color: #36ab80;
}

.receptionChatStatus.closed
  .dropdown-container.ssi-control.ar-dropdown-container {
  background-color: #ccd4e0;
  color: #002b63;
  border-color: #002b63;
}

.disableTextDecoration {
  text-decoration: none !important;
}

.air-reception-msg-item {
  text-decoration: none !important;
}

.air-reception-msg-item.Unread::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  /* margin: auto 0; */
  /* display: block; */
  width: 5px;
  height: 100%;
  background: #1e73f0;
  overflow: hidden;
}

/* .air-reception-sidenav .NavItemHeader svg {
  height: 33px;
} */

/* .ar-badge */

.air-reception-sidenav .UnreadCircleCount {
  width: 20px;
  height: 20px;
  font-size: 12px;
  background: #1e73f0;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.air-reception-msg-list {
  width: 370px;
  transition: width 0.3s ease-in-out;
}
.air-reception-msg-list .air-reception-msg-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 9px;
  border: 1px solid #d5dee8;
  border-left: none;
  border-top: none;
}

.disable-checkbox-on-list .air-reception-msg-list-checkbox {
  display: none !important;
}

.air-reception-msg-list .air-reception-msg-list-checkbox {
  width: 24px;
  display: flex;
  align-items: center;
}

.air-email-chat-footer {
  background: white;
  box-shadow: 0px 3px 10px #0000001a;
}

.air-email-chat-footer .Tabstrip-content {
  padding-bottom: 16px;
}

.air-reception-msg-list .air-reception-msg-item {
  display: flex;
  justify-content: space-between;
}

.air-reception-msg-list .air-reception-msg-item:hover {
  background: #f9fbff;
}
.air-reception-msg-list .air-reception-msg-item.active {
  background: #e7f1fd !important;
}

.air-reception-chat-container {
  height: 100%;
  flex: 1 1;
}

.EmailChatHeader {
  min-height: 70px;
  padding: 15px 12px;
}

.air-reception-email-item-container {
  overflow: hidden;
  /* max-width: 90%; */
  flex: 1 1 auto;
  margin-top: 12px;
  background: white;
  max-width: 100%;
  width: 90%;
  /* flex: 1 1; */
  box-shadow: 0px 3px 10px #e3e7f19e;
  border-radius: 3px;
}

.air-reception-description-container {
  height: 100%;
  transition: all 0.3s ease-in-out;
  width: 400px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
}

.air-reception-description-padding {
  padding: 27px 17px;
}

.air-reception-description-container .ar-overview-item-title {
  width: fit-content !important;
}

.air-reception-description-container.closed {
  width: 50px;
}

.air-reception-msg-item-email-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.air-reception-msg-item-email-icon.open {
  background: #e6faf4;
  color: #36ab80;
}

.air-reception-msg-item-email-icon.closed {
  background-color: #ccd4e0;

  color: #002b63;
}

.ar-reception-summary-container .ar-overview-item-title {
  width: 130px;
}

.ar-reception-summary-container {
  transition: 0.3s;
  overflow: hidden;
}

.ar-reception-summary-container .breadcrumbs {
  height: 70px;
}

.ar-reception-minimize-button {
  height: 70px;
  width: 50px;
}

.ar-chat-reception-note {
  width: 100%;
  word-break: break-word;
  background: #e7f1fd;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000008;
}

.ar-accordion .title {
  transition: 0.3s;
  cursor: pointer;
}

.ar-accordion-content {
  line-height: 1.5;
  overflow: hidden;
  /* -webkit-transition: 0.3s;
  transition: 0.3s; */
  height: 0px;
  transform-origin: top;
}

.ar-accordion-content > div {
  height: 100%;
}

.ar-accordion-content.active {
  opacity: 1;
  height: 100%;
}

.ar-accordion-icon > svg {
  transition: 0.3s;
  transform: rotate(0deg);
}

/* .ar-accordion.active > div.SidebarMenu-link > div.ar-accordion-icon > svg {
  transform: rotate(90deg);
} */

.ar-accordion-toggleContainer {
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar-inbox-minimized .ar-reception-summary-container {
  position: absolute;
  right: 0;
  height: 100% !important;
}

.ar-inbox-minimized .ar-entity-summary-messages {
  margin-right: 50px;
}

.ar-inbox-minimized .air-reception-description-container {
  position: absolute;
  right: 0;
  height: 100%;
}

.ar-inbox-minimized .air-reception-chat-container {
  margin-right: 50px;
}

.w-32px {
  width: 32px;
}
