.ar-grid-buttons-popup > .label-title:first-child {
  margin-top: 0;
}

.label-title {
  user-select: none;
  color: #7c98b6;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 10px;
}

.label-hr {
  color: #7c98b6;
}

.icon-width {
  width: 20px;
}
