.cardList-grid {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-gap: 8px;
  justify-content: center;
  cursor: grab;
  grid-auto-rows: minmax(min-content, max-content);
	padding-top: 2px;
}
.air-card-block {
  background: white;
  border: 1px solid #d5dee8;
  border-radius: 5px;
  overflow: hidden;
  height: fit-content;
  position: relative;
  cursor: default;
  transition: all 0.3s linear;
}

.air-card-block-updated {
  box-shadow: 0 0 0 2px orange;
}

.air-card-block.selected {
  border-color: #1e73f0 !important;
}

.ar-card-item-header-tools {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.air-card-block:hover {
  border-color: #1e73f0;
  box-shadow: rgb(6 29 75 / 24%) 0px 0px 6px;
}

.ar-card-item {
  outline: none !important;
  box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 0px;
  transition: 0.3s;
  /* box-shadow: rgba(6, 29, 75, 0.24) 0px 0px 6px */
}

.air-cards-checkbox .checkmark {
  background: #f8faff !important;
}

.cardList-grid.kinetic-grabbing {
  cursor: grabbing;
}
