.air-page-views-line {
  /* margin-top: 21px; */
  white-space: nowrap;
  display: inline-block;
}

.air-page-view-tab:not(.active-view):hover {
  background-color: #1473e610 !important;
}
.air-add-new-view:hover {
  background-color: #1473e610 !important;
	color: #1e73f0 !important;
}

.air-add-new-view {
	color: #323D49 !important;
	font-weight: 500;
}

.air-page-view-tab {
  display: inline-flex;
  text-align: center;
  position: relative;
	border-bottom: none;
	height: 38px;
  max-width: 350px;
	align-items: center;
	background: white;
  vertical-align: top;
  cursor: pointer;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -ms-user-select: none; /*Internet Explorer */
  user-select: none;
}

.air-page-views-bar-item-icon {
  display: inline-flex;
  text-align: center;
  position: relative;
  border-bottom: none;
  align-items: center;
  vertical-align: top;
  cursor: pointer;
}

.air-page-view-active {
  background-color: #e7f1fd;
  color: #1e73f0;
}
.air-page-view-inactive {
  background-color: white;
  color: #002b63;
}

.air-page-view-bars-popup {
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  background: white;
	padding: 0 8px;
	border-radius: 5px;
}

.ViewSorterChooserOrderList {
  height: 340px;
  overflow: auto;

  padding-right: 10px;
}

.ViewSorterDragableContainer {
  background: #f9fbff;
  border: 1px solid #d5dee8;
  /* margin-top: 10px;
	margin-bottom: 10px; */
  padding-top: 7px;
  border-radius: 3px;
  opacity: 1;
  width: 382px;
  height: 40px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.ViewSorterModalWrap {
  top: 267px;
  left: 496px;
  width: 450px;
  height: 558px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

.air-page-view-tab.active-view::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f9fbff;
  bottom: -1px;
  left: 0;
  z-index: 10;
}
