.ar-animated-accordion .title {
  transition: 0.3s;
  cursor: pointer;
}

/* .ar-animated-accordion .title:hover {
    background: #f6faff;
  } */

.ar-animated-accordion-icon {
  color: #9a9ae8;
  height: 100%;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 40px; */
  /* margin-right: 0.5rem; */
}

/* .AccordionActive {
  background: #232357;
}
.AccordionExtraActive {
  background: var(--purple50) !important;
} */

.ar-animated-accordion.active .ar-animated-accordion-icon > svg {
  transform: rotate(90deg);
}

.ar-animated-accordion-icon svg {
  display: block;
  transition: 0.3s;
  transform: rotate(0deg);
  /* width: 100% !important;
  height: 100%; */
}

.ar-animated-accordion-content {
  line-height: 1.5;
  opacity: 0;
  /* margin-left: 1.25rem; */
  overflow: hidden;
  transition: 0.3s;
  will-change: height, opacity;
	border-radius: 0px 0px 4px 4px;
}

/* .ar-animated-accordion.active .title {
  margin-bottom: 0.25rem;
} */

.ar-animated-accordion-content.active {
  opacity: 1;
}

/* .ar-animated-accordion-content a:hover{
	background: #343482;
} */

.ar-animated-accordion-anchor {
  width: 100%;
  height: 40px;
  /* padding: 8px; */
	position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  user-select: none;
}

.ar-animated-accordion-anchor:hover {
  background-color: #232357;
	color: white !important;
}
.ar-animated-accordion-anchor:hover svg  {
	color: white !important;
}

.ar-animated-accordion-anchor > svg {
  width: 40px;
	color: #ddddf7;
}
.ar-animated-accordion-anchor > span {
  font-weight: 500;
  color: #ddddf7;
  font-size: 14px;
}
.ar-animated-accordion-anchor:hover > span {
  color: white !important;
}
