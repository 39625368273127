.air-signature-modal-container{
	width: 306px;
	height: 230px;
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
}

.air-modeal-border-radius{
	border-radius: 3px;
}

.air-signature-form .ar-details-tabstrip .Tabstrip-content{
	padding: 0 !important;
}

.air-min-width-signature-pictures{
	width: min-content;
}
.air-signature-modal-container svg.blank-svg{
	height: 80px !important;
}

.Disable-svg-events {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.active-view{
	background: #F9FBFF;
}

.air-signature-logo .ar-organizationLogo{
	width: 250px !important;
}

.checkbox{
	width: 24px;
	height: 24px;
}

.checkbox label{
	height: 24px;
}

.air-signature-modal-container svg{
	height: 100% !important;
	width: 100% !important;
}

.Email-signature-preview-header{
	height: 54px;
}