.dateArrow {
  user-select: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  padding: 4px;
  cursor: pointer;
}
.dateArrow > svg {
  width: 100%;
  height: 100%;
}

.dateArrow:hover {
  background: #efefef;
}

.viewSelector {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #d5dee8;
  background: white;
  height: 40px;
}

.viewSelectorButton {
  color: #1e73f0;
  transition: background 0.3s;
  cursor: pointer;
  user-select: none;
  height: 40px;
}

.viewSelectorButton:hover,
.viewSelectorButton.selected {
  background: rgba(30, 114, 240, 0.1);
}
.viewSelectorButton.selected {
  font-weight: 500;
}

.calendarHours {
  background: rgba(30, 114, 240, 0.1);
}
