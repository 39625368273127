.react-flow__edge-fat-path:hover + .showOnMouseOver {
  display: initial !important;
}

.showOnMouseOver {
  display: none;
}

.react-flow__edge-fat-path {
  stroke: white;
	stroke-width: 38px;
  fill: none;
  opacity: 0;
  -webkit-animation: unset !important;
  animation: unset !important;
  stroke-dasharray: 0 !important;
}

.react-flow__edge-path{
	stroke-width: 3px !important;
}

.react-flow__edge.animated .react-flow__edge-path {
  stroke-dasharray: 5 !important;
  -webkit-animation: dashdraw 0.5s linear infinite !important;
  animation: dashdraw 0.5s linear infinite !important;
}

.react-flow__handle-bottom{
	bottom: -8px;
}

.react-flow__handle-top{
	top: -8px;
}