.ar-timetablepicker {
  transition: 0.3s;
}

.ar-timetablepicker-input-container {
  border: none;
  width: auto;
  outline: none;
  text-align: center;
  width: 100%;
  height: 100%;
  text-align: right;
  background: inherit;
  margin-right: 2px;
}

.DatePicker-Width-Small {
  width: 115px !important;
}
