.items {
	display: grid;
	grid-auto-columns: 50%;
	grid-template-columns: 1fr 1fr;
	column-gap: 6px;
	row-gap: 6px;
}

.item {
  cursor: pointer;
}

.nodeIcon {
  width: 48px;
}

.disabledNode {
  pointer-events: none !important;
  opacity: 0.4;
}
