.NotifBox {
  background: white;
  /* border: 1px solid gray; */
  border-radius: 7px;
  width: 560px;
  height: 380px;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  overflow: hidden;
}

.react-draggable,
.cursor {
  cursor: move;
}
