.split{
    position: relative;
    display:block;
  }
  .split .left, .split .right{
    width:50%;
    box-sizing: border-box;
  }
  .split .left{
    float:left;
    padding-right:9px;
  }
  .split > .right{
    float:right;
    padding-left:9px;
  }
  .clear{
    clear:both;
    position:relative;
    margin:0;
    padding:0;
  }