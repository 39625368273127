.ar-groupbutton-container {
  border: 1px solid #1e73f0;
  border-radius: 5px;
}

.ar-groupbutton {
  font-size: 16px;
  /* padding: 3px 10px; */
  width: 38px;
  color: #1e73f0;
  background: #fff;
  border: 0;
  border-right: 1px solid #1e73f0;
  cursor: pointer;
  outline: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ar-groupbutton:first-child {
  border-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.ar-groupbutton:last-child {
  border-right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ar-groupbutton.ar-selected,
.ar-groupbutton:hover {
  background-color: #1e73f0;
  color: #fff;
}
